import { createTheme, responsiveFontSizes } from "@mui/material";
import { typography } from "./Typography";

let theme = createTheme({
  palette: {
    primary: {
      main: "#CFDEDB",
      light: "#C8CAF1",
      dark: "#c4c7c4",
      contrastText: "#000000",
    },
    secondary: {
      main: "#B800CC", //dark purple
      light: "#D0D0D0",
      dark: "#292C2D", //order card black bg color
      contrastText: "#FFFFFF",
    },
    // tertiary: {
    //     main: "#595959",
    //     light: "#f2f2f2",
    //     dark: "#2c2d2c",
    // },
    success: {
      main: "#00CC52", //dark green
      light: "#C1E9DE", //light green
      dark: "#98E59A", //green
      contrastText: "#000000",
      //   contrastText: "#ffffff",
    },
    info: {
      main: "#FBC1D9",
      light: "#595959",
      contrastText: "#000000",
    },
    error: {
      main: "#FF0000",
      light: "#fdf3f5",
      dark: "#e45a68",
    },
    warning: {
      main: "#fec90f",
      light: "#fff4e5",
      dark: "#dcb014",
      contrastText: "#ffffff",
    },
    text: {
      secondary: "#777e89",
    },
    grey: {
      A100: "#ecf0f2",
      A200: "#99abb4",
      A400: "#767e89",
      A700: "#e6f4ff",
    },
    action: {
      disabledBackground: "rgba(73,82,88,0.12)",
      hoverOpacity: 0.02,
      hover: "rgba(0, 0, 0, 0.03)",
    },
    mode: "dark",
  },
  shape: {
    borderRadius: 5,
  },
})
theme = createTheme(theme, {
  typography: {
    fontFamily: "'DM Sans', sans-serif",
    fontSize: 16,
    h1: {
      fontWeight: 500,
      fontSize: "1.875rem",
      lineHeight: "1.5",
    },
    h2: {
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: "1.5",
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.3125rem",
      lineHeight: "1.5",
    },
    h4: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.5",
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.875rem',
      },
    },
    h5: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    h6: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.5",
    },
    body1: {
      fontWeight: 400, // or 'bold',
      fontSize: "0.875rem",
    },
    subtitle1: {
      fontSize: "0.75rem",
      fontWeight: "400",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: "400",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "6px 8px",
          minWidth: 30,
          [theme.breakpoints.only("sm")]: {
            padding: "6px 4px",
            minWidth: 30
          },
          [theme.breakpoints.only("md")]: {
            padding: "6px 4px",
            minWidth: 30
          },
        }
      }
    }
  }
});
export const baseTheme = theme;