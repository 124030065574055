import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { ISecurityRole, ISecurityApproval, ISecurityGroup, ISecurityNestedGroup, ISecurityRoleGroup } from "./security.types"
import { createNestedSecurityGroup, parseNestedSecurityGroups } from "./security.helpers"
import { ISelectOption } from "../../../constants/types"


const GET_SECURITY_MODULE_ACCESS_PERMISSIONS_BASE_URL = "/security/get-role-module-content-access-permission"
const UPSER_SECURITY_MODULE_ACCESS_PERMISSIONS_BASE_URL = "/company/upsert-outlet"


export interface IFetchSecurityGroupOutput {
    nested: ISecurityNestedGroup,
    roleName: any,
    roleGroup: any,
    roleStatus: any
}
export const fetchSecurityGroupAsync = createAsyncThunk<IFetchSecurityGroupOutput, string | null>(
    'security/fetchSecurityGroupAsync', async (roleId: string | null, thunkAPI) => {
        let url = GET_SECURITY_MODULE_ACCESS_PERMISSIONS_BASE_URL;
        if (roleId) {
            url = `/security/get-role-module-content-access-permission?role_uuid=${roleId}`;
        }
        const res = await axios_api.get(url);
        const data: ISecurityGroup[] = roleId
            ? res.data.data.data
            : res.data.data;
        const roleName = roleId ? res.data.data.role_name : null;
        const roleGroup = roleId ? res.data.data.role_group : null;
        const roleStatus = roleId ? res.data.data.status : "ACTIVE";
        const nested = createNestedSecurityGroup(data);
        return { nested, roleName, roleGroup, roleStatus }
    },
)

interface IUpsertSecurityGroupPayload {
    data: ISecurityNestedGroup,
    roleId: string | null,
    role_group: string,
    roleName: string,
    status: string,
    onCallback: (isSuccess: boolean, roleId?: number) => void,
    isDuplicate?: boolean,
}
export const upsertSecurityGroupAsync = createAsyncThunk<IUpsertSecurityGroupPayload, any>(
    'outlets/upsertSecurityGroupAsync', async (payload: IUpsertSecurityGroupPayload, thunkAPI) => {
        const { data, roleId, roleName, role_group, status, onCallback, isDuplicate } = payload
   
        const list = parseNestedSecurityGroups(data);
        let finalRoleId = null;

        const res = await axios_api.post("/security/upsert-roles", {
            role_name: roleName,
            role_uuid: isDuplicate ? null : roleId,
            role_group: role_group,
            status: status,
        });
        finalRoleId = res.data.data.role_uuid;

        const finalList: ISecurityGroup[] = [];
        for (let role of list) {
            const { role_module_uuid, ...payload } = role as any;
            const finalRole = isDuplicate ? payload : role;
            finalList.push({
                ...finalRole,
                role_uuid: finalRoleId,
                role_name: roleName,
            });
        }
        const response = await axios_api.post(
            "/security/upsert-role-module-content-access-permission",
            finalList,
        );
        onCallback(true, finalRoleId);
        return response.data
    },
)



export interface IFetchRecordDropdownPayload {
    apiUrl: string,
    columnKey: string,
    columnLabel: string,
    onCallback: (isSuccess: boolean, data: ISelectOption[]) => void,
}
export const fetchRecordDropdownAsync = createAsyncThunk<ISelectOption[], IFetchRecordDropdownPayload>(
    'security/fetchRecordDropdownAsync', async (payload: IFetchRecordDropdownPayload, thunkAPI) => {
        const { apiUrl, columnKey, columnLabel, onCallback } = payload
        const res = await axios_api.get(`${apiUrl}`);
        const data: any[] = res.data.data;
        const options: ISelectOption[] = [
            { label: "Self", value: columnKey },
            { label: "All", value: "*" },
            { label: "Self Zone", value: "self_zone" },
        ];
        for (let item of data) {
            options.push({ label: item[columnLabel], value: item[columnKey] });
        }
        onCallback(true, options);
        return options
    },
)



// ########################################################################
// ######################### Security Roles ###############################
// ########################################################################


export const fetchSecurityRolesListAsync = createAsyncThunk<{ data: ISecurityRole[], totalRecords: number }>(
    'security/fetchSecurityRolesListAsync', async () => {
        const response = await axios_api.get(`/security/get-roles`)
        return response.data
        ;
    },
)


// ########################################################################
// ######################### Role_Group ##################################
// ########################################################################

export const fetchSecurityRoleGroupListAsync = createAsyncThunk<{ data: ISecurityRoleGroup[], totalRecords: number }, IQueryParams>(
    'security/fetchSecurityRoleGroupListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`/security/get-role-groups${searchQuery}`)
        return response.data;
    },
)



export const upsertSecurityRoleGroupAsync = createAsyncThunk<ISecurityRoleGroup, any>(
    'security/upsertSecurityRoleGroupAsync', async (payload: ISecurityRoleGroup, thunkAPI) => {
        const {create_ts, insert_ts, ...restPayload} = payload
        const response = await axios_api.post("/security/upsert-role-group", restPayload)
        return response.data
    },
)



// ########################################################################
// ######################### Security_Approval ##################################
// ########################################################################


export const fetchSecurityApprovalListAsync = createAsyncThunk<ISecurityApproval[], IQueryParams>(
    'security/fetchSecurityApprovalListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`/approval/get-approval-count${searchQuery}`)
        return response.data.data;
    },
)
export const fetchSecurityApprovalAsync = createAsyncThunk<ISecurityApproval[], string>(
    'security/fetchSecurityApprovalAsync', async (approval_count_uuid: string | null) => {
        const response = await axios_api.get(`/approval/get-approval-count?approval_count_uuid=${approval_count_uuid}&pageNo=1&itemPerPage=10`)
        return response.data.data[0]
    },
)
export const upsertSecurityApproval = createAsyncThunk<ISecurityApproval, any>(
    'security/upsertSecurityApproval', async (payload: ISecurityApproval, thunkAPI) => {
        const response = await axios_api.post("/approval/insert-approval-count", payload)
        return response.data
    },
)

