import React, { useState } from 'react'
import { ControlledCustomSelect } from '../../../../Components/formComponents'
import { clearCompanyListSync, fetchCompanyListAsync } from '../../../../redux/settings/company';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { SelectChangeEvent } from '@mui/material';

interface ICompaniesDropDownProps {
    value: string | null
    onChange: (data: string) => void
    error?: string
}
export const CompaniesDropDown: React.FC<ICompaniesDropDownProps> = ({
    value,
    onChange,
    error
}) => {
    const {
        data: companyList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.companies.company_list);
    const dispatch = useAppDispatch();

    const [companiesList, setCompaniesList] = useState<{ label: string, value: string | number }[]>([])

    React.useEffect(() => {
        dispatch(
            fetchCompanyListAsync({
                page: 1,
                rowsPerPage: 1000,
            }),
        );
    }, []);

    React.useEffect(() => {
        const newList = companyList.map((data) => ({ label: data.company_name, value: data.company_uuid || "" }))
        setCompaniesList(newList)
    }, [companyList]);

    return (
        <ControlledCustomSelect
            label="Select Company"
            options={companiesList}
            value={value}
            onChange={(evt) => onChange(evt.target.value as string)}
            sx={{ minWidth: 120 }}
            fullWidth size="small"
            error={error}
        />
    )
}
