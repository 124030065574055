import { Avatar, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { StandardTableActions } from '../../../Components/StandardTableActions/StandardTableActions';
import { DataTable } from '../../../Components/Table/DataTable';
import { IDataTableProps, MobileLogoRenderType, RenderType } from '../../../Components/Table/interfaces/IDataTableProps';
import { useAppSelector, IStoreState, useAppDispatch } from '../../../redux';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { CustomButton } from '../../../Components/CustomButton';
import moment from 'moment';
import { ControlledCustomSelect } from '../../../Components/formComponents';
import { CustomDatePicker } from '../../../Components/formComponents/CustomDatePicker';
import { useNavigate } from 'react-router-dom';
import { IUserProfile, clearAllUsersStateSync } from '../../../redux/settings/user-profile';
import { ALL_USERS_LIST_ROUTE } from './UserProfile.constant';
import { CustomLink } from '../../../Components/Customlink/CustomLink';
import { deepOrange } from '@mui/material/colors';
import { fetchAllUsersProfileAsync } from '../../../redux/settings/user-profile/user-profile.actions';
import { CreateNewUserDialog } from './dialogs/CreateNewUserDialog';

export const AllUsersTable = () => {
    const navigate = useNavigate()
    const {
        data: allUsersData,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.userProfile.users_list);
    const dispatch = useAppDispatch();


    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 100,
    });
    const [dateFilter, setDateFilter] = React.useState<string>("ALL");
    const [customDate, setCustomDate] = React.useState<string | null>(null);
    const [customDateDialog, setCustomDateDialog] = React.useState<boolean>(false);
    const [allUsersList, setAllUsersList] = React.useState<IUserProfile[]>([]);
    const [createUserDialog, setCreateUserDialog] = React.useState<boolean>(false);

    const dataTableProps: IDataTableProps = {
        isPagination: true,
        rowsPerPageOptions: 25,
        isDataLoading: false,
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },

        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IUserProfile) => {
                    return (
                        <StandardTableActions
                            onEditClick={() => navigate(`${ALL_USERS_LIST_ROUTE}/manage/${row.user_uuid}`)}
                        />
                    );
                },
            },
            {
                key: "first_name",
                headerName: "Name",
                fieldName: "first_name",
                exportCellWidth: 25,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IUserProfile) => {
                    return (
                        <Stack direction="row" alignItems={"center"} spacing={1}>
                            {value.value && (
                                <>
                                    <Avatar
                                        sx={{
                                            bgcolor:
                                                value.column.rowIndex % 2 === 0
                                                    ? deepOrange[400]
                                                    : deepOrange[400],
                                        }}
                                    >
                                        {value.value.substring(0, 1)}
                                    </Avatar>
                                    <CustomLink
                                        to={"/users/manage/" + row.user_uuid}
                                        // /users/manage/" + row.user_fact_id
                                        label={row.first_name + " " + row.last_name}
                                    />
                                </>
                            )}
                        </Stack>
                    );
                },
            },

            {
                key: "email",
                headerName: "Email",
                fieldName: "email",
                renderType: RenderType.TEXT_DARK,
                exportCellWidth: 30,
                enableSorting: true,
            },
            {
                key: "role_name",
                headerName: "Role Name",
                fieldName: "role_name",
                renderType: RenderType.TEXT,
                exportCellWidth: 30,
                enableSorting: true,
            },
            {
                key: "status",
                headerName: "Status",
                fieldName: "status",
                renderType: RenderType.TEXT,
                enableSorting: true,
            },
        ],

        items: allUsersList,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };


    React.useEffect(() => {
        fetchList()
    }, [pagination]);




    useEffect(() => {
        if (dateFilter === "ALL") {
            setAllUsersList(allUsersData)
        } else if (dateFilter === "CUSTOM") {
            setCustomDateDialog(true)
        } else {
            const newList = allUsersData.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === dateFilter)
            setAllUsersList(newList)
        }
    }, [dateFilter, allUsersData])

    useEffect(() => {
        if (dateFilter === "CUSTOM" && customDate) {
            const newCustomDate = moment(customDate).format("yyyy-MM-DD")
            const newList = allUsersData.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === newCustomDate)
            setAllUsersList(newList)
        }
    }, [customDate])

    React.useEffect(() => {
        return () => {
            dispatch(clearAllUsersStateSync());
        };
    }, []);

    const fetchList = () => {
        dispatch(
            fetchAllUsersProfileAsync({
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
            }),
        );
    };

    return (
        <PageContainer title="All Users" description='inner page' >
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='h2' minWidth={100}>All Users</Typography>
                    <ControlledCustomSelect
                        label="Search by Date"
                        options={[
                            { label: "All", value: "ALL" },
                            { label: "Today", value: moment().format("yyyy-MM-DD") },
                            { label: "Yesterday", value: moment().subtract(1, "day").format("yyyy-MM-DD") },
                            { label: "Custom", value: "CUSTOM" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        sx={{ minWidth: 150 }}
                        fullWidth size="small" />

                    {customDateDialog && <CustomDatePicker
                        label="Select Date"
                        fullWidth
                        fieldName="reservation_date"
                        value={customDate || ""}
                        onChange={(newValue, formattedDate) => {
                            setCustomDate(formattedDate);
                        }}
                    />}
                </Stack>
                <CustomButton
                    variant="contained"
                    color="primary"
                    sx={{
                        display: "flex",
                        marginLeft: "auto",
                    }}
                    // onClick={() => navigate(`${ALL_USERS_LIST_ROUTE}/manage`)}
                    onClick={() => setCreateUserDialog(true)}

                >
                    Add New User
                </CustomButton>
            </Stack>
            <DataTable {...dataTableProps} />

            {createUserDialog &&
                <CreateNewUserDialog
                    open={createUserDialog}
                    onClose={() => setCreateUserDialog(false)}
                />}
        </PageContainer>
    );
}
