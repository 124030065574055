import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import PageContainer from '../../../../Components/PageContainer/PageContainer'
import { ReadyForPickupCard } from './ReadyForPickupCard'

export const UberEatsReadyForPickupTable = () => {
  return (
    <PageContainer title="Uber Eats In Progress Orders" description='inner page' >
      <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Typography variant='h2'>Ready For Pickup</Typography>
        </Stack>
      </Stack>

      <Box sx={{
        width: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }} >
        {[0, 1, 2, 3, 4].map((order, o_idx) => {
          return (
            <ReadyForPickupCard />
          )
        })}
      </Box>
    </PageContainer>
  )
}
