import { Box, Dialog, Divider, List, ListItemButton, ListItemText, Slide, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import React from 'react'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface INewOrderDialogProps {
    open: boolean
    onClose: () => void
    onOrderAccept: () => void
}
export const UberEatsNewOrderDialog: React.FC<INewOrderDialogProps> = ({
    open,
    onClose,
    onOrderAccept
}) => {
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <Box
                sx={{
                    background: "#00a94d",
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    gap: 1,
                    cursor: "pointer"
                }}
                onClick={onOrderAccept}
            >
                <Box sx={{
                    height: 200,
                    width: 200,
                    borderRadius: '50%',
                    background: "#257e38",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Typography variant='h1' fontSize={64} fontWeight={800}>
                        1
                    </Typography>
                </Box>

                <Typography variant='h1' fontSize={54} fontWeight={800}>
                    New Order
                </Typography>

                <Typography variant='h3' sx={{color: "#4d4d4d"}} fontWeight={800}>
                    Tap anywhere to accept
                </Typography>
            </Box>

        </Dialog>
    )
}
