import { Avatar, Button, Stack, Typography } from '@mui/material';
import React from 'react'
import { StandardTableActions } from '../../../Components/StandardTableActions/StandardTableActions';
import { DataTable } from '../../../Components/Table/DataTable';
import { IDataTableProps, MobileLogoRenderType, RenderType } from '../../../Components/Table/interfaces/IDataTableProps';
import { useAppSelector, IStoreState, useAppDispatch } from '../../../redux';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { CustomButton } from '../../../Components/CustomButton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ICompanyOutlet, clearOutletsListSync } from '../../../redux/settings/outlets';
import { RECEIPT_COMPOSER_ROUTE } from './ReceiptComposer.constants';
import { COMPANY_OUTLETS_ROUTE } from '../CompanyOutlets/CompanyOutlets.constants';
import { IReceiptComposer, clearOutletReceiptsListSync, fetchOutletReceiptsListAsync } from '../../../redux/settings/receipt-composer';

export const ReceiptsTable = () => {
    const {
        data: receiptsList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.receiptComposer.receipts_list);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 10,
    });


    const dataTableProps: IDataTableProps = {
        isPagination: true,
        rowsPerPageOptions: 25,
        isDataLoading: false,
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },

        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IReceiptComposer) => {
                    return (
                        <StandardTableActions
                            onEditClick={() => navigate(`${RECEIPT_COMPOSER_ROUTE}/manage/${row.receipt_composer_uuid}`)}
                        />
                    );
                },
            },
            {
                key: "receipt_name",
                headerName: "Receipt Name",
                fieldName: "receipt_name",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "receipt_footer",
                headerName: "Footer",
                fieldName: "receipt_footer",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "page_size",
                headerName: "Page Size",
                fieldName: "page_size",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },

        ],

        items: receiptsList,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };


    React.useEffect(() => {
        dispatch(
            fetchOutletReceiptsListAsync({
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
            }),
        );
    }, [pagination]);

    React.useEffect(() => {
        return () => {
            dispatch(clearOutletReceiptsListSync());
        };
    }, []);

  

    return (
        <PageContainer title="Receipts" description='inner page' >
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='h2'>Receipts</Typography>
                    {/* <ControlledCustomSelect
                        label="Search by Users"
                        options={[
                            { label: "All", value: "ALL" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />
                    <ControlledCustomSelect
                        label="Search by Date"
                        options={[
                            { label: "All", value: "ALL" },
                            { label: "Today", value: moment().format("yyyy-MM-DD") },
                            { label: "Yesterday", value: moment().subtract(1, "day").format("yyyy-MM-DD") },
                            { label: "Custom", value: "CUSTOM" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />

                    {customDateDialog && <CustomDatePicker
                        label="Select Date"
                        fullWidth
                        fieldName="reservation_date"
                        value={customDate || ""}
                        onChange={(newValue, formattedDate) => {
                            setCustomDate(formattedDate);
                        }}
                    />} */}
                </Stack>
                <CustomButton
                    variant="contained"
                    color="primary"
                    sx={{
                        display: "flex",
                        marginLeft: "auto",
                    }}
                    onClick={() => navigate(`${RECEIPT_COMPOSER_ROUTE}/manage`)}
                >
                    Add New Receipt
                </CustomButton>
            </Stack>
            <DataTable {...dataTableProps} />
        </PageContainer>
    );
}
