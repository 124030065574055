
export const ALL_SHIFTS_ROUTE = "/shifts/all-shifts"
export const SHIFTS_PUNCH_IN_OUT_ROUTE = "/shifts/punch-in-out"


export const punch_type_without_underscore = {
    PUNCH_IN: "Punch-in",
    PUNCH_OUT: "Punch-out",
    BREAK_IN: "Punch-in",
    BREAK_OUT: "Punch-out",
}