import { TableRow, TableCell, IconButton, Collapse, Box, Typography, Table, TableHead, TableBody, Tooltip } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { UberOrderItemCard } from "../../dialogs/ViewOrderDialog/UberOrderItemCard";
import { RUPEE_SYMBOL } from "../../../../constants/constants";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { OrderHistoryExpandViewTable } from "./OrderHistoryExpandViewTable";
interface IOrderHistoryRowProps {

}

export const OrderHistoryRow: React.FC<IOrderHistoryRowProps> = ({

}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell width={100}> 
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ExpandLessIcon sx={{ fontSize: 32 }} /> :
                            <ChevronRightIcon sx={{ fontSize: 32 }} />

                        }
                    </IconButton>
                </TableCell>


                <TableCell component="th" scope="row">
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                    }} >
                        <Typography variant='h5'>Out for Delivery</Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 1
                        }} >
                            <Typography variant='h6'>11:57am</Typography>
                            {/* <Chip  label="EAT IN" size='small' sx={{padding: "4px 8px", fontWeight: "700", borderRadius: 1}} /> */}
                        </Box>

                    </Box>
                </TableCell>
                <TableCell >
                    <Typography variant='h5'>7EA9C</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h5'>Rachel D.</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h5'>{`${RUPEE_SYMBOL} 16.10`}</Typography>
                </TableCell>

                <TableCell align="right">
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1
                    }} >
                        <Box sx={{
                            height: 35,
                            width: 35,
                            borderRadius: "50%"
                        }} >
                            <img
                                alt='test'
                                src='https://fastly.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY'
                                style={{ height: "100%", width: "100%", borderRadius: "50%" }}
                            />

                        </Box>

                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                        }} >
                            <Typography variant='h5'>Michel D.</Typography>
                            <Typography variant='h6'>11:57am</Typography>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1
                    }} >
                        <IconButton>
                            <ThumbUpIcon />
                        </IconButton>
                        <IconButton>
                            <ThumbDownIcon />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingInline: 8 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h5" gutterBottom component="div">
                                Order Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <OrderHistoryExpandViewTable />
                                    <OrderHistoryExpandViewTable />
                                    <TableRow sx={{ background: "#292929" }}>
                                        <TableCell sx={{ borderBottom: "none" }} />
                                        <TableCell sx={{ borderBottom: "none" }} />
                                        <TableCell align="right" sx={{ borderBottom: "none" }}>
                                            <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 1 }}>
                                                <Typography variant='body1'>Sub Total</Typography>
                                                <Typography variant='body1'> {`${RUPEE_SYMBOL} 50`}</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 2 }}>
                                                <Typography variant='body1'>Tax</Typography>
                                                <Typography variant='body1'> {`${RUPEE_SYMBOL} 10`}</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 2 }}>
                                                <Typography variant='h5'>Total</Typography>
                                                <Typography variant='h5'> {`${RUPEE_SYMBOL} 110`}</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}