import { useRoutes } from "react-router-dom";
import "./App.css";
import { Router } from "./router/router";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "./Theme/ThemeVariable";
import 'reactflow/dist/style.css';
import { LoadingAndErrors } from "./views/Loading&Errors/LoadingAndErrors";
import { UberEatsProvider } from "./providers/UberEatsProvider/UberEatsProvider";

function App() {
  const routing = useRoutes(Router);

  return (
    <ThemeProvider theme={baseTheme}>
      <UberEatsProvider>
        <CssBaseline />
        {routing}
      </UberEatsProvider>
      <LoadingAndErrors />
    </ThemeProvider>
  );
}

export default App;