import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { UberEatsOrderDialog } from '../../dialogs/ViewOrderDialog/ViewOrderDialog'
import { defaultUberEatsOrder } from '../../../../redux/uber-eats'

export const ReadyForPickupCard = () => {
  const [openViewOrderDialog, setOpenViewOrderDialog] = useState(false)

  const openOrderDialog = () => {
    setOpenViewOrderDialog(true)
  }

  const closeOrderDialog = () => {
    setOpenViewOrderDialog(false)
  }
  return (
    <>
      <Box sx={{
        width: '100%',
        maxWidth: 600,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        background: "#292929",
        paddingInline: 2,
        paddingBlock: 2,
        borderRadius: 1,
        cursor: "pointer"
      }}
        onClick={openOrderDialog}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }} >
          <Typography variant='h3'>Michelle T.</Typography>
          <Typography variant='h5'>3WEWSF3</Typography>
        </Box>

        <Box sx={{
          height: 50,
          width: 50,
          borderRadius: "50%"
        }} >
          <img
            alt='test'
            src='https://fastly.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY'
            style={{ height: "100%", width: "100%", borderRadius: "50%" }}
          />

        </Box>
      </Box>

      {openViewOrderDialog && <UberEatsOrderDialog open={true} onClose={closeOrderDialog} order_info={defaultUberEatsOrder} />}
    </>
  )
}
