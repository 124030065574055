import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import {
  UberEatsOrderDialog


} from '../../dialogs/ViewOrderDialog/ViewOrderDialog'
import { CustomButton } from '../../../../Components/CustomButton'
import { defaultUberEatsOrder } from '../../../../redux/uber-eats'

export const InProgressOrderCard = () => {
  const [openViewOrderDialog, setOpenViewOrderDialog] = useState(false)

  const openOrderDialog = () => {
    setOpenViewOrderDialog(true)
  }

  const closeOrderDialog = () => {
    setOpenViewOrderDialog(false)
  }
  return (
    <>
      <Box sx={{
        minHeight: 75,
        width: '100%',
        maxWidth: 600,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        background: "#292929",
        borderRadius: 1,
        cursor: "pointer"
      }}
        onClick={openOrderDialog}
      >
        <Box sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }} >
          <Typography variant='h3'>Michelle T.</Typography>
          <Typography variant='h5'>3WEWSF3</Typography>
        </Box>

        <Box sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
        }} >
          <Typography variant='h5'>11:45am</Typography>

          <Box sx={{
            minWidth: 75,
            minHeight: 75,
            borderLeft: "1px solid #717171",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }} >
            <Typography variant='h5' sx={{ textTransform: "uppercase", color: "#717171", fontWeight: 700 }}>Ready</Typography>
          </Box>

        </Box>


      </Box>

      {openViewOrderDialog && <UberEatsOrderDialog
        open={true} onClose={closeOrderDialog}
        order_info={defaultUberEatsOrder}
      />}
    </>
  )
}
