import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";

import Paper from "@mui/material/Paper/Paper";
import { SendOtpOnMail } from "./SendOtpOnMail";
import { ValidateOtpAndResetPassword } from "./ValidateOtpAndResetPassword";
import PageContainer from "../../../Components/PageContainer/PageContainer";

export const ForgetPassword: React.FC = () => {
  const [step, setStep] = React.useState<number>(1);
  const [userEmail, setUserEmail] = React.useState<string>("");

  return (
    <PageContainer title="Forget Password" description="this is Login page">
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            p: 4,
            pt: 5,
            pb: 5,
            border: "none rgb(230, 235, 241)",
            borderRadius: "4px",
            boxShadow: "rgb(0 0 0 / 8%) 0px 1px 4px",
            background: "#fff",
            maxWidth: 400
          }}
        >
          <Typography variant="h2" fontWeight={"bold"} color="#000">
            {step === 0 ? "Forget Password" : "Reset Password"}
          </Typography>
          {step === 0 && (
            <SendOtpOnMail
              onSuccess={(email) => {
                setStep(1);
                setUserEmail(email);
              }}
            />
          )}
          {step === 1 && <ValidateOtpAndResetPassword email={userEmail} />}
        </Paper>

      </Box>
    </PageContainer>
  );
};
