import { ISecurityRoleGroup } from "../views/Settings/Security/SecurityRoleGroupAutoSearch";

type IRemoveDuplicateItems = ISecurityRoleGroup


export const removeDuplicateItems = (
  options: IRemoveDuplicateItems[],
  key: string,
) => {
  // return options.filter((item, index) => options.indexOf(item) === index);
  //@ts-ignore
  return [...new Map(options.map((item) => [item[key], item])).values()];
};