import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const CustomLink: React.FC<{
  to: string;
  label?: string;
  state?: any;
  children?: React.ReactNode;
}> = (props) => {
  const { to, label, state, children } = props;

  if (label) {
    return (
      <Link to={to} state={state} style={{ textDecoration: "none" }}>
        <Typography variant="body1" color={"primary.main"}>
          {" "}
          {label}
        </Typography>
      </Link>
    );
  } else if (children) {
    return (
      <Link to={to} state={state} style={{ textDecoration: "none" }}>
        {children}
      </Link>
    );
  }
  return <></>
};
