import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React from 'react'
import { OutlinedTimePicker } from '../../../../../Components/formComponents/CustomDatePicker';
import { IUpsertManualShift } from '../../../../../redux/shifts';
import moment from 'moment';
import { ControlledCustomSelect } from '../../../../../Components/formComponents';
import Add from '@mui/icons-material/Add';
import { Remove } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';

interface IAddShiftPunchInfoProps {
    data: IUpsertManualShift["time"]
    onChange: (d: IUpsertManualShift["time"]) => void
}
export const AddShiftPunchInfo: React.FC<IAddShiftPunchInfoProps> = ({
    data,
    onChange
}) => {

    const handleAddRowClick = () => {
        const newData = [...data]
        newData.push({
            type: "",
            user_punch_in_out_time: ""
        })
        onChange(newData)
    }

    const handleDeleteRowClick = (idx: number) => {
        const newData = [...data]
        newData.splice(idx, 1)
        onChange(newData)
    }
    return (
        <Box>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Typography variant='h5'>Punch Details</Typography>

            </Box>
            <Grid container spacing={2} mt={.2}>
                {data.map((punch, c_idx) => {
                    return (
                        <>
                            <Grid item xs={5.5} >
                                <ControlledCustomSelect
                                    label="Punch Type"
                                    name="column_title"
                                    fullWidth
                                    // options={getColumnListForReceipt()}
                                    options={[
                                        { label: "Punch-in", value: "PUNCH_IN" },
                                        { label: "Break-in", value: "BREAK_IN" },
                                        { label: "Break-out", value: "BREAK_OUT" },
                                        { label: "Punch-out", value: "PUNCH_OUT" },
                                    ]}
                                    placeholder="Select Punch Type"
                                    value={punch.type}
                                    onChange={(evt) => {
                                        let newData = [...data]
                                        newData[c_idx] = {
                                            ...data[c_idx],
                                            type: evt.target.value as string
                                        }
                                        onChange(newData)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5.5}>
                                <OutlinedTimePicker
                                    label="Punch In"
                                    fullWidth
                                    value={moment(punch.user_punch_in_out_time)}
                                    onChange={(newValue) => {
                                        let newData = [...data]
                                        newData[c_idx] = {
                                            ...data[c_idx],
                                            user_punch_in_out_time: newValue.toISOString()
                                        }
                                        onChange(newData)
                                    }}
                                // error={errors.reservation_date}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => handleDeleteRowClick(c_idx)}>
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </>
                    )
                })}
            </Grid>
            <Button variant='text' sx={{mt: 1, textDecoration: "underline"}} onClick={handleAddRowClick}>
                <Add /> Add New Punch
            </Button>
        </Box>

    )
}
