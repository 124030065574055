import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultCampaignState } from "./campaigns.state";
import { ICampaign } from "./campaigns.types";
import { fetchAllCampaignsListAsync, fetchSingleCampaignAsync } from "./campaigns.actions";

const campaignsSlice = createSlice({
    initialState: defaultCampaignState,
    name: "campaigns",
    reducers: {
        clearCampaignStateSync: (state) => {
            return defaultCampaignState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllCampaignsListAsync.pending, (state, action) => {
            state.campaign_list.loading = LoadState.pending
        })
        builder.addCase(fetchAllCampaignsListAsync.fulfilled, (state, action: PayloadAction<{ data: ICampaign[], count: number }>) => {
            state.campaign_list.loading = LoadState.succeeded
            state.campaign_list.data = action.payload.data
            state.campaign_list.count = action.payload.count
            state.campaign_list.error = null
        })
        builder.addCase(fetchAllCampaignsListAsync.rejected, (state, action) => {
            state.campaign_list.error = action.error.message as string
        })




        builder.addCase(fetchSingleCampaignAsync.pending, (state, action) => {
            state.single_campaign.loading = LoadState.pending
        })
        builder.addCase(fetchSingleCampaignAsync.fulfilled, (state, action) => {
            state.single_campaign.loading = LoadState.succeeded
            state.single_campaign.data = action.payload
            state.single_campaign.error = null
        })
        builder.addCase(fetchSingleCampaignAsync.rejected, (state, action) => {
            state.single_campaign.error = action.error.message as string
        })
    },
});

export const campaignsReducer = campaignsSlice.reducer;
export const { clearCampaignStateSync } = campaignsSlice.actions;
