import { LoadState } from "../../constants/enum";
import { IUpsertManualShift, IPunchInOut, IShiftPunchInOutState, ISingleUserShift } from "./shifts.types";

export const defaultPunchInOut: IPunchInOut = {
    shift_uuid: null,
    login_pin: "",
    user_name: null,
    user_uuid: null,
    user_image: null,
    user_punch_in_out_time: new Date().toISOString(),
    type: "PUNCH_IN",
    punch_date: new Date().toISOString(),
    duration: null,
    total_time: null,
    break_duration: null,
    amount_earn: null,

    status: "ACTIVE"
}


export const defaultSingleUserShift: ISingleUserShift = {
    shift_code: "",

    user_name: "",
    user_uuid: "",

    punch_date: new Date().toISOString(),
    punch_details: [],

    work_time: "",
    amount: "",
}


export const defaultUpsertManualShift: IUpsertManualShift = {
    user_name: "",
    user_uuid: "",
    user_image: null,
    time: [{
        type: "",
        user_punch_in_out_time: "",
    }],
    punch_date: new Date().toISOString(),

    status: "ACTIVE"
}


export const defaultShiftPunchInOutState: IShiftPunchInOutState = {
    all_user_shifts_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    manual_shift_state: {
        loading: LoadState.idle,
        data: defaultUpsertManualShift,
        error: null
    },
    single_punch_in_out: {
        loading: LoadState.idle,
        data: defaultPunchInOut,
        error: null
    },
    single_user_shift: {
        loading: LoadState.idle,
        data: defaultSingleUserShift,
        error: null,
    }
}