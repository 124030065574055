import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TablesReserveChip } from '../../../Components/CustomChip'
import { ICustomTableNode } from '../../../Components/ReactFlowShapes/ReactFlowShapes.types'

interface ITableBottomProps {
    tables: ICustomTableNode[]
}
export const TableBottom: React.FC<ITableBottomProps> = ({
tables
}) => {

    const [tablesList, setTablesList] = useState<ICustomTableNode[]>([])

    useEffect(() => {
        setTablesList((tables || []).filter((table) => table.data.nodeType === "TABLE"))
    }, [tables])

    return (
        <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            mt={2}
            spacing={2}
            sx={{
                paddingBlock: 2,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingRight: 1,
                    borderRight: "1px solid #ccc",
                    gap: 2
                }}
            >
                <Typography variant="h4" fontWeight={"bold"}>
                    {tablesList.filter(table => table.data.availability === "FREE").length}
                </Typography>
                <TablesReserveChip label="Free" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingRight: 2,
                    borderRight: "1px solid #ccc",
                    gap: 2
                }}
            >
                <Typography variant="h4" fontWeight={"bold"}>
                    {tablesList.filter(table => table.data.availability === "RESERVED").length}
                </Typography>

                <TablesReserveChip label="Reserved" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2
                }}
            >
                <Typography variant="h4" fontWeight={"bold"}>
                    {tablesList.filter(table => table.data.availability === "CHECKED_IN").length}
                </Typography>

                <TablesReserveChip label="Checked-in" />
            </Box>
        </Stack>
    )
}
