import React from "react";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../redux";
import { ControlledCustomSelect, ExtendableSelectField, OutlinedTextField } from "../../../../Components/formComponents";
import { IPaymentPaid, upsertSinglePaymentPaidAsync } from "../../../../redux/accounting";
import { order_Status_list, order_type_list } from "../../PaymentsReceived/PaymentsReceived.constants";
import { payment_mode_list } from "../PaymentsPaid.constatnt";
import { SingleFileUploadButton } from "../../../../Components/FileUploads";


export interface IUpsertPaymentsPaidDialogProps {
  open: boolean;
  initialState: IPaymentPaid;
  onClose: () => void;
}
export const UpsertPaymentsPaidDialog: React.FC<IUpsertPaymentsPaidDialogProps> = (props) => {
  const { open, initialState, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [fileAttachment, setFileAttachment] = React.useState<File | null>( null);

  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: { ...initialState },
    validate: (values) => {
      const errors: any = {};
      if (!values.vendor) {
        errors.vendor = "*This field is required.";
      }
      if (!values.category) {
        errors.category = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSinglePaymentPaidAsync({...values, file: fileAttachment})
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });

  return (
    <Dialog
      open={open}
      title="Add/Edit Payment Paid Information"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
          disabled: saveLoading
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ExtendableSelectField
              label="Vendor"
              value={values.vendor}
              onChange={handleChange}
              options={[]}
            />
          </Grid>

          <Grid item xs={12}>
            <ExtendableSelectField
              label="Category"
              value={values.category}
              onChange={handleChange}
              options={[]}
            />
          </Grid>

          <Grid item xs={12}>
            <OutlinedTextField
              label="Order Number"
              id="order_number"
              value={values.order_number}
              onChange={handleChange}
              error={errors.order_number}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Invoice Number"
              id="invoice_number"
              value={values.invoice_number}
              onChange={handleChange}
              error={errors.invoice_number}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Total Tax"
              id="total_tax"
              type="number"
              value={values.total_tax}
              onChange={handleChange}
              error={errors.total_tax}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Total Amount"
              id="total_amount"
              type="number"
              value={values.total_amount}
              onChange={handleChange}
              error={errors.total_amount}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Payment Mode"
              name="payment_mode"
              value={values.payment_mode}
              onChange={handleChange}
              options={payment_mode_list}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Order Status"
              name="order_status"
              value={values.order_status}
              onChange={handleChange}
              options={order_Status_list}
            />
          </Grid>
          <Grid item xs={12}>
            <SingleFileUploadButton
              title="Upload Attachment"
              fileUrl={values.attachment}
              onChange={(file) => setFileAttachment(file)}
              onDelete={() => setFieldValue("attachment", null)}
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
