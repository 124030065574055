import React from "react";
import { ICustomPaymentTypeButtonProps } from "./interface/interface";
import { Box, Button, Typography } from "@mui/material";

export const CustomPaymentTypeButton: React.FC<
    ICustomPaymentTypeButtonProps
> = (props) => {
    const { sx, icon, label, onClick  } = props;
    return (
        <Box sx={{ width: "100%" }}>
            <Button
                variant="outlined"
                fullWidth
                sx={{
                    borderRadius: 2,
                    py: 1.5,
                    px: "auto",
                    ...sx,
                }}
                onClick={onClick}
                {...props}
            >
                {icon}
            </Button>
            <Typography
                textAlign={"center"}
                mt={1}
                variant={"h6"}
                fontWeight={"bold"}
            >
                {label}
            </Typography>
        </Box>
    );
};
