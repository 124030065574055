import { LoadState } from "../../../constants/enum";
import { IChartOfAccount, IChartOfAccountState } from "./charts-of-account.types";

export const defaultChartOfAccount: IChartOfAccount = {
    charts_of_account_uuid: null,
    detail_type: "",
    account_name: "",
    account_number: "",
    account_type: "",
    currency: "",
    current_balance: "",
    month_opening_balance: "",
    year_opening_balance: "",

    create_ts: new Date().toISOString(),
    insert_ts: new Date().toISOString()
}

export const defaultChartOfAccountState: IChartOfAccountState = {
    charts_of_account_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_charts_of_account: {
        loading: LoadState.idle,
        data: defaultChartOfAccount,
        error: null
    }
}