import React, {  } from "react";
import { Typography, } from "@mui/material";
import { Dialog } from "../../../Components/Dialogs/Dialog";
import { IReservation, upsertSingleReservationAsync } from "../../../redux/reservations";
import { useAppDispatch } from "../../../redux";


export interface ICancelReservationDialogProps {
    open: boolean;
    reservation: IReservation
    onClose: () => void;
}


export const CancelReservationDialog: React.FC<ICancelReservationDialogProps> = (
    props
) => {
    const { open, reservation, onClose } = props;
    const dispatch = useAppDispatch();


    const handleCancelClick = () => {
        dispatch(
            upsertSingleReservationAsync({
                ...reservation,
                status: "CANCELLED"
            })
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                onClose()
            }
        }).finally(() => {
            onClose()
        });
    }

    return (
        <Dialog
            open={open}
            title="Cancel Reservation"
            size="sm"
            onClose={onClose}
            actions={[
                {
                    type: "button",
                    variant: "outlined",
                    label: "No",
                    onClick: onClose,
                },
                {
                    type: "submit",
                    variant: "contained",
                    label: "Yes",
                    onClick: handleCancelClick
                },
            ]}
        >
            <Typography variant="h3">Are you sure to want to Cancel this Reservation</Typography>
        </Dialog>
    );
};
