export enum LoadState {
  idle = 0,
  pending = 1,
  succeeded = 2,
  failed = 3,
}


export enum APP_MODULE_ACCESS_LIST {
  LEADS = "ENQUIRY|ENQUIRY|LATEST_ENQUIRY_WITH_PROJECT",
  PRODUCTS = "PRODUCTS|PRODUCTS|LATEST_PRODUCT",
  QUOTES = "QUOTES|QUOTES|LATEST_QUOTES",
  PERFORMA = "QUOTES|PROFORMA INVOICE|LATEST_PROFORMA_INVOICE",
  ORDER_PROCCESSING = "",
  ORDER = "ENQUIRY|ORDERS|LATEST_ORDER",
  ORDER_REQUESTED = "APPROVAL|ORDER PROCESSING|LATEST_APPROVAL",
  RENDERINGS_JOBS = "RENDERINGS|JOBS|LATEST_RENDERING",
  RENDERINGS_PARTNERS = "RENDERINGS|PARTNERS|LATEST_RENDERING_PARTNERS",

  CUSTOMERS = "ENTITIES|CUSTOMERS|LATEST_CUSTOMER",
  CONTACTS = "ENTITIES|CONTACTS|LATEST_CONTACTS",
  TASKBOARD = "TASKS|TASKBOARD|LATEST_TASKS",
  USERS = "USERS|USERS|LATEST_USER",
  ROLES = "latest_roles",
  SECURITY = "SECURITY|SECURITY|ROLE_MODULE",
  COSTING_SHEET = "COSTING SHEET|COSTING SHEET|LATEST_COSTING_SHEET",
  APPROVAL_QUOTE = "APPROVAL|QUOTES|LATEST_APPROVAL",
  APPROVAL_ITEM = "APPROVAL|PRODUCT|LATEST_APPROVAL",
  APPROVAL_COSTINGSHEET = "APPROVAL|COSTING SHEET|LATEST_APPROVAL",
  APPROVAL_ORF = "APPROVAL|ORF|LATEST_APPROVAL",
  APPROVALS_LIST = "APPROVAL|ORDER PROCESSING|LATEST_APPROVAL",
  APPROVALS_DRAWING = "APPROVAL|DRAWING|LATEST_APPROVAL",
  APPROVALS_BOM = "APPROVAL|BOM|LATEST_APPROVAL",
  BOM = "BOM|BOM|LATEST_BOM",
  DRAWING = "DRAWING|DRAWING|LATEST_DRAWINGS",
  ECN = "ECN|ECN|LATEST_ECN",
  NCP = "NCP|NCP|LATEST_NCP",
  // APPROVAL = "approval"
}
