import { NodeToolbar, Position } from 'reactflow';
import { Stack } from '@mui/material';
import { OutlinedTextField } from '../../../formComponents';

type IRightToolbarProps = {
  title: string;
  onTitleChange: (title: string) => void;
  tableNumber: number;
  onTableNumberChange: (tableNo: number) => void
};

export const RightToolbar: React.FC<IRightToolbarProps> = ({
  title,
  tableNumber,
  onTitleChange,
  onTableNumberChange
}) => {
  return (
    <NodeToolbar className='nodrag' position={Position.Right} offset={10} style={{top: -10}}>
      <Stack direction="column" alignItems="center" spacing={2} >
        <OutlinedTextField
          label={"Table Name"}
          sx={{ minWidth: 100 }}
          value={title}
          onChange={(evt) => onTitleChange(evt.target.value)}
        />
        <OutlinedTextField
          id="tableNumber"
          label={"Table Number"}
          type="number"
          sx={{ minWidth: 100 }}
          value={tableNumber}
          onChange={(evt) => onTableNumberChange(Number(evt.target.value))}
        />
      </Stack>
    </NodeToolbar>
  );
}