import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICommonState } from "./common.types";
import { defaultCommonState } from "./common.state";



const commonSlice = createSlice({
    initialState: defaultCommonState,
    name: "common",
    reducers: {
        displayErrorDialog: (state, action: PayloadAction<ICommonState["errorVisible"]>) => {
            state.errorVisible = action.payload
        },
        closeErrorDialog: (state) => {
            state.errorVisible = null
        },
        displaySnackBarDialog: (state, action: PayloadAction<ICommonState["snackbarVisible"]>) => {
            state.snackbarVisible = action.payload
        },
        closeSnackBarDialog: (state) => {
            state.snackbarVisible = null
        },
    },
});

export const commonReducer = commonSlice.reducer;
export const {
    displayErrorDialog,
    closeErrorDialog,
    displaySnackBarDialog,
    closeSnackBarDialog
} = commonSlice.actions;