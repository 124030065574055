import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { ITaxInfo } from "./tax-info.types"

const GET_TAX_INFO_BASE_URL = "/company/get-tax"
const UPSERT_TAX_INFO_BASE_URL = "/company/upsert-tax"


export const fetchTaxInfoListAsync = createAsyncThunk<{ data: ITaxInfo[], count: number }, IQueryParams>(
    'tax-info/fetchTaxInfoListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_TAX_INFO_BASE_URL}${searchQuery}`)
        return response.data as { data: ITaxInfo[], count: number }
    },
)

export const fetchSingleTaxInfoAsync = createAsyncThunk<ITaxInfo, string>(
    'tax-info/fetchSingleTaxInfoAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_TAX_INFO_BASE_URL}?user_uuid=${uuid}`)
        return response.data
    },
)


export const upsertNewTaxInfoAsync = createAsyncThunk<ITaxInfo, ITaxInfo>(
    'tax-info/upsertNewTaxInfoListAsyncAsync', async (payload: ITaxInfo, thunkAPI) => {
        const response = await axios_api.post(UPSERT_TAX_INFO_BASE_URL, payload)
        thunkAPI.dispatch(fetchTaxInfoListAsync({
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)


