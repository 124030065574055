import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../common";
import { axios_api } from "../../api/axios-api";
import { IReservation } from "./reservations.types";

const GET_RESERVATIONS_BASE_URL = "/reservation/get-reservation"
const UPSERT_RESERVATIONS_BASE_URL = "/reservation/upsert-reservation"

export const fetchTableReservationListAsync = createAsyncThunk<{ recordCount: number, data: IReservation[] }, IQueryParams>(
    'reservations/fetchTableReservationListAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_RESERVATIONS_BASE_URL}${searchQuery}`)
        const data: IReservation[] = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data } //.filter((res) => res.status !== "CANCELED")
    },
)


export const upsertSingleReservationAsync = createAsyncThunk<IReservation, IReservation>(
    'reservations/upsertSingleReservationAsync', async (payload: IReservation, thunkAPI) => {
        const response = await axios_api.post(UPSERT_RESERVATIONS_BASE_URL, payload)
        thunkAPI.dispatch(fetchTableReservationListAsync({
            columns: [],
            page: 1,
            status: "-1",
            value: "",
            rowsPerPage: 10
        }))
        return response.data
    },
)