

export const inventory_category_list: { label: string; value: string | number }[]  = [
    {label: "Vegetables", value: "Vegetables"},
    {label: "Spices", value: "Spices"},
    {label: "Drinks", value: "Drinks"},
    {label: "Sweets", value: "Sweets"},
    // {label: "", value: ""},
]

export const inventory_vendor_list: { label: string; value: string | number }[]  = [
    {label: "Vendor One", value: "Vendor One"},
    {label: "Vendor Two", value: "Vendor Two"},
    {label: "Vendor Three", value: "Vendor Three"},
    // {label: "", value: ""},
]