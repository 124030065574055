import { LoadState } from "../../../constants/enum"
import { defaultProductCustomization } from "../customization"
import { IProduct, IProductIngredient, IProductState } from "./products.types"

export const defaultProductIngredient: IProductIngredient = {
    inventory_uuid: "",
    ingredient_name: "",
    quantity: 0,
    unit: ""
}
export const defaultProduct: IProduct = {
    product_uuid: null,
    product_name: "",
    category_uuid: "",
    category_name: "",

    product_description: "",
    product_image: "",

    product_sell_its_own: false,

    product_type: "",
    product_price_by: "EACH",
    product_price: 0,
    product_weight_unit: "",
    product_tax: 0,
    product_tax_amount: 0,


    is_out_of_stock: false,
    out_of_stock_for: null,

    product_customizations: [defaultProductCustomization],
    product_ingredients: [defaultProductIngredient],
    // Energy  Values
    calories: 0,
    kilojoules: 0,

    // Extra Details
    gst_number: "",
    external_id: "",
    external_data: "",

    outlet_uuid: "",
    inventory_uuid: "",
    status: "ACTIVE"
}

export const defaultProductState: IProductState = {
    product_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_product: {
        loading: LoadState.idle,
        data: defaultProduct,
        error: null,
    }
}