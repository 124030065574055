import React, { useState } from "react";

import { Box, Snackbar, Typography } from "@mui/material";
import { IUberEatsProviderState } from "./UberEatsProvider.types";
import { CustomButton } from "../../Components/CustomButton";
import { UberEatsNewOrderDialog } from "./dialogs/NewOrderDialog";
import { useAppDispatch } from "../../redux";
import { fetchUberEatsOrderHistoryListAsync } from "../../redux/uber-eats";


const UberEatsContext = React.createContext<IUberEatsProviderState>({
    data: ""
});

export const useUberEatsProvider = () => React.useContext(UberEatsContext);

export const UberEatsProvider: React.FC<{ children: React.ReactNode }> = (
    props,
) => {
    const dispatch = useAppDispatch();
    const [showNotificationDialog, setShowNotificationDialog] = useState<boolean>(true)
    const [showNewOrderDialog, setShowNewOrderDialog] = useState<boolean>(false)

    React.useEffect(() => {
        // Initial fetch
        dispatch(fetchUberEatsOrderHistoryListAsync(""));

        // Set up the interval to fetch orders every minute
        const intervalId = setInterval(() => {
            dispatch(fetchUberEatsOrderHistoryListAsync(""));
        }, 60000); // 60000 ms = 1 minute

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch]);



    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return
        setShowNotificationDialog(false);
    };
    const onViewOrderClick = () => {
        setShowNotificationDialog(false)
        setShowNewOrderDialog(true)
    }


    
    return (
        <UberEatsContext.Provider
            value={{
                data: ""
            }}
        >
            {props.children}
            {showNotificationDialog && (
                <Snackbar
                    open={showNotificationDialog}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ maxWidth: 400, minHeight: 150, background: "#505050", padding: 2, borderRadius: 1 }}
                >
                    <Box>
                        <Typography variant='h3' fontWeight={700}>
                            One New Order, Please view order details by clicking view button.
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                            <CustomButton size="large"
                                sx={{ paddingInline: "16px !important", fontWeight: 600, fontSize: 16 }}
                                variant="outlined"
                                onClick={handleClose}>
                                Close
                            </CustomButton>
                            <CustomButton
                                size="large" sx={{ paddingInline: "16px !important", fontWeight: 600, fontSize: 16 }}
                                variant="contained"
                                onClick={onViewOrderClick} >
                                View
                            </CustomButton>
                        </Box>
                    </Box>
                    {/* <SnackbarContent
                        message={
                            'one New Order, Please Accept by clicking accept button \n'
                        }
                        action={

                        }
                    /> */}
                </Snackbar>
            )}

            {showNewOrderDialog &&
                <UberEatsNewOrderDialog
                    open={showNewOrderDialog}
                    onClose={() => setShowNewOrderDialog(false)}
                    onOrderAccept={() => setShowNewOrderDialog(false)}
                />}
        </UberEatsContext.Provider >
    );
};
