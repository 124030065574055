/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { StandardCard } from "../../../Components/Card";
import { OutlinedTextField } from "../../../Components/formComponents";
import { OutlinedTimePicker } from "../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { ProfileIntroCard } from "../../../Components/Profile/ProfileIntoCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchSingleCompanyAsync, upsertCompanyInfoAsync } from "../../../redux/settings/company";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { COMPANY_OUTLETS_ROUTE, MANAGE_SINGLE_OUTLETS_ROUTE, OUTLET_REGISTERS_ROUTE } from "./CompanyOutlets.constants";
import { TabsList } from "../../../Components/Tab/Tab";
import { TabPanel } from "../../../Components/Tab/TabPanel";
import { ManageSingleOutlet } from "./ManageSingleOutlet";
import { OutletRegistersTable } from "./OutletRegisters/OutletRegistersTable";

interface IManageCompanyOutletWithTabsProps {
  activeTab?: number
}
export const ManageCompanyOutletWithTabs: React.FC<IManageCompanyOutletWithTabsProps> = (props) => {
  const { uuid: outlet_uuid, register_uuid } = useParams<{ uuid?: string, register_uuid?: string }>();
  
  const { activeTab } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tab, setTab] = React.useState(activeTab || 0);

  React.useEffect(() => {
    if(pathname.includes("registers")) {
      setTab(1)
    } else {
      setTab(0)
    }
  }, [pathname])

  const handleTabChange = (url: string) => {
    navigate(url);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    if (newValue === 0) handleTabChange(`${COMPANY_OUTLETS_ROUTE}/manage/${outlet_uuid}`);
    if (newValue === 1) handleTabChange(`${COMPANY_OUTLETS_ROUTE}/${outlet_uuid}/registers`);
  };


  return (
    <PageContainer title="Outlets" description="Outlets page" sx={{ paddingInline: 2 }} >
      <TabsList
        selectedTab={tab}
        tabData={[
          { tabLabel: "Outlet", tabLink: `${COMPANY_OUTLETS_ROUTE}/manage/${outlet_uuid}` },
          { tabLabel: "Registers", tabLink: `${COMPANY_OUTLETS_ROUTE}/${outlet_uuid}/registers` },
        ]}
        onChange={handleChange}
      />


      <TabPanel value={tab} index={0}>
        <ManageSingleOutlet />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <OutletRegistersTable />
      </TabPanel>
    </PageContainer>
  );
};
