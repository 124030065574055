import React, { useState } from 'react'
import { ISingleUserPunch } from '../../../../../redux/shifts'
import { Box, Button, IconButton, Paper, Typography } from '@mui/material';
import moment from 'moment';
import Edit from '@mui/icons-material/Edit';
import { PunchInOutUpdateDialog } from './PunchInOutUpdateDialog';
import { punch_type_without_underscore } from '../../../ShiftAndPunchInOut.constants';

interface ISingleUserPunchDetailsProps {
    data: ISingleUserPunch,
    onChange: (data: ISingleUserPunch) => void
}

export const SingleUserPunchDetails: React.FC<ISingleUserPunchDetailsProps> = ({
    data,
    onChange
}) => {
    const { user_image, type, user_punch_in_out_time } = data;
    const [openEditDialog, setOpenEditDialog] = useState<ISingleUserPunch | null>(null)


    const handlePunchInfoChange = (data: ISingleUserPunch) => {
        onChange(data)
        setOpenEditDialog(null)
    }

    return (
        <>
            <Paper
                sx={{
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2
                }}
            >
                <Box sx={{
                    width: 200,
                    height: 200,
                    borderRadius: "50%",
                    background: "#fff"
                }}>
                    {user_image && <img src={user_image || ""} height="100%" width="100%" alt='user' />}
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1
                }}>
                    <Typography variant='body1' fontWeight={700}>{`${punch_type_without_underscore[type]} ${moment(user_punch_in_out_time).format("hh:mm A")}`}</Typography>
                    <Button variant='contained' onClick={() => setOpenEditDialog(data)}><Edit sx={{marginRight: 0.5}} />Edit</Button>
                </Box>
            </Paper>
            {openEditDialog && (
                <PunchInOutUpdateDialog
                    open={true}
                    onClose={() => setOpenEditDialog(null)}
                    data={openEditDialog}
                    onSubmit={handlePunchInfoChange}
                />
            )}
        </>
    )
}
