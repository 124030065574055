import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultSecurityState } from "./security.state";
import { IFetchSecurityGroupOutput, fetchSecurityGroupAsync, fetchSecurityRoleGroupListAsync, fetchSecurityRolesListAsync } from "./security.actions";
import { produce } from "immer";
import { ISecurityRole, ISecurityRoleGroup } from "./security.types";

const securitySlice = createSlice({
    initialState: defaultSecurityState,
    name: "security",
    reducers: {
        clearSecurityStateSync: (state) => {
            return defaultSecurityState
        },
        clearSecurityRoleGroupListSync: (state) => {
            state.roleGroups.data = defaultSecurityState.roleGroups.data
        },
        clearSecurityApprovalStateSync: (state) => {
            state.approval = defaultSecurityState.approval
        },


    },
    extraReducers: (builder) => {
        // ########################################################################
        // ######################### Security Roles ###############################
        // ########################################################################

        builder.addCase(fetchSecurityRolesListAsync.pending, (state, action) => {
            state.security_roles_list.loading = LoadState.pending
        })
        builder.addCase(fetchSecurityRolesListAsync.fulfilled, (state, action: PayloadAction<{ data: ISecurityRole[], totalRecords: number }>) => {
            state.security_roles_list.loading = LoadState.succeeded
            state.security_roles_list.data = action.payload.data
            state.security_roles_list.totalRecords = action.payload.totalRecords
            state.security_roles_list.error = null
        })
        builder.addCase(fetchSecurityRolesListAsync.rejected, (state, action) => {
            state.security_roles_list.error = action.error.message as string
        })


        // ########################################################################
        // ######################### Role_Group ##################################
        // ########################################################################

        builder.addCase(fetchSecurityRoleGroupListAsync.pending, (state, action) => {
            state.roleGroups.loading = LoadState.pending
        })
        builder.addCase(fetchSecurityRoleGroupListAsync.fulfilled, (state, action: PayloadAction<{ data: ISecurityRoleGroup[], totalRecords: number }>) => {
            state.roleGroups.loading = LoadState.succeeded
            state.roleGroups.data = action.payload.data
            state.roleGroups.totalRecords = action.payload.totalRecords
            state.roleGroups.error = null
        })
        builder.addCase(fetchSecurityRoleGroupListAsync.rejected, (state, action) => {
            state.roleGroups.error = action.error.message as string
        })




        builder.addCase(fetchSecurityGroupAsync.pending, (state, action) => {
            const newState = produce(state, (draftState) => {
                draftState.groups.loading = LoadState.pending;
                draftState.groups.group = defaultSecurityState["groups"]["group"];
                draftState.groups.error = null;
            });
            return newState
        })
        builder.addCase(fetchSecurityGroupAsync.fulfilled, (state, action: PayloadAction<IFetchSecurityGroupOutput>) => {
            const { nested, roleName, roleGroup, roleStatus } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.groups.loading = LoadState.succeeded;
                draftState.groups.group = nested;
                draftState.groups.roleName = roleName;
                draftState.groups.role_group = roleGroup;
                draftState.groups.status = roleStatus;
            });
            return newState;
        })
        builder.addCase(fetchSecurityGroupAsync.rejected, (state, action) => {
            const newState = produce(state, (draftState) => {
                draftState.groups.loading = LoadState.failed;
                draftState.groups.error = action.error.message as string
            });
            return newState;
        })
    },
});

export const securityReducer = securitySlice.reducer;
export const { clearSecurityStateSync, clearSecurityRoleGroupListSync,
    clearSecurityApprovalStateSync
} = securitySlice.actions;
