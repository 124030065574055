import { Avatar, Button, Stack, Typography } from '@mui/material';
import React from 'react'
import { StandardTableActions } from '../../../Components/StandardTableActions/StandardTableActions';
import { DataTable } from '../../../Components/Table/DataTable';
import { IDataTableProps, MobileLogoRenderType, RenderType } from '../../../Components/Table/interfaces/IDataTableProps';
import { useAppSelector, IStoreState, useAppDispatch } from '../../../redux';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { CustomButton } from '../../../Components/CustomButton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { MARKETING_CAMPAIGNS_ROUTE } from '../Marketing.constants';
import { ICoupon, clearCouponsStateSync, fetchAllCouponsListAsync } from '../../../redux/marketing/coupon-codes';
import { ICampaign } from '../../../redux/marketing/campaigns';

export const CampaignsTable = () => {
    const {
        data: campaignsData,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.marketing.campaigns.campaign_list);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 10,
    });


    const dataTableProps: IDataTableProps = {
        isPagination: true,
        rowsPerPageOptions: 25,
        isDataLoading: false,
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },

        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: ICampaign) => {
                    return (
                        <StandardTableActions
                            onEditClick={() => navigate(`${MARKETING_CAMPAIGNS_ROUTE}/manage/${row.campaign_uuid}`)}
                        />
                    );
                },
            },
            {
                key: "campaign_name",
                headerName: "Campaign Name",
                fieldName: "campaign_name",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "discount_type",
                headerName: "Discount Type",
                fieldName: "discount_type",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "coupon_amount",
                headerName: "Coupon Amount",
                fieldName: "coupon_amount",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },

            {
                key: "minimum_spend",
                headerName: "Minimum Spend",
                fieldName: "minimum_spend",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "maximum_spend",
                headerName: "Maximum Spend",
                fieldName: "maximum_spend",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "coupon_expiry_date",
                headerName: "Coupon Expiry Date",
                fieldName: "coupon_expiry_date",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (values, row: ICoupon) => {
                    return moment(row.coupon_expiry_date).isValid() ? moment(row.coupon_expiry_date).format("DD-MM-YYYY") : "--";
                },
            },

        ],

        items: campaignsData,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };


    React.useEffect(() => {
        dispatch(
            fetchAllCouponsListAsync({
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
            }),
        );
    }, [pagination]);

    React.useEffect(() => {
        return () => {
            dispatch(clearCouponsStateSync());
        };
    }, []);



    return (
        <PageContainer title="Campaigns" description='inner page' >
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='h2'>Campaigns</Typography>
                </Stack>
                <CustomButton
                    variant="contained"
                    color="primary"
                    sx={{
                        display: "flex",
                        marginLeft: "auto",
                    }}
                    onClick={() => navigate(`${MARKETING_CAMPAIGNS_ROUTE}/manage`)}
                >
                    Run New Campaign
                </CustomButton>
            </Stack>
            <DataTable {...dataTableProps} />
        </PageContainer>
    );
}
