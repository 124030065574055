import React, { useEffect, useState } from "react";
import { StandardCard } from "../../../../Components/Card";
import { Box, Stack } from "@mui/material";
import { CustomRoundButton } from "../../../../Components/CustomButton";
import { ControlledCustomSelect } from "../../../../Components/formComponents";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import { UserReservationCard } from "./UserReservationCard";
import { IReservation, deleteReservationsSync, fetchTableReservationListAsync } from "../../../../redux/reservations";
import { UpsertReservationDialog } from "../../dialogs/UpsertReservationDialog";
import moment from "moment";
import { CustomDatePicker } from "../../../../Components/formComponents/CustomDatePicker";
import { checkInTableByNumberSync } from "../../../../redux/tables";
import { CancelReservationDialog } from "../../dialogs/CancelReservationDialog";

export const ReservationsListPanel: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    data: reservations_list,
  } = useAppSelector((storeState: IStoreState) => storeState.reservations.reservations_list);

  const [selectedReservation, setSelectedReservation] = useState<IReservation | null>(null)
  const [openDialog, setOpenDialog] = React.useState<IReservation | null>(null);
  const [openCancelDialog, setOpenCancelDialog] = React.useState<IReservation | null>(null);
  const [dateFilter, setDateFilter] = React.useState<string>("ALL");
  const [reservationsList, setReservationsList] = React.useState<IReservation[]>([]);
  const [customDate, setCustomDate] = React.useState<string | null>(null);
  const [customDateDialog, setCustomDateDialog] = React.useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchTableReservationListAsync({
      page: 1,
      rowsPerPage: 10000
    }))
  }, [])


  // useEffect(() => {
  //   setReservationsList(reservations_list)
  // }, [reservations_list])

  useEffect(() => {
    setCustomDateDialog(false)
    setCustomDate(null)
    if (dateFilter === "ALL") {
      setReservationsList(reservations_list)
    } else if (dateFilter === "CUSTOM") {
      setCustomDateDialog(true)
    } else {
      const newList = reservations_list.filter((res) => moment(res.reservation_date).format("yyyy-MM-DD") === dateFilter)
      setReservationsList(newList)
    }
  }, [dateFilter, reservations_list])

  useEffect(() => {
    if (dateFilter === "CUSTOM" && customDate) {
      const newCustomDate = moment(customDate).format("yyyy-MM-DD")
      const newList = reservations_list.filter((res) => moment(res.reservation_date).format("yyyy-MM-DD") === newCustomDate)
      setReservationsList(newList)
    }
  }, [customDate])

  const onCheckInButtonClick = () => {
    dispatch(checkInTableByNumberSync(selectedReservation?.table_no))
    dispatch(deleteReservationsSync(selectedReservation?.reservation_uuid))
  }

  const onCancelClick = () => {
    setOpenCancelDialog(selectedReservation)
    // dispatch(deleteReservationsSync(selectedReservation?.reservation_uuid))
  }


  return (
    <StandardCard
      heading="Reservations"
      fullHeight
      sx={{
        border: "1px solid #626262",
      }}
    >
      <Stack spacing={2} direction={"column"} width={"100%"} mb={3}>
        <ControlledCustomSelect
          options={[
            { label: "All", value: "ALL" },
            { label: "Today", value: moment().format("yyyy-MM-DD") },
            { label: "Yesterday", value: moment().subtract(1, "day").format("yyyy-MM-DD") },
            { label: "Custom", value: "CUSTOM" },
          ]}
          value={dateFilter}
          onChange={(evt) => setDateFilter(evt.target.value as string)}
          sx={{ minWidth: 120 }}
          fullWidth size="small" />

        {customDateDialog && <CustomDatePicker
          label="Select Date"
          fullWidth
          fieldName="reservation_date"
          value={customDate || ""}
          onChange={(newValue, formattedDate) => {
            setCustomDate(formattedDate);
          }}
        />}
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "60vh",
        }}
      >
        <Box
          sx={{
            height: "calc(100% - 8vh)",
            overflowY: "scroll",
            paddingRight: 1.5,
            mb: 1,
          }}
        >
          {reservationsList.map((res, res_idx) => {
            return (
              <UserReservationCard
                selected={reservations_list.findIndex((res) => res.reservation_uuid === selectedReservation?.reservation_uuid) === res_idx}
                data={res}
                onClick={() => setSelectedReservation(selectedReservation?.reservation_uuid === res.reservation_uuid ? null : res)}
                onDoubleClick={() => setOpenDialog(res)}
              />
            )
          })}

        </Box>

        <Stack direction={"row"} spacing={2}>
          <CustomRoundButton disabled={!selectedReservation} fullWidth title="Cancel" variant="outlined" onClick={onCancelClick} />
          <CustomRoundButton disabled={!selectedReservation} fullWidth title="Check-in" onClick={onCheckInButtonClick} />
        </Stack>
      </Box>
      {openDialog && (
        <UpsertReservationDialog
          open={true}
          reservation={openDialog}
          onClose={() => setOpenDialog(null)}
        />
      )}
      {openCancelDialog && (
        <CancelReservationDialog
          open={true}
          reservation={openCancelDialog}
          onClose={() => setOpenCancelDialog(null)}
        />
      )}
    </StandardCard>
  );
};
