import React from "react";
// import { DataTable, ITableColumn } from "../../../Components/Tables/Table";
import { Avatar } from "@mui/material";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../Components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../Components/Table/DataTable";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { useNavigate } from "react-router-dom";
import { StandardTableActions } from "../../../Components/StandardTableActions/StandardTableActions";
import { UpsertBankInfoDialog } from "./dialogs/UpsertBankInfoDialog";
import { CustomButton } from "../../../Components/CustomButton";
import { IPosBankInfo, clearPosBankInfoState, defaultPosBankInfo, fetchPosBankInfoListWithArgsAsync } from "../../../redux/accounting";
import moment from "moment";

export const BankInfoTable = () => {
  const {
    data: bank_info_list,
  } = useAppSelector((storeState: IStoreState) => storeState.accounting.bankInfo.bank_info_list);

  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openUpsertDialog, setOpenUpsertDialog] = React.useState<IPosBankInfo | null>(null);


  const dataTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: 25,
    isDataLoading: false,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IPosBankInfo) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenUpsertDialog(row)}
            />
          );
        },
      },
      {
        key: "bank_name",
        headerName: "Bank Name",
        fieldName: "bank_name",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "account_number",
        headerName: "Account Number",
        fieldName: "account_number",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "transit_number",
        headerName: "Transit Number",
        fieldName: "transit_number",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "institution_number",
        headerName: "Institution Number",
        fieldName: "institution_number",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "opening_balance",
        headerName: "Opening Balance",
        fieldName: "opening_balance",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "opening_date",
        headerName: "Opening Date",
        fieldName: "opening_date",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (values, row: IPosBankInfo) => {
          return moment(row.opening_date).isValid() ? moment(row.opening_date).format("YYYY-MM-DD") : "--";
        },

      },
      // {
      //   key: "insert_ts",
      //   headerName: "Date",
      //   fieldName: "insert_ts",
      //   enableSorting: true,
      //   renderType: RenderType.DATE_DARK_COLOR,
      //   exportCellWidth: 25,
      //   onExportRender: (values) => {
      //     return formatDateWithTime(values.value);
      //   },
      // },
    ],

    items: bank_info_list,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };


  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearPosBankInfoState());
    };
  }, []);

  const fetchList = () => {
    dispatch(
      fetchPosBankInfoListWithArgsAsync({
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
      }),
    );
  };

  return (
    <>
      <CustomButton
        variant="contained"
        color="primary"
        sx={{
          display: "flex",
          marginLeft: "auto",
          marginY: 2,
        }}
        onClick={() => setOpenUpsertDialog(defaultPosBankInfo)}
      >
        Add New
      </CustomButton>
      <DataTable {...dataTableProps} />
      {openUpsertDialog && <UpsertBankInfoDialog
        open={true}
        initialState={openUpsertDialog}
        onClose={() => setOpenUpsertDialog(null)}
      />}
    </>
  );
};
