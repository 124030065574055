import { NodeToolbar, Position } from 'reactflow';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { OutlinedTextField } from '../../../formComponents';
import { toolbar_colors } from '../../ReactFlowShapes.constants';

type ITopToolbarProps = {
  position?: Position
  activeColor: string;
  onColorChange?: (color: string) => void;
  onDelete: () => void;
  onDuplicate: () => void;
};

export const  TopToolbar: React.FC<ITopToolbarProps> = ({
  onColorChange = () => false,
  activeColor,
  position,
  onDelete,
  onDuplicate,
}) => {
  return (
    <NodeToolbar className='nodrag' position={position || Position.Top} offset={32}>
      {toolbar_colors.map((color) => (
        <button
          key={color}
          style={{ backgroundColor: color }}
          onClick={() => onColorChange(color)}
          className={`color-swatch ${color === activeColor ? 'active' : ''}`}
        />
      ))}
      <Stack direction="row" alignItems="center" sx={{ borderLeft: "1px solid #dadada", marginLeft: 1, paddingLeft: 1 }} spacing={1} >
        <Tooltip title="Duplicate">
          <ContentCopyIcon sx={{ cursor: "pointer" }} onClick={onDuplicate} />
        </Tooltip>
        <Tooltip title="Delete">
          <DeleteIcon sx={{ cursor: "pointer" }} onClick={onDelete} />
        </Tooltip>
        {/* <OutlinedTextField
          id="tableNumber"
          label={"Table Number"}
          type="number"
          sx={{minWidth: 100}}
          value={tableNumber}
          onChange={(evt) => onTableNumberChange(Number(evt.target.value))}
        /> */}
      </Stack>
    </NodeToolbar>
  );
}