import { Box, Chip, InputAdornment } from "@mui/material";
import React from "react";
import { OutlinedTextField } from "../../../../Components/formComponents";

export const MultiPhoneNumberField: React.FC<{
  label?: string;
  placeholder: string;
  error?: string;
  emails: string[];
  onChange: (emails: string[]) => void;
}> = (props) => {
  const { label, emails, onChange, placeholder, error } = props;
  const [phoneNumbersList, setPhoneNumbersList] = React.useState<string[]>([]);
  const [newNumberText, setNewNumberText] = React.useState<string>("");
  const [isError, setIsError] = React.useState<boolean>(false);

  React.useEffect(() => {
    setPhoneNumbersList(emails);
  }, []);

  const handleDelete = (index: number) => {
    const currentMails = [...phoneNumbersList];
    currentMails.splice(index, 1);
    setPhoneNumbersList(currentMails);
    props.onChange(currentMails);
  };

  const handleOnChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const phoneNumber = evt.target.value.toLowerCase();
    setNewNumberText(phoneNumber);
    if (phoneNumber.length !== 10) {
      return setIsError(true);
    }
    setIsError(false);
  };

  const onAddEmailInArray = () => {
    if (newNumberText.length > 0 && !isError) {
      const newList = [...phoneNumbersList, newNumberText];
      setPhoneNumbersList(newList);
      onChange(newList);
      setNewNumberText("");
    }
  };

  return (
    <>
      <OutlinedTextField
        label={label}
        id="recipient_email"
        type="number"
        sx={{
          height: "auto",
          textAlign: "left",
        }}
        value={newNumberText}
        onKeyDown={(evt) => {
          if (evt.key === "Enter") {
            onAddEmailInArray();
          }
        }}
        onChange={(evt) => {
          if (evt.target.value.length > 10) return;
          if (evt.target.value.includes(",")) {
            onAddEmailInArray();
          } else {
            handleOnChange(evt);
          }
        }}
        onBlur={() => {
          onAddEmailInArray();
        }}
        placeholder={placeholder}
        InputProps={
          phoneNumbersList.length > 0
            ? {
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    wordBreak: "break-all",
                    height: "auto",
                    maxHeight: 500,
                    flexWrap: "wrap",
                    marginBlockStart: phoneNumbersList.length > 0 ? 0.25 : 0,
                  }}
                >
                  {phoneNumbersList.map((email, idx) => {
                    return (
                      <Chip
                        sx={{
                          marginBlock: phoneNumbersList.length > 0 ? 0.25 : 0,
                          marginInline: phoneNumbersList.length > 0 ? 0.25 : 0,
                        }}
                        size="small"
                        label={email}
                        onDelete={() => handleDelete(idx)}
                      />
                    );
                  })}
                </InputAdornment>
              ),
            }
            : {}
        }
        error={error || (isError ? "Invalid Phone Number. Length should be 10" : "")}
      />
    </>
  );
};
