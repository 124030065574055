import { RESERVATIONS_LIST_ROUTE } from "./Reservations.constants";
import { ReservationsTable } from "./ReservationsTable";


export const reservationsRoutes = [
    {
        path: RESERVATIONS_LIST_ROUTE,
        exact: true,
        element: <ReservationsTable />
    },
] 