import { LoadState } from "../../../constants/enum";
import { IPaymentReceivedState, IPaymentReceived } from "./payment-received.types";


export const defaultPaymentReceived: IPaymentReceived = {
    payment_received_uuid: null,
    order_type: "DINE_IN",
    order_number: "",
    invoice_number: null,
    total_tax: "",
    total_amount: "",
    order_status: "UNPAID",
    cash_received: "",
    cash_changed: "",
    order_details: [],
    payment_mode: "CASH",
}

export const defaultPaymentReceivedState: IPaymentReceivedState = {
    payments_received_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null
    },
    single_payment_received: {
        loading: LoadState.idle,
        data: defaultPaymentReceived,
        error: null
    }
}