import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../common";
import { axios_api } from "../../api/axios-api";
import { ITablesLayout } from "./tables.types";
import { defaultTablesLayout } from "./tables.state";

const GET_TABLE_LAYOUTS_BASE_URL = "/layout/get-layout"
const UPSERT_TABLE_LAYOUTS_BASE_URL = "/layout/upsert-layout"

export const fetchAllTablesLayoutAsync = createAsyncThunk<{ recordCount: number, data: ITablesLayout[] }, IQueryParams>(
    'tables/fetchAllTablesLayoutAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_TABLE_LAYOUTS_BASE_URL}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchSingleTableLayoutAsync = createAsyncThunk<ITablesLayout, string>(
    'tables/fetchSingleTableLayoutAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_TABLE_LAYOUTS_BASE_URL}?layout_uuid=${"eab941e5-2c8b-4ac8-bc1f-9fdb7c7601c2"}`)
        const data: ITablesLayout = response.data.data[0];

        if(data) {
            data.tables = data.tables.filter(table => table.id)
        }

        return data ? data : defaultTablesLayout
    },
)


export const upsertSingleTableLayoutAsync = createAsyncThunk<ITablesLayout, ITablesLayout>(
    'tables/upsertSingleTableLayoutAsync', async (payload: ITablesLayout, thunkAPI) => {

        const customizedTablesData = payload.tables.map((table) => {
            return {
                table_id: table.id,
                table_number: table.data.table_number,
                availability: table.data.availability,
                status: table.data.status,
                ...table,
            }
        })
        const response = await axios_api.post(UPSERT_TABLE_LAYOUTS_BASE_URL, {...payload, tables: customizedTablesData})
        // thunkAPI.dispatch(fetchSingleTableLayoutAsync({
        //     columns: [],
        //     page: 1,
        //     status: "-1",
        //     value: "",
        //     rowsPerPage: 10
        // }))
        return response.data
    },
)