import React from "react";
import { FormControl, Button } from "@mui/material";
import { useFormik } from "formik";
import { CustomTextFieldV2 } from "../../../Components/formComponents";
import { useAuthProvider } from "../../../providers/Authentication/AuthenticationProvider";
import { useAppDispatch } from "../../../redux";


export const SendOtpOnMail: React.FC<{
  onSuccess: (email: string) => void;
}> = ({ onSuccess }) => {
  const { sendForgetPasswordMailAsync } = useAuthProvider();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { values, errors, handleChange, handleSubmit, setFieldError } =
    useFormik({
      initialValues: {
        email: "",
      },
      validate: (values) => {
        const errors: any = {};
        if (!values.email) {
          errors.email = "*Email is required.";
        } else if (
          values.email !== "" &&
          !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            values.email,
          )
        ) {
          errors.email = "*Invalid email address.";
        }
        return errors;
      },
      onSubmit: (values) => {
        setLoading(true);
        sendForgetPasswordMailAsync(
          values.email,
          (message) => {
            if (message) {
              // dispatch(
              //   showMessage({
              //     type: "success",
              //     message: message,
              //     displayAs: "snackbar",
              //   }),
              // );
              onSuccess(values.email);
            }
            setLoading(false);
          },
          (errorMessage) => {
            setLoading(false);
            setFieldError("email", errorMessage);
          },
        );
      },
    });
  return (
    <form onSubmit={handleSubmit}>
      <FormControl sx={{ mt: 2 }} fullWidth error={errors.email ? true : false}>
        <CustomTextFieldV2
          label="Enter you Email"
          id="email"
          variant="outlined"
          value={values.email}
          fullWidth
          type="text"
          error={errors.email ? true : false}
          helperText={errors.email}
          onChange={handleChange}
        />
      </FormControl>

      <Button
        color="primary"
        variant="contained"
        size="large"
        fullWidth
        disabled={loading}
        type="submit"
        sx={{
          mt: 2,
          pt: "10px",
          pb: "10px",
        }}
      >
        Send OTP
      </Button>
    </form>
  );
};
