import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../api/axios-api"
import { IUpsertManualShift, IPunchInOut, ISingleUserPunch, ISingleUserShift } from "./shifts.types"
import { IQueryParams, getSearchQuery } from "../../common"
import { uploadSingleFileAsync } from "../../helpers/uploadFilesAsync"
import { getFileNameAndExtension } from "../../helpers/convertBase64ToFile"
import { displaySnackBarDialog } from "../common"
import { AxiosError } from "axios"



const GET_SHIFT_PUNCH_IN_OUT_BASE_URL = "/shift/get-shift"
const GET_USER_SHIFT_DETAILS_BASE_URL = "/shift/get-shift-break-in-out-details"
const UPSERT_RECEIPT_COMPOSER_BASE_URL = "/shift/upsert-shift"
const UPSERT_MANUAL_SHIFT_BASE_URL = "/shift/upsert-shift-manually"


export const fetchShiftPunchInOutAsync = createAsyncThunk<{ data: IPunchInOut[], recordCount: number }, IQueryParams>(
    'shifts/fetchShiftPunchInOutAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_SHIFT_PUNCH_IN_OUT_BASE_URL}${searchQuery}`)
        return response.data
    },
)

interface IFetchUserShiftDetails {
    user_uuid: string
    shift_code: string
}
export const fetchSingleUserShiftInfoAsync = createAsyncThunk<ISingleUserShift, IFetchUserShiftDetails>(
    'shifts/fetchSingleUserShiftInfoAsync', async ({ user_uuid, shift_code }: IFetchUserShiftDetails) => {
        const response = await axios_api.get(`${GET_USER_SHIFT_DETAILS_BASE_URL}?user_uuid=${user_uuid}&shift_code=${shift_code}`)
        return response.data.data[0]
    },
)


export const upsertShiftManuallyAsync = createAsyncThunk<IUpsertManualShift, IUpsertManualShift>(
    'shifts/upsertShiftManuallyAsync', async (payload: IUpsertManualShift, thunkAPI) => {
        const response = await axios_api.post(UPSERT_MANUAL_SHIFT_BASE_URL, payload);
        thunkAPI.dispatch(fetchShiftPunchInOutAsync({
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)


interface IUpsertPunchInOut extends IPunchInOut {
    file: File | null,
}

export const upsertSinglePunchInOutAsync = createAsyncThunk<IPunchInOut, IUpsertPunchInOut>(
    'shifts/upsertSinglePunchInOutAsync', async (payload: IUpsertPunchInOut, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const { file, create_ts, insert_ts, ...restPayload } = payload
            if (file) {
                const path = await uploadSingleFileAsync({
                    file,
                    moduleName: "SHIFT",
                    asPayload: {
                        login_pin: payload.login_pin,
                        punch_date: payload.punch_date,
                    }
                })
                restPayload.user_image = path
            }
            const response = await axios_api.post(UPSERT_RECEIPT_COMPOSER_BASE_URL, restPayload);
            console.log("response.data 1 ==> ", response.data)

            dispatch(fetchShiftPunchInOutAsync({
                page: 1,
                rowsPerPage: 10
            }))
            return fulfillWithValue(response.data)
        } catch (error) {
            if ((error as AxiosError).response?.status === 404) {
                dispatch(displaySnackBarDialog({
                    content: (error as any)?.response?.data?.message as string,
                    type: "error"
                }))
            }
            console.log("response. error 1 ==> ", error)
            return rejectWithValue((error as AxiosError).message)
        }
    },
)


export const updateSinglePunchInfoAsync = createAsyncThunk<ISingleUserPunch, ISingleUserPunch>(
    'shifts/updateSinglePunchInfoAsync', async (payload: ISingleUserPunch, thunkAPI) => {
        const response = await axios_api.post(UPSERT_RECEIPT_COMPOSER_BASE_URL, payload);
        return response.data
    },
)