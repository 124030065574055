import React from "react";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { Grid, TextField } from "@mui/material";
import { ICategory, upsertSingleCategoryAsync } from "../../../../redux/inventory";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../redux";
import { ControlledCustomSelect } from "../../../../Components/formComponents";


export interface IUpsertCategoryProps {
  open: boolean;
  category: ICategory;
  onClose: () => void;
}
export const UpsertCategoryDialog: React.FC<IUpsertCategoryProps> = (props) => {
  const { open, category, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...category },
    validate: (values) => {
      const errors: any = {};
      if (!values.category_name) {
        errors.category_name = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSingleCategoryAsync(values)
      ).finally(() => {
        setSaveLoading(false);
        onClose()
      });
    },
  });

  return (
    <Dialog
      open={open}
      title="Add/Edit Category"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
          disabled: saveLoading
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Category Name"
              id="category_name"
              value={values.category_name}
              onChange={handleChange}
              error={errors.category_name ? true : false}
              helperText={errors.category_name}
            />
          </Grid>

          {values.category_uuid && (
            <Grid item xs={12}>
              <ControlledCustomSelect
                label="Status"
                name="status"
                fullWidth
                value={values.status}
                onChange={handleChange}
                options={[
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                ]}
                displayEmpty
                placeholder="Select Status"
                error={errors.status}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </Dialog>
  );
};
