import React, { } from 'react'
import { Add, Delete } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { OutlinedTextField } from "../../../../Components/formComponents";
import { produce } from 'immer';
import { IInventory, IProductIngredient } from '../../../../redux/inventory';
import { InventoryAutoSearch } from '../../InventoryList/AutoSearch/InventoryAutoSearch';

export interface IProductIngredientsTableProps {
  data: IProductIngredient[];
  onChange: (data: IProductIngredient[]) => void;
}
export const ProductIngredientsTable: React.FC<IProductIngredientsTableProps> = ({
  data: productIngredients,
  onChange,

}) => {

  const handleInsertNewIngredient = (index: number) => {
    const newValues = produce(productIngredients, (draftItems) => {
      draftItems.splice(index + 1, 0, { inventory_uuid: "", ingredient_name: "", quantity: 0, unit: "" });
    });
    onChange(newValues);
  };

  const handleDeleteNewIngredient = (currentIndex: number) => () => {
    if (productIngredients.length > 1) {
      const newValues = produce(productIngredients, (draftItems) => {
        draftItems.splice(currentIndex, 1);
      });
      onChange(newValues);
    }
  };

  const handleIngredientChange = (key: string, index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = produce(productIngredients, (draftItems) => {
      if (["quantity"].includes(key)) {
        draftItems[index].quantity = Number(e.target.value);
      } else {
        draftItems[index][key as "ingredient_name"] = e.target.value;
      }
    });
    onChange(newValues);
  };

  const handleIngredientNameChange = (index: number) => (data: IInventory) => {
    const newValues = produce(productIngredients, (draftItems) => {
      draftItems[index].inventory_uuid = data.inventory_uuid || "";
      draftItems[index].ingredient_name = data.product_name;
      draftItems[index].unit = data.product_unit;
    });
    onChange(newValues);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table >
          <TableHead sx={{ background: (theme) => theme.palette.grey[800] }}>
            <TableRow>
              <TableCell sx={{ borderBottom: "none", width: 100 }} />
              <TableCell sx={{ borderBottom: "none" }}>
                <Typography variant="h5" textAlign="center" fontWeight={600}>
                  Ingredient Name
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none", width: 200 }}>
                <Typography variant="h5" textAlign="center" fontWeight={600}>
                  Quantity
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none", width: 100 }}>
                <Typography variant="h5" textAlign="center" fontWeight={600}>
                  Unit
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productIngredients.map((ingredient, rowIndex) => {
              return (
                <TableRow>
                  <TableCell sx={{ width: 100 }}>
                    <Box display={"flex"}>
                      <IconButton
                        color="error"
                        disabled={
                          productIngredients.length === 1
                        }
                        onClick={handleDeleteNewIngredient(rowIndex)}
                      >
                        <Delete />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleInsertNewIngredient(rowIndex)}
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </TableCell>

                  <TableCell >
                    <InventoryAutoSearch
                      name="product_category_name"
                      label="Ingredient Name"
                      value={{
                        inventory_uuid: ingredient.inventory_uuid,
                        product_name: ingredient.ingredient_name,
                      }}
                      onSelect={(newValue) => handleIngredientNameChange(rowIndex)(newValue)}
                    />
                  </TableCell>

                  <TableCell sx={{ width: 100 }}>
                    <OutlinedTextField
                      label="Quantity"
                      id="quantity"
                      placeholder="Enter quantity"
                      value={ingredient.quantity}
                      type="number"
                      onChange={(e) => handleIngredientChange("quantity", rowIndex)(e as any)}
                    />
                  </TableCell>
                  <TableCell sx={{ width: 100 }}>
                    <OutlinedTextField
                      label="Unit"
                      value={ingredient.unit}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};