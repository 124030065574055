import React from 'react'
import { List, ListItemButton, ListItemText, Divider, DialogContent, Box, Typography, ListItem, IconButton, Grid, DialogActions } from '@mui/material'
import { cancelUberEatsOrderAsync, IUberEatsOrder } from '../../../../../redux/uber-eats'
import { IOrderScreenType } from '../ViewOrderDialog.types'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ControlledCustomSelect, OutlinedTextField } from '../../../../../Components/formComponents';
import { IMenuOption } from '../../../../../Components/ContextMenu/interfaces/IContextMenuProps';
import { IElementType } from '../../../../../common';
import { capitalizeMWS } from '../../../../../helpers/capitalizeString';
import { CustomButton } from '../../../../../Components/CustomButton';
import { useAppDispatch } from '../../../../../redux';
import { useFormik } from 'formik';

export const UberEatsHelpList = ["Delay", "Cancel"]

export const cancelOrderType = ["ITEM_ISSUE", "KITCHENCLOSED", "CUSTOMER_CALLED_TO_CANCEL", "RESTAURANT_TOO_BUSY",
    "ORDER_VALIDATION", "STORE_CLOSED", "TECHNICAL_FAILURE", "POS_NOT_READY", "POS_OFFLINE", "CAPACITY",
    "ADDRESS", "SPECIAL_INSTRUCTIONS", "PRICING", "UNKNOWN", "OTHER"] as const;

export type ICancelOrderType = IElementType<typeof cancelOrderType>

interface ICancelOrderScreenProps {
    order_info: IUberEatsOrder,
    onBackIconClick: () => void
    onListClick: (type: IOrderScreenType) => void
    onClose: () => void
}

export const CancelOrderScreen: React.FC<ICancelOrderScreenProps> = ({
    order_info,
    onListClick,
    onBackIconClick,
    onClose
}) => {



    const dispatch = useAppDispatch();
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            type: "",
            client_error_code: "",
            item_metadata: {
                invalid_item: []
            },
            info: ""

        },
        validate: (values) => {
            const errors: any = {};
            if (!values.type) {
                errors.type = "*This field is required.";
            }
            if (!values.info) {
                errors.info = "*This field is required.";
            }
            return errors;
        },
        onSubmit: async (values) => {
            // setSaveLoading(true);
            dispatch(
                cancelUberEatsOrderAsync({
                    order_id: order_info.id,
                    ...values
                })
            ).finally(() => {
                // setSaveLoading(false);
                onClose()
            });
        },
    });

    return (
        <Box component="form" onSubmit={handleSubmit} >
            <Box sx={{
                minHeight: 70,
                width: '100%',
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
                background: "#292929",
                paddingInline: 2,
                paddingBlock: 2,
                borderRadius: 1,
                cursor: "pointer"
            }}
            >
                <IconButton sx={{ p: .75 }} onClick={onBackIconClick}>
                    <ArrowBackIcon sx={{ height: 26, width: 26 }} />
                </IconButton>

                <Typography variant='h2'>
                    Cancel
                </Typography>

            </Box >

            <DialogContent dividers sx={{ minHeight: 390, px: 8, py: 4, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ControlledCustomSelect
                            label="Issue"
                            name="type"
                            fullWidth
                            options={cancelOrderType.map((cancel_type, idx) => {
                                return { label: capitalizeMWS(cancel_type), value: cancel_type }
                            })}
                            placeholder="Select Unit"
                            value={values.type}
                            onChange={handleChange}
                            error={errors.type}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <OutlinedTextField
                            label="Description"
                            id="info"
                            multiline
                            minRows={5}
                            value={values.info}
                            onChange={handleChange}
                            error={errors.info}
                        />
                    </Grid>
                </Grid>
            </DialogContent>


            <DialogActions sx={{ minHeight: 60, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <CustomButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{
                        minWidth: 100,
                        paddingInline: "16px !important",
                        textTransform: "uppercase"
                    }}
                    onClick={onClose}
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        minWidth: 100,
                        paddingInline: "16px !important",
                        textTransform: "uppercase"
                    }}
                    type="submit"
                >
                    Confirm
                </CustomButton>
            </DialogActions>
        </Box>
    )
}
