import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { DashboardCards } from "./DashboardCards/DashboardCards";
import { TodaysUpscale } from "./TodaysUpscale/TodaysUpscale";
import { BarCharts } from "../../Components/Charts/BarChart";
import { CustomSearchField } from "../../Components/formComponents";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { RevenueAndFoodCostBarChart } from "./Charts/RevenueAndFoodCostBarChart";

export const Dashboard: React.FC = () => {
  return (
    <PageContainer title="Dashboard" description="Dashboard">
      <Box>
        <CustomSearchField />

        <Box mt={3.5}>
          <Typography variant="h2" fontWeight={"bold"}>
            Dashboard
          </Typography>
        </Box>

        <Grid container spacing={6} mt={2}>
          <Grid item xs={12} sm={12} md={12}>
            <DashboardCards />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TodaysUpscale />
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <RevenueAndFoodCostBarChart />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};
