import React from "react";
import { styled } from "@mui/material/styles";
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const MuiAccordion = styled((props: AccordionProps) => (
  <Accordion {...props} />
))(({ theme }) => ({
  borderRadius: '0px',
  // '&:not(:last-child)': {
  //     borderBottom: 'none', // Remove the bottom border for all but the last Accordion
  //   },
  '&:before': {
    display: 'none', // Remove the default "plus" icon
  },
  backgroundColor: "#272B2C",
  boxShadow: "none",
  padding: "8px 0px 8px 0px",
  borderBottom: "1px solid #a6a6a7",
}));



interface ICustomAccordionProps {
  expanded?: boolean;
  size?: "small" | "medium" | "large";
  title: string;
  children: React.ReactNode
  sx?: AccordionProps["sx"]
}

export const CustomAccordion: React.FC<ICustomAccordionProps> = ({
  expanded,
  size = "medium",
  title,
  children,
  sx
}) => {


  return (
    <MuiAccordion defaultExpanded={expanded} sx={sx}>
      <AccordionSummary
        aria-controls="panel2d-content"
        id="panel2d-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h3">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{
        paddingBlock: 2,
        borderTop: "2px solid #dadada"
      }}>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  )
}