import React, { } from "react";
import { Grid, } from "@mui/material";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { OutlinedTextField } from "../../../../Components/formComponents";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../redux";
import { ITaxInfo } from "../../../../redux/settings/tax-info/tax-info.types";
import { upsertNewTaxInfoAsync } from "../../../../redux/settings/tax-info/tax-info.actions";
import { defaultTaxInfo } from "../../../../redux/settings/tax-info/tax-info.state";
import { CompaniesDropDown } from "../../Company/components/CompaniesDropDown";


export interface IUpsertTaxInfoDialogProps {
  open: boolean;
  taxInfo?: ITaxInfo
  onClose: () => void;
}


export const UpsertTaxInfoDialog: React.FC<IUpsertTaxInfoDialogProps> = (
  props
) => {
  const { open, taxInfo, onClose } = props;
  const dispatch = useAppDispatch();



  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: taxInfo ? taxInfo : defaultTaxInfo,
    validate: (values) => {
      const errors: any = {};
      if (!values.tax_name) {
        errors.tax_name = "*This field is required.";
      }
      if (!values.company_uuid) {
        errors.company_uuid = "*This field is required.";
      }
      // if (!values.location_uuid) {
      //   errors.location_uuid = "*This field is required.";
      // }
      if (!values.tax_rate) {
        errors.tax_rate = "*This field is required.";
      }
      if (!values.tax_state) {
        errors.tax_state = "*This field is required.";
      }
      if (!values.tax_country) {
        errors.tax_country = "*This field is required.";
      }

      return errors;
    },
    onSubmit: async (values) => {
      dispatch(upsertNewTaxInfoAsync(values)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      })
    },
  });

  return (
    <Dialog
      open={open}
      title="Add/Edit Tax Information"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Tax Name"
              id="tax_name"
              value={values.tax_name}
              onChange={handleChange}
              error={errors.tax_name}
            />
          </Grid>

          <Grid item xs={12}>
            <CompaniesDropDown
              value={values.company_uuid}
              onChange={(newValue) => setFieldValue("company_uuid", newValue)}
              error={errors.company_uuid}
            />
          </Grid>

          <Grid item xs={12}>
            <OutlinedTextField
              label="Tax Rate"
              id="tax_rate"
              value={values.tax_rate}
              onChange={handleChange}
              error={errors.tax_rate}
            />
          </Grid>

          <Grid item xs={12}>
            <OutlinedTextField
              label="Tax Country"
              id="tax_country"
              value={values.tax_country}
              onChange={handleChange}
              error={errors.tax_country}
            />
          </Grid>

          <Grid item xs={12}>
            <OutlinedTextField
              label="Tax State"
              id="tax_state"
              value={values.tax_state}
              onChange={handleChange}
              error={errors.tax_state}
            />
          </Grid>






        </Grid>
      </form>
    </Dialog>
  );
};
