import { LoadState } from "../../../constants/enum";
import { IOutletRegister, IOutletRegisterState, IRegisterStaff } from "./outlet-registers.types";


export const defaultRegisterStaff: IRegisterStaff = {
    register_staff_uuid: null,
    register_uuid: "",
    user_uuid: "",
    user_name: "",
    user_pin: "",
    status: "ACTIVE"
}

export const defaultOutletRegister: IOutletRegister = {
    register_uuid: null,
    register_name: null,
    company_uuid: null,
    outlet_uuid: null,
    register_type: null,
    user_assigned: null,
    status: "ACTIVE"
}


export const defaultOutletRegisterState: IOutletRegisterState = {
    registers_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_register: {
        loading: LoadState.idle,
        data: defaultOutletRegister,
        error: null,
    },



    single_register_staff_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_staff: {
        loading: LoadState.idle,
        data: defaultRegisterStaff,
        error: null,
    }
}