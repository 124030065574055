import { ShapeComponents, type ShapeComponentProps } from './types';

function Shape({ type, width, height, ...svgAttributes }: ShapeComponentProps) {
  const ShapeComponent = ShapeComponents[type];

  if (!ShapeComponent || !width || !height) {
    return null;
  }

  const strokeWidth = svgAttributes.strokeWidth
    ? +svgAttributes.strokeWidth
    : 0;

  // we subtract the strokeWidth to make sure the shape is not cut off
  // this is done because svg doesn't support stroke inset (https://stackoverflow.com/questions/7241393/can-you-control-how-an-svgs-stroke-width-is-drawn)
  const innerWidth = width - 2 * strokeWidth;
  const innerHeight = height - 2 * strokeWidth;

  if (type === "circle" || type === "square") {

    let nodeSize = 0;
    if (width > height) {
      nodeSize = width
    } else if (height > width) {
      nodeSize = height
    } else {
      nodeSize = height
    }

    const innerSize = nodeSize - 2 * strokeWidth;

    return (
      <svg width={nodeSize} height={nodeSize} className='shape-svg'>
        {/* this offsets the shape by the strokeWidth so that we have enough space for the stroke */}
        <g
          transform={`translate(${svgAttributes.strokeWidth ?? 0}, ${svgAttributes.strokeWidth ?? 0
            })`}
        >
          <ShapeComponent
            width={innerSize}
            height={innerSize}
            {...svgAttributes}
          />
        </g>
      </svg>
    )
  }

  if (type === "line") {
    return <ShapeComponent
      width={innerWidth}
      height={innerHeight}
      {...svgAttributes}
    />
  }
  
  return (
    <svg width={width} height={height} className='shape-svg'>
      {/* this offsets the shape by the strokeWidth so that we have enough space for the stroke */}
      <g
        transform={`translate(${svgAttributes.strokeWidth ?? 0}, ${svgAttributes.strokeWidth ?? 0
          })`}
      >
        <ShapeComponent
          width={innerWidth}
          height={innerHeight}
          {...svgAttributes}
        />
      </g>
    </svg>
  );
}

export default Shape;
