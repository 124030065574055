/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { StandardCard } from "../../../Components/Card";
import { CustomCheckBoxWithLabel, OutlinedTextField } from "../../../Components/formComponents";
import { OutlinedTimePicker } from "../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { ProfileIntroCard } from "../../../Components/Profile/ProfileIntoCard";
import { useNavigate } from "react-router-dom";
import { fetchSingleCompanyAsync, upsertCompanyInfoAsync } from "../../../redux/settings/company";

export const ManageSingleCompany = () => {
  // const { uuid: companyUUID } = useParams<{ uuid?: string }>();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { data: companyInfo } = useAppSelector((storeState: IStoreState) => storeState.settings.companies.single_company);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

  const {
    values,
    errors,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...companyInfo },
    validate: (values) => {
      const errors: any = {};
      if (!values.company_name) {
        errors.company_name = "*First Name is required";
      }
      if (!values.company_opening_timing) {
        errors.company_opening_timing = "*This Field is required.";
      }
      if (!values.company_closing_timing) {
        errors.company_closing_timing = "*This Field is required.";
      }
      if (values.company_phone_number && values.company_phone_number.toString().length !== 10) {
        errors.company_phone_number = "*Phone number must be 10 digits";
      }
      if (!values.company_email) {
        errors.company_email = "*Email is required.";
      } else if (
        values.company_email !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.company_email,
        )
      ) {
        errors.company_email = "*Invalid email address.";
      }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertCompanyInfoAsync({ ...values, file: fileToUpload }),
      ).then((res) => {
        // if (res.meta.requestStatus === "fulfilled") {
        //   navigate(COMPANY_ROUTE)
        // }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });


  React.useEffect(() => {
    dispatch(fetchSingleCompanyAsync());
  }, []);

  React.useEffect(() => {
    setValues(companyInfo);
  }, [companyInfo]);

  // React.useEffect(() => {
  //   return () => {
  //     dispatch(clearSingleCom())
  //   }
  // }, []);


  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant='h2'>Company</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <StandardCard
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <ProfileIntroCard
                title="Change Logo"
                fileUrl={values.company_logo}
                subTitle="Change company logo from here"
                onChange={(file) => setFileToUpload(file)}
                onFileReset={() => setFieldValue("company_logo", null)}

              />
            </StandardCard>
          </Grid>
          <Grid item xs={12} md={9}>
            <StandardCard
              heading="Company Information"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Company Name"
                    id="company_name"
                    value={values.company_name}
                    onChange={handleChange}
                    error={errors.company_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OutlinedTextField
                    label="Company Email"
                    id="company_email"
                    value={values.company_email}
                    onChange={handleChange}
                    error={errors.company_email}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Company Phone Number"
                    id="company_phone_number"
                    type="number"
                    value={values.company_phone_number}
                    onChange={handleChange}
                    error={errors.company_phone_number}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <OutlinedTimePicker
                    label="Company Opening Time"
                    fullWidth
                    format="hh:mm A"
                    value={moment(values.company_opening_timing)}
                    onChange={(newValue) => {
                      setFieldValue("company_opening_timing", newValue.toISOString());
                    }}
                    error={errors.company_opening_timing}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTimePicker
                    label="Company Closing Time"
                    fullWidth
                    format="hh:mm A"
                    value={moment(values.company_closing_timing)}
                    onChange={(newValue) => {
                      setFieldValue("company_closing_timing", newValue.toISOString());
                    }}
                    error={errors.company_closing_timing}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Company GST Number"
                    id="company_gsthst_number"
                    value={values.company_gsthst_number}
                    onChange={handleChange}
                    error={errors.company_gsthst_number}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomCheckBoxWithLabel
                    checked={values.company_locations_y_n}
                    label="Multi Locations"
                    name="company_locations_y_n"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <OutlinedTextField
                    label="Company Address"
                    id="company_address"
                    multiline
                    minRows={3}
                    value={values.company_address}
                    onChange={handleChange}
                    error={errors.company_address}
                  />
                </Grid>
              </Grid>
            </StandardCard>
          </Grid>
        </Grid>


        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "10%" }}
            disabled={saveLoading}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  );
};
