import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { IAuthenticationState, defaultUserAuthInfo } from "./AuthenticationProvider.types";
import { axios_api } from "../../api/axios-api";


const AuthContext = React.createContext<IAuthenticationState>({
  authInfo: {
    isOTPPreview: false,
    token: "",
    name: "",
    user: defaultUserAuthInfo,
  },
  isLoggedIn: false,
  loginAsync: () => { },
  verifyOTPAsync: () => { },
  logoutAsync: () => { },
  // addUserName: () => { },
  sendForgetPasswordMailAsync: () => { },
  resetPasswordAsync: () => { },
});

export const useAuthProvider = () => React.useContext(AuthContext);

export const AuthenticationProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [userInfo, setUserInfo] = React.useState<IAuthenticationState["authInfo"]>({
    isOTPPreview: false,
    token: null,
    name: "",
    user: defaultUserAuthInfo,
  });
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const data = JSON.parse(auth);
      const date1 = new Date(data.date);
      const date2 = new Date();
      const diff = date2.getHours() - date1.getHours();
      if (diff <= 1) {
        setUserInfo(data);
      }
    }
    setLoading(false);
  }, []);

  const handleLogin = async (
    email: string,
    password: string,
    onSuccess: (isOTPPreview: boolean) => void,
    onError: (message: string) => void,
  ) => {
    try {
      const response = await axios_api.post(`/authentication/login`, { email, password })
      if (response.data.data.isOTPPreview) {
        setUserInfo({
          ...userInfo,
          user: {
            ...userInfo.user,
            email: email,
          },
        });
      } else {
        const userData: IAuthenticationState["authInfo"] = response.data.data;
        userData.name = "";
        if (userData.user.first_name) {
          userData.name = userData.user.first_name;
        }
        if (userData.user.last_name) {
          userData.name += " " + userData.user.last_name;
        }
        setUserInfo(userData);
        localStorage.setItem(
          "auth",
          JSON.stringify({ ...userData, date: new Date() }),
        );
      }
      onSuccess(response.data.data.isOTPPreview);
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };
  const handleVerfiyOTP = () => {

  }


  const handleLogout = () => {
    localStorage.clear();
    setUserInfo({
      isOTPPreview: false,
      token: null,
      name: "",
      user: defaultUserAuthInfo,
    });
    // window.open("/auth/login", "_self");
  };

  const handleForgetPassword = (
    email: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
  ) => {
    axios_api.post(`/authentication/forget-password`, {
      email: email,
      otp: 0,
      user_password: "",
      action: "SEND",
    })
      .then((response) => {
        onSuccess(response.data.message);
      })
      .catch((error) => {
        onError(error.response.data.message);
      });
  };

  const resetPasswordAsync = (
    email: string,
    password: string,
    otp: number,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
  ) => {
    axios_api.post(`/authentication/forget-password`, {
      email: email,
      otp: otp,
      user_password: password,
      action: "",
    })
      .then((response) => {
        onSuccess(response.data.message);
      })
      .catch((error) => {
        onError(error.response.data.message);
      });
  };

  // const addUserName = (name: string) => {
  //   setUserInfo({ ...userInfo, name: name });
  // };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <AuthContext.Provider
      value={{
        authInfo: userInfo,
        isLoggedIn: userInfo.token ? true : false,
        loginAsync: handleLogin,
        verifyOTPAsync: handleVerfiyOTP,
        logoutAsync: handleLogout,
        // addUserName: addUserName,
        sendForgetPasswordMailAsync: handleForgetPassword,
        resetPasswordAsync: resetPasswordAsync,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
