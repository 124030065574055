import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { StandardCard } from "../../../Components/Card";
import { ControlledCustomSelect, CustomCheckBoxWithLabel, OutlinedTextField } from "../../../Components/formComponents";
import { CustomDatePicker, OutlinedTimePicker } from "../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { ProfileIntroCard } from "../../../Components/Profile/ProfileIntoCard";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSingleCompanyAsync, upsertCompanyInfoAsync } from "../../../redux/settings/company";
import { clearCouponsStateSync, fetchSingleCouponAsync, upsertSingleCouponAsync } from "../../../redux/marketing/coupon-codes";
import { MARKETING_COUPON_CODES_ROUTE } from "../Marketing.constants";
import { TabsList } from "../../../Components/Tab/Tab";
import { TabPanel } from "../../../Components/Tab/TabPanel";
import { CustomButton } from "../../../Components/CustomButton";
import { generateCouponCodeAsync } from "./CouponCodes.helpers";

export const ManageSingleCouponCode = () => {
    const { uuid: couponUUID } = useParams<{ uuid?: string }>();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { data: singleCouponInfo } = useAppSelector((storeState: IStoreState) => storeState.marketing.coupons.single_coupon);

    const [saveLoading, setSaveLoading] = React.useState(false);
    const [couponCodeLoading, setCouponCodeLoading] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(0);

    const {
        values,
        errors,
        setFieldValue,
        setValues,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: { ...singleCouponInfo },
        validate: (values) => {
            const errors: any = {};
            if (!values.coupon_code) {
                errors.coupon_code = "*This Field is required.";
            }
            if (!values.discount_type) {
                errors.discount_type = "*This Field is required.";
            }
            if (!values.coupon_amount) {
                errors.coupon_amount = "*This Field is required.";
            }
            if (!values.coupon_expiry_date) {
                errors.coupon_expiry_date = "*This Field is required.";
            }
            return errors;
        },
        onSubmit: (values) => {
            setSaveLoading(true);
            dispatch(
                upsertSingleCouponAsync(values),
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    navigate(MARKETING_COUPON_CODES_ROUTE)
                }
            }).finally(() => {
                setSaveLoading(false);
            });
        },
    });


    React.useEffect(() => {
        if(!couponUUID) return
        dispatch(fetchSingleCouponAsync(couponUUID));
    }, [couponUUID]);

    React.useEffect(() => {
        setValues(singleCouponInfo);
    }, [singleCouponInfo]);

    React.useEffect(() => {
        return () => {
            dispatch(clearCouponsStateSync())
        }
    }, []);


    const generateCouponCode = () => {
        setCouponCodeLoading(true)
        setTimeout(() => {
            setFieldValue("coupon_code", generateCouponCodeAsync())
            setCouponCodeLoading(false)
        }, 500)
        
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Typography variant='h2'>Manage Coupon </Typography>
                <StandardCard
                    heading="Coupon Information"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <OutlinedTextField
                                label="Coupon Code"
                                id="coupon_code"
                                value={values.coupon_code}
                                onChange={handleChange}
                                error={errors.coupon_code}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>

                            <CustomButton
                                variant="contained"
                                onClick={generateCouponCode}
                                endIcon={ couponCodeLoading && <CircularProgress size={16} sx={{color: "#212121"}} />}>
                                Generate Coupon Code
                            </CustomButton>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <OutlinedTextField
                                label="Description"
                                id="coupon_description"
                                placeholder="description (optional)"
                                multiline
                                minRows={4}
                                value={values.coupon_description}
                                onChange={handleChange}
                                error={errors.coupon_description}
                            />
                        </Grid>


                    </Grid>
                </StandardCard>

                <StandardCard
                    heading="Other Information"
                    sx={{
                        mt: 2
                    }}
                >
                    <TabsList
                        selectedTab={selectedTab}
                        tabData={[
                            { tabLabel: "General", tabLink: "" },
                            { tabLabel: "Usage Restriction", tabLink: "" },
                            { tabLabel: "Usage Limit", tabLink: "" },
                        ]}
                        onChange={(ele, value) => setSelectedTab(value)}
                    />
                    <TabPanel value={selectedTab} index={0}>
                        <Grid container spacing={2} direction="column">
                            <Grid item xs={12} md={3}>
                                <ControlledCustomSelect
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    label="Discount Type"
                                    value={values.discount_type}
                                    name="discount_type"
                                    onChange={handleChange}
                                    options={[
                                        { label: "Fixed Price Discount", value: "FIXED_PRICE_DISCOUNT" },
                                        { label: "Fixed Cart Discount", value: "FIXED_CART_DISCOUNT" },
                                        { label: "Fixed Product Discount", value: "FIXED_PRODUCT_DISCOUNT" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <OutlinedTextField
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    label="Coupon Amount"
                                    id="coupon_amount"
                                    type="number"
                                    value={values.coupon_amount}
                                    onChange={handleChange}
                                    error={errors.coupon_amount}
                                />
                            </Grid>
                           
                            <Grid item xs={12} md={3}>
                                <CustomCheckBoxWithLabel
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    checked={values.allow_free_shipping}
                                    label="Allow Free Shipping"
                                    name="allow_free_shipping"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomDatePicker
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    label="Coupon Expiry Date"
                                    fieldName="coupon_expiry_date"
                                    value={values.coupon_expiry_date}
                                    onChange={(newValue, formattedDate) => {
                                        setFieldValue("coupon_expiry_date", formattedDate);
                                    }}
                                    error={errors.coupon_expiry_date}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <Grid container spacing={2} direction="column">
                            <Grid item xs={12} md={3}>
                                <OutlinedTextField
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    label="Minimum Spend"
                                    id="minimum_spend"
                                    value={values.minimum_spend}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <OutlinedTextField
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    label="Maximum Spend"
                                    id="maximum_spend"
                                    value={values.maximum_spend}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomCheckBoxWithLabel
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    checked={values.individual_use_only}
                                    label="Individual Use Only"
                                    name="individual_use_only"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomCheckBoxWithLabel
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    checked={values.exclude_sale_items}
                                    label="Exclude Sale Items"
                                    name="exclude_sale_items"
                                    onChange={handleChange}
                                />
                            </Grid>

                        </Grid>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <Grid container spacing={2} direction="column">
                            <Grid item xs={12} md={3}>
                                <OutlinedTextField
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    label="Usage Limit Per Coupon"
                                    id="usage_limit_per_coupon"
                                    type="number"
                                    value={values.usage_limit_per_coupon}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <OutlinedTextField
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    label="Limit Usage To X Items"
                                    id="limit_usage_to_x_items"
                                    type="number"
                                    value={values.limit_usage_to_x_items}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <OutlinedTextField
                                    sx={{
                                        maxWidth: 220
                                    }}
                                    label="Coupon Amount"
                                    id="Usage Limit Per User"
                                    type="number"
                                    value={values.usage_limit_per_user}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </StandardCard>

                <Box sx={{ mt: 4 }}>
                    <Button
                        sx={{ width: "10%" }}
                        disabled={saveLoading}
                        variant="contained"
                        type="submit"
                    >
                        Save
                    </Button>
                </Box>
            </form>
        </>
    );
};
