import { ACCOUNTING_BANK_INFO_ROUTE, ACCOUNTING_CHARTS_OF_ACCOUNT_ROUTE, ACCOUNTING_PAYMENT_PAID_ROUTE, ACCOUNTING_PAYMENT_RECEIVED_ROUTE } from "./Accounting.constants";
import { AccountingTabsPanel } from "./AccountingTabsPanel";

export const accountingRoutes = [

    // ##################### Payment Received Route ############################
    {
        path: `${ACCOUNTING_PAYMENT_RECEIVED_ROUTE}`,
        exact: true,
        element: <AccountingTabsPanel activeTab={0} />
    },
    // {
    //     path: `${PRODUCTS_ROUTE}/manage`,
    //     exact: true,
    //     element: <ManageSingleProduct />
    // },
    // {
    //     path: `${PRODUCTS_ROUTE}/manage/:uuid`,
    //     exact: true,
    //     element: <ManageSingleProduct />
    // },
    // ##################### Categories Route ############################
    {
        path: ACCOUNTING_PAYMENT_PAID_ROUTE,
        exact: true,
        element: <AccountingTabsPanel activeTab={1} />
    },
    // ##################### Customization Route ############################
    {
        path: `${ACCOUNTING_BANK_INFO_ROUTE}`,
        exact: true,
        element:<AccountingTabsPanel activeTab={2} />
    },
    // ##################### Inventory List Route ############################
    {
        path: `${ACCOUNTING_CHARTS_OF_ACCOUNT_ROUTE}`,
        exact: true,
        element: <AccountingTabsPanel activeTab={3} />
    },


] 