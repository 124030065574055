import { Table, TableBody, TableRow, TableCell, Typography, Box, DialogActions, DialogContent, IconButton, Tooltip } from "@mui/material"
import { RUPEE_SYMBOL } from "../../../../../constants/constants"
import { IUberEatsOrder } from "../../../../../redux/uber-eats"
import { UberOrderItemCard } from "../UberOrderItemCard"
import { Help, Print } from "@mui/icons-material"
import { CustomButton } from "../../../../../Components/CustomButton"
import { IOrderScreenType } from "../ViewOrderDialog.types"

interface IOrderDetailsScreenProps {
    order_info: IUberEatsOrder,
    onHelpIconClick: () => void
}

export const OrderDetailsScreen: React.FC<IOrderDetailsScreenProps> = ({
    order_info,
    onHelpIconClick,
}) => {

    return (
        <>
            <Box sx={{
                minHeight: 70,
                width: '100%',
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                background: "#292929",
                paddingInline: 2,
                paddingBlock: 2,
                borderRadius: 1,
                cursor: "pointer"
            }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                }} >
                    <Typography variant='h3'>Michelle T.</Typography>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1
                    }} >
                        <Typography variant='h5'>3WEWSF3</Typography>
                        {/* <Chip  label="EAT IN" size='small' sx={{padding: "4px 8px", fontWeight: "700", borderRadius: 1}} /> */}
                    </Box>

                </Box>

                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1
                }} >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                    }} >
                        <Typography variant='h5'>11:45am</Typography>
                        <Typography variant='body1'>Due in 15 mins</Typography>
                    </Box>
                    <CustomButton
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{
                            minWidth: 100,
                            paddingInline: "16px !important"
                        }}

                        startIcon={<Help />}
                        onClick={onHelpIconClick}
                    >
                        Help
                    </CustomButton>
                </Box>
            </Box >

            <DialogContent dividers sx={{ minHeight: 390}}>

                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell width={80}>
                                <Typography variant='h5'>1X</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant='h5'>Houmous (Large)</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant='h5'> {`${RUPEE_SYMBOL} 50`}</Typography>
                            </TableCell>
                        </TableRow>
                        <UberOrderItemCard />
                        <TableRow>
                            <TableCell sx={{ borderBottom: "none" }} />
                            <TableCell sx={{ borderBottom: "none" }} />
                            <TableCell align="right" sx={{ borderBottom: "none" }}>
                                <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 1 }}>
                                    <Typography variant='body1'>Sub Total</Typography>
                                    <Typography variant='body1'> {`${RUPEE_SYMBOL} 50`}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 2 }}>
                                    <Typography variant='body1'>Tax</Typography>
                                    <Typography variant='body1'> {`${RUPEE_SYMBOL} 10`}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 2 }}>
                                    <Typography variant='h5'>Total</Typography>
                                    <Typography variant='h5'> {`${RUPEE_SYMBOL} 110`}</Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>


            <DialogActions sx={{ minHeight: 60, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Tooltip title="Print">
                    <IconButton>
                        <Print />
                    </IconButton>
                </Tooltip>
                <CustomButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{
                        minWidth: 100,
                        paddingInline: "16px !important"
                    }}
                >
                    Ready For Pickup
                </CustomButton>
                <CustomButton
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        minWidth: 100,
                        paddingInline: "16px !important"
                    }}
                >
                    Confirm
                </CustomButton>
            </DialogActions>
        </>


    )
}