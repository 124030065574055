import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthProvider } from "../providers";

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const { isLoggedIn, authInfo } = useAuthProvider();
  const location = useLocation();

  if (isLoggedIn && authInfo.token) {
    return <>{props.children}</>;
  }
  return (
    <Navigate to="/auth/login" replace state={{ path: location.pathname }} />
  );
};
