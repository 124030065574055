import { LoadState } from "../../../constants/enum";
import { ICompany, ICompanyState } from "./company.types";


export const defaultCompany: ICompany = {
    company_uuid: null,
    company_name: "",
    company_logo: null,
    company_address: "",
    company_phone_number: "",
    company_email: "",
    company_locations_y_n: false,
    company_gsthst_number: "",
    company_opening_timing: "",
    company_closing_timing: "",
    status: "ACTIVE"
}


export const defaultCompanyState: ICompanyState = {
    company_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_company: {
        loading: LoadState.idle,
        data: defaultCompany,
        error: null,
    }
}