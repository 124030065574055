import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { ICoupon } from "./coupon-codes.types"

const GET_MARKETING_COUPONS_BASE_URL = "/marketing/get-coupon"
const UPSERT_MARKETING_COUPONS_BASE_URL = "/marketing/upsert-coupon"


export const fetchAllCouponsListAsync = createAsyncThunk<{ data: ICoupon[], count: number }, IQueryParams>(
    'coupons/fetchAllCouponsListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_MARKETING_COUPONS_BASE_URL}${searchQuery}`)
        return response.data as { data: ICoupon[], count: number }
    },
)

export const fetchSingleCouponAsync = createAsyncThunk<ICoupon, string>(
    'coupons/fetchSingleCouponAsync', async (uuid: string) => {
        const response = await axios_api.get(`${GET_MARKETING_COUPONS_BASE_URL}?coupon_uuid=${uuid}`)
        return response.data.data[0]
    },
)


export const upsertSingleCouponAsync = createAsyncThunk<ICoupon, ICoupon>(
    'coupons/upsertSingleCouponAsync', async (payload: ICoupon, thunkAPI) => {
        const response = await axios_api.post(UPSERT_MARKETING_COUPONS_BASE_URL, payload)
        return response.data
    },
)


