import { GET_ORDERS_BASE_URL, axios_api } from "../../../../../api";
import { GET_OUTLET_BASE_URL } from "../../../../../redux/settings/outlets";

export const fetchColumnNamesForReceipt = (key: "OUTLET" | "ORDER") => new Promise<string[]>((resolve, reject) => {
    axios_api.get(`${key === "OUTLET" ? GET_OUTLET_BASE_URL : GET_ORDERS_BASE_URL}`).then((response) => {
        const data = response.data.data[0]
        const { insert_ts, create_ts, ...resColumns } = data
        resolve(Object.keys(resColumns).map((column) => column))
    })
})

