

export const generateCouponCodeAsync = (): string => {
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
    var result = '';
    for(var i=0; i<10; i++){
      var x = Math.floor(Math.random() * chars.length);
      result += chars[x];
    }
    return result;
}