import { Autocomplete, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { debounce } from "lodash";
import { axios_api } from "../../../../api/axios-api";
import { OutlinedTextField } from "../../../../Components/formComponents";
import { ISelectOption } from "../../../../constants/types";
import { GET_OUTLET_BASE_URL } from "../../../../redux/settings/outlets";

export interface IOutletsAutoSearchProps {
  value: any;
  onSelect: (value: ISelectOption) => void;
  error?: string;
}


export const OutletsAutoSearch: React.FC<IOutletsAutoSearchProps> = (
  props,
) => {
  const { value, onSelect } = props;
  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<ISelectOption[]>([]);
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    fetchSuggestion(value);
  }, []);

  const fetchSuggestion = debounce(async (value) => {
    setLoading(true);
    try {
      const res = await axios_api.get(`${GET_OUTLET_BASE_URL}`);
      const data: any[] = res.data.data;
      const finalData: ISelectOption[] = [];
      for (const outlet of data) {
        finalData.push({
          label: outlet.outlet_name,
          value: outlet.outlet_uuid,
        });
      }
      setSearchResults(finalData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setSearchText(value);
  };
  const getOptionLabel = (option: string | ISelectOption) => {
    if (typeof option === "string") {
      return option;
    }
    return option.label;
  };

  React.useEffect(() => {
    if (value) {
      setSearchText(value);
      fetchSuggestion(value);
    } else {
      setSearchText("");
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        options={searchResults.map((result) => ({
          label: result.label,
          value: result.value,
        }))}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },
        }}
        value={searchResults.find((option) => option.value === value) || null}
        defaultValue={value}
        getOptionLabel={getOptionLabel}
        onChange={(e, newValue) => {
          if (newValue && typeof newValue !== "string") {
            onSelect(newValue);
          } else {
            onSelect({ label: "", value: "" });
          }
        }}
        renderInput={(params) => (
          <OutlinedTextField
            {...params}
            label="Outlet"
            fullWidth
            value={searchText}
            onChange={handleChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
