/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import { StandardCard } from "../../../../Components/Card";
import { OutlinedTextField } from "../../../../Components/formComponents";
import { useNavigate, useParams } from "react-router-dom";
import { clearSingleRegisterStaffSync, fetchSingleOutletRegisterAsync, upsertSingleStaffMemberForRegisterAsync } from "../../../../redux/settings/outlet-registers";
import { UsersDropDown } from "../../UserProfile/components/UsersDropDown";
import { COMPANY_OUTLETS_ROUTE } from "../CompanyOutlets.constants";
import { RegistersDropDown } from "../components/RegistersDropDown";

export const ManageSingleRegisterStaff = () => {
  const { uuid: outlet_uuid, register_uuid: registerUUID, register_staff_uuid: staffUUID } = useParams<{ uuid?: string, register_uuid?: string, register_staff_uuid?: string }>();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { data: singleStaffInfo } = useAppSelector((storeState: IStoreState) => storeState.settings.registers.single_staff);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const {
    values,
    errors,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      ...singleStaffInfo,
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.register_uuid) {
        errors.register_uuid = "*First Name is required";
      }
      if (!values.user_uuid) {
        errors.user_uuid = "*This Field is required.";
      }
      if (!values.user_pin) {
        errors.user_pin = "*This Field is required.";
      }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSingleStaffMemberForRegisterAsync(values),
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(`${COMPANY_OUTLETS_ROUTE}/${outlet_uuid}/registers/manage/${registerUUID}`)
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });


  React.useEffect(() => {
    if (!staffUUID) return;
    dispatch(fetchSingleOutletRegisterAsync(staffUUID));
  }, [staffUUID]);

  React.useEffect(() => {
    setValues({
      ...singleStaffInfo,
      register_uuid: singleStaffInfo.register_uuid ? singleStaffInfo.register_uuid : registerUUID ? registerUUID : ""

    });
  }, [singleStaffInfo]);

  React.useEffect(() => {
    return () => {
      dispatch(clearSingleRegisterStaffSync())
    }
  }, []);


  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant='h2'>Manage Register Staff</Typography>
        <StandardCard
          heading="Staff Information"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <UsersDropDown
                value={values.user_uuid}
                onChange={(newValue) => setFieldValue("user_uuid", newValue)}
                error={errors.user_uuid}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <OutlinedTextField
                label="User Pin"
                id="user_pin"
                value={values.user_pin}
                onChange={handleChange}
                error={errors.user_pin}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <RegistersDropDown
                disabled
                outletUUID={outlet_uuid}
                value={values.register_uuid}
                onChange={handleChange}
                error={errors.register_uuid}
              />
            </Grid>

          </Grid>
        </StandardCard>


        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "10%" }}
            disabled={saveLoading}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  );
};
