import React from "react";
import { CustomChip } from "./CustomChip";
import { IStandardChipProps } from "./interfaces/interface";
import { Box, Divider, Typography } from "@mui/material";

export const TablesReserveChip: React.FC<IStandardChipProps> = (props) => {
  const { label, classes  } = props;
  const capitalizeLabel = label.toUpperCase();
  switch (capitalizeLabel) {
    case "RESERVED":
      return <CustomChip className={classes} size="small" label={label} color="error" />;
    case "FREE":
      return <CustomChip className={classes} size="small" label={label} color="secondary" />;
    case "CHECKED-IN":
      return <CustomChip className={classes} size="small" label={label} color="success" />;
    default:
      return <CustomChip className={classes} size="small" label={label} color="info" />;
  }
};

export const TableReserveChipWithBar: React.FC<{ label: string }> = (props) => {
  const { label } = props;
  const capitalizeLabel = label.toUpperCase();
  switch (capitalizeLabel) {
    case "RESERVED":
      return (
        <Box display="flex" alignItems="center">
          <TablesReserveChip label={label} />
          <Divider orientation="vertical" flexItem />
        </Box>
      );
    default:
      return <CustomChip label={`${label}`} color="info" />;
  }
};
