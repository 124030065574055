import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  THEME_COLOR,
  NAVBAR_BG,
  SIDEBAR_BG,
  DIRECTION,
  DARK_THEME,
} from "../../constants/constants";
import { defaultCustomizerState } from "./customizer.state";
import { ICustomizerState } from "./customizer.types";



const customizerSlice = createSlice({
  initialState: defaultCustomizerState,
  name: "customizer",
  reducers: {
      changeCustomizerThemeSync: (state, action) => {
        state.activeTheme = action.payload
      },
      changeCustomizerDarkModeSync: (state, action) => {
        state.activeMode = action.payload
      },
      changeCustomizerNavbarBgSync: (state, action) => {
        state.activeNavbarBg = action.payload
      },
      changeCustomizerSidebarSync: (state, action) => {
        state.activeSidebarBg = action.payload
      },
      changeCustomizerDirSync: (state, action) => {
        state.activeDir = action.payload
      },
  },
});

export const customizerReducer = customizerSlice.reducer;
export const { 
  changeCustomizerDarkModeSync,
  changeCustomizerNavbarBgSync,
  changeCustomizerDirSync,
  changeCustomizerSidebarSync,
  changeCustomizerThemeSync
 } = customizerSlice.actions;