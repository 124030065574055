import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { ICreateUser, IUserProfile } from "./user-profile.types"
import { IQueryParams, getSearchQuery } from "../../../common"
import { uploadSingleFileAsync } from "../../../helpers/uploadFilesAsync"

export const GET_USER_PROFILE_BASE_URL = "/user/get-user"
const UPSERT_USER_PROFILE_BASE_URL = "/user/upsert-profile"
const UPSERT_NEW_USER_BASE_URL = "/user/upsert-user"


export const fetchAllUsersProfileAsync = createAsyncThunk<{ data: IUserProfile[], count: number }, IQueryParams>(
    'user-profile/fetchAllUsersProfileAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_USER_PROFILE_BASE_URL}${searchQuery}`)
        return response.data as { data: IUserProfile[], count: number }
    },
)

interface IUpsertUserProfile extends IUserProfile {
    file: File | null,
}

export const upsertUserProfileAsync = createAsyncThunk<IUserProfile, IUpsertUserProfile>(
    'user-profile/upsertUserProfileAsync', async (payload: IUpsertUserProfile, thunkAPI) => {
        const { file, create_ts, insert_ts, ...restPayload } = payload
        if (file) {
            const path = await uploadSingleFileAsync({
                file,
                moduleName: "ACCOUNTING",
                asPayload: {
                    user_uuid: payload.user_uuid, // need to be discuss
                    first_name: payload.first_name,
                    file_name: file.name,
                    file_ext: file.name.split('.').pop() || "",
                }
            })
            restPayload.profile_picture = path
        }

        const response = await axios_api.post(UPSERT_USER_PROFILE_BASE_URL, payload)
        return response.data
    },
)


export const fetchSingleUserProfileAsync = createAsyncThunk<IUserProfile, string>(
    'user-profile/fetchSingleUserProfileAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_USER_PROFILE_BASE_URL}?user_uuid=${uuid}`)
        return response.data.data[0]
    },
)


export const createNewUserUserAsync = createAsyncThunk<ICreateUser, ICreateUser>(
    'user-profile/createNewUserUserAsync', async (payload: ICreateUser, thunkAPI) => {
        const { confirmPassword, ...restPayload} = payload
        const response = await axios_api.post(UPSERT_NEW_USER_BASE_URL, restPayload)
        thunkAPI.dispatch(fetchAllUsersProfileAsync({
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)
