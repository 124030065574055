
import { isNotNull } from '../../../../../helpers/isNotNull';
import { IReceiptComposerOrderItem } from '../../../../../redux/settings/receipt-composer';
import { ISelectOption } from './../../../../../constants/types';
export const order_details_column_list: ISelectOption[] = [
    { label: "Qty", value: "Qty" },
    { label: "Item", value: "Item" },
    { label: "Tax", value: "Tax" },
    { label: "Price", value: "Price" },
]


export const getColumnListForReceipt = (filter_Values: string[]): ISelectOption[] => {
    return order_details_column_list
}


export const calculateColumnWidth = (data: IReceiptComposerOrderItem[]) => {
    let totalWidth = 0
    data.filter((item) => item.column_title.length > 0).forEach((item) => {
        totalWidth = isNotNull(item.column_width)
    })
    return (100 - totalWidth)
}