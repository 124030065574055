


export const getFileNameAndExtension = (file: File): { file_name: string, file_ext: string } => {
    return { file_name: file.name.split(".")[0], file_ext: file.name.split(".")[1] }
}

export const convertBase64ToFile = (base64data: string): File => {
    const file_ext = base64data.split(";")[0].split("/").pop()
    const file_name = `${Math.random().toString(36).slice(-5)}.${file_ext}`
    const file_type = `image/${file_ext}`

    const byteString = atob(base64data.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {
        type: file_type,
    });
    return new File([blob], file_name, { lastModified: new Date().getTime(), type: file_type });
}