import React from "react";
import { ICustomSquareButtonProps } from "./interface/interface";
import { Button, Typography } from "@mui/material";
import { capitalizeFirstLetter } from "../../helpers";
import { CustomButton } from "./CustomButton";

export const CustomSquareButton: React.FC<ICustomSquareButtonProps> = (
  props
) => {
  const { title, sx, icon } = props;
  if (title) {
    return (
      <CustomButton
        {...props}
        variant="contained"
        sx={{ borderRadius: 2, py: 1, px: 2, bgcolor: "white", ...sx }}
      >
        <Typography variant="h5" fontWeight={900}>
          {title}
        </Typography>
      </CustomButton>
    );
  }
  if (icon) {
    return (
      <Button
        {...props}
        variant="contained"
        sx={{
          borderRadius: 2,
          py: 1,
          px: 1,
          minWidth: "48px",
          bgcolor: "white",
          ...sx,
        }}
      >
        {icon}
      </Button>
    );
  }
  return <></>;
};
