import React, { useState } from 'react'
import { IReceiptComposer } from '../../../../../redux/settings/receipt-composer'
import { Box, Grid, InputAdornment } from '@mui/material'
import { ControlledCustomSelect, OutlinedTextField } from '../../../../../Components/formComponents'
import { calculateColumnWidth, getColumnListForReceipt } from './ReceiptOrderDetails.helpers'

interface IReceiptOrderDetails {
    data: IReceiptComposer["order_details"]
    onChange: (d: IReceiptComposer["order_details"]) => void
}
export const ReceiptOrderDetails: React.FC<IReceiptOrderDetails> = ({
    data: orderDetailsList,
    onChange
}) => {

    // const [orderDetailsList, setOrderDetailsList] = useState<IReceiptComposer["order_details"]>(data)

    console.log("ReceiptOrderDetails==>", orderDetailsList)
    return (
        <Grid container spacing={2}>


            <Grid item xs={12} md={3} display={"flex"} flexDirection={"column"} gap={2} >
                <ControlledCustomSelect
                    label="Select Column"
                    name="column_title"
                    fullWidth
                    // options={getColumnListForReceipt()}
                    options={getColumnListForReceipt(orderDetailsList.map((item) => item.column_title))}
                    placeholder="Select Column"
                    value={orderDetailsList[0].column_title}
                    onChange={(evt) => {
                        let newData = [...orderDetailsList]
                        newData[0] = {
                            ...orderDetailsList[0],
                            column_title: evt.target.value as string,
                            column_width: calculateColumnWidth(orderDetailsList).toString()
                        }
                        onChange(newData)
                    }}
                />
                {orderDetailsList[0].column_title.length > 0 &&
                    <>
                        <OutlinedTextField
                            label="Column Width"
                            type="number"
                            name='column_width'
                            value={orderDetailsList[0].column_width}
                            onChange={(evt) => {
                                let newData = [...orderDetailsList]
                                newData[0] = {
                                    ...orderDetailsList[0],
                                    column_width: evt.target.value
                                }
                                onChange(newData)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                        <OutlinedTextField
                            label="Column Value"
                            name='column_value'
                            value={orderDetailsList[0].column_value}
                            onChange={(evt) => {
                                let newData = [...orderDetailsList]
                                newData[0] = {
                                    ...orderDetailsList[0],
                                    column_value: evt.target.value
                                }
                                onChange(newData)
                            }}
                           
                        />
                    </>
                }
            </Grid>


            <Grid item xs={12} md={3} display={"flex"} flexDirection={"column"} gap={2} >
                <ControlledCustomSelect
                    label="Select Column"
                    name="column_title"
                    fullWidth
                    // options={getColumnListForReceipt()}
                    options={getColumnListForReceipt(orderDetailsList.map((item) => item.column_title))}
                    placeholder="Select Column"
                    value={orderDetailsList[1].column_title}
                    onChange={(evt) => {
                        let newData = [...orderDetailsList]
                        newData[1] = {
                            ...orderDetailsList[1],
                            column_title: evt.target.value as string,
                            column_width: calculateColumnWidth(orderDetailsList).toString()

                        }
                        onChange(newData)
                    }}
                />
                {orderDetailsList[1].column_title.length > 0 && <OutlinedTextField
                    label="Column Width"
                    type="number"
                    name='column_width'
                    value={orderDetailsList[1].column_width}
                    onChange={(evt) => {
                        let newData = [...orderDetailsList]
                        newData[1] = {
                            ...orderDetailsList[1],
                            column_width: evt.target.value
                        }
                        onChange(newData)
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />}
            </Grid>

            <Grid item xs={12} md={3} display={"flex"} flexDirection={"column"} gap={2} >
                <ControlledCustomSelect
                    label="Select Column"
                    name="column_title"
                    fullWidth
                    // options={getColumnListForReceipt()}
                    options={getColumnListForReceipt(orderDetailsList.map((item) => item.column_title))}
                    placeholder="Select Column"
                    value={orderDetailsList[2].column_title}
                    onChange={(evt) => {
                        let newData = [...orderDetailsList]
                        newData[2] = {
                            ...orderDetailsList[2],
                            column_title: evt.target.value as string,
                            column_width: calculateColumnWidth(orderDetailsList).toString()
                        }
                        onChange(newData)
                    }}
                />
                {orderDetailsList[2].column_title.length > 0 && <OutlinedTextField
                    label="Column Width"
                    type="number"
                    name='column_width'
                    value={orderDetailsList[2].column_width}
                    onChange={(evt) => {
                        let newData = [...orderDetailsList]
                        newData[2] = {
                            ...orderDetailsList[2],
                            column_width: evt.target.value
                        }
                        onChange(newData)
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />}
            </Grid>
            <Grid item xs={12} md={3} display={"flex"} flexDirection={"column"} gap={2} >
                <ControlledCustomSelect
                    label="Select Column"
                    name="column_title"
                    fullWidth
                    // options={getColumnListForReceipt()}
                    options={getColumnListForReceipt(orderDetailsList.map((item) => item.column_title))}
                    placeholder="Select Column"
                    value={orderDetailsList[3].column_title}
                    onChange={(evt) => {
                        let newData = [...orderDetailsList]
                        newData[3] = {
                            ...orderDetailsList[3],
                            column_title: evt.target.value as string,
                            column_width: calculateColumnWidth(orderDetailsList).toString()
                        }
                        onChange(newData)
                    }}
                />
                {orderDetailsList[3].column_title.length > 0 && <OutlinedTextField
                    label="Column Width"
                    type="number"
                    name='column_width'
                    value={orderDetailsList[3].column_width}
                    onChange={(evt) => {
                        let newData = [...orderDetailsList]
                        newData[3] = {
                            ...orderDetailsList[3],
                            column_width: evt.target.value
                        }
                        onChange(newData)
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />}
            </Grid>
        </Grid>
    )
}
