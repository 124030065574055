import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../redux";
import { ISecurityRoleGroup, upsertSecurityRoleGroupAsync } from "../../../../redux/settings/security";
import { Grid } from "@mui/material";
import LoadingDialog from "../../../../Components/Dialogs/LoadingDialog";
import { CustomFormLabel, CustomTextField, ControlledCustomSelect, OutlinedTextField } from "../../../../Components/formComponents";
import { renderDropdownValue } from "../../../../helpers/renderDropdownValue";
import { Dialog } from "../../../../Components/Dialogs/Dialog";

interface IManageRoleGroupDialogProps {
  open: boolean;
  onClose: () => void;
  roleGroup: ISecurityRoleGroup;
}
export const ManageRoleGroupDialog: React.FC<IManageRoleGroupDialogProps> = (
  props,
) => {
  const { open, roleGroup, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: roleGroup,
    validate: (values) => {
      const errors: any = {};
      if (!values.role_group) {
        errors.role_group = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(upsertSecurityRoleGroupAsync(values)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose();
        }

      }).finally(() => setSaveLoading(false))
    },
  });

  // useEffect(() => {
  //     if (!roleGroup) return;
  //     setValues(roleGroup)
  // }, [roleGroup])

  return (
    <Dialog
      open={open}
      title="Add/Edit Role Group"
      onClose={onClose}
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={1}>
        <LoadingDialog open={saveLoading} />
        <Grid item xs={12}>
          <CustomFormLabel></CustomFormLabel>
          <OutlinedTextField
            label="Role Group"
            id="role_group"
            value={values.role_group}
            onChange={handleChange}
            error={errors.role_group}
          />
        </Grid>
        {values.role_group_uuid && (
          <Grid item xs={12}>
            <CustomFormLabel></CustomFormLabel>
            <ControlledCustomSelect
              label="Status"
              name="status"
              fullWidth
              value={values.status}
              onChange={handleChange}
              displayEmpty
              placeholder="Select one"
              renderValue={renderDropdownValue(`Select One`)}
              options={[
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
              ]}
            />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};
