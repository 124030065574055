import { Button, ButtonProps, styled } from "@mui/material";
import React from "react";

export const CustomButton = styled((props: ButtonProps) => (
  <Button {...props} />
))(({ theme }) => ({
  textTransform: "none",
  ":disabled": {
    border: "1px solid #676767"
  }
}));
