import { createSlice } from "@reduxjs/toolkit";
import { defaultPaymentPaidState } from "./payment-paid.state";
import { getPaymentsPaidListFormLocalStore, setPaymentsPaidListInLocalStore } from "./payment-paid.localstore";
import { fetchPaymentPaidListWithArgsAsync } from "./payment-paid.actions";
import { LoadState } from "../../../constants/enum";



const paymentPaidSlice = createSlice({
    initialState: defaultPaymentPaidState,
    name: "payments-paid",
    reducers: {
        clearPaymentsPaidState: (state) => {
            return defaultPaymentPaidState
        },
    },
    extraReducers: (builder) => {
        //############################# Multiple Payment Paid List  ######################################
        builder.addCase(fetchPaymentPaidListWithArgsAsync.pending, (state, action) => {
            state.payments_paid_list.loading = LoadState.pending
        })
        builder.addCase(fetchPaymentPaidListWithArgsAsync.fulfilled, (state, action) => {
            state.payments_paid_list.loading = LoadState.succeeded
            state.payments_paid_list.data = action.payload.data
            state.payments_paid_list.totalRecords = action.payload.recordCount
            state.payments_paid_list.error = null
        })
        builder.addCase(fetchPaymentPaidListWithArgsAsync.rejected, (state, action) => {
            state.payments_paid_list.error = action.error.message as string
        })
        
    },
});

export const paymentPaidReducer = paymentPaidSlice.reducer;
export const { clearPaymentsPaidState } = paymentPaidSlice.actions;
