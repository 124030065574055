import React, { } from "react";
import { Grid, } from "@mui/material";
import { Dialog } from "../../../../../Components/Dialogs/Dialog";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../../redux";
import { CustomDatePicker } from "../../../../../Components/formComponents/CustomDatePicker";
import { upsertShiftManuallyAsync, defaultUpsertManualShift } from "../../../../../redux/shifts";
import { AddShiftPunchInfo } from "./AddShiftPunchInfo";
import { UserAutoSearch } from "../../../../Settings/UserProfile/autoSearch/UserAutoSearch";


export interface IUpsertReservationDialogProps {
  open: boolean;
  onClose: () => void;
}


export const UpsertUserShiftDialog: React.FC<IUpsertReservationDialogProps> = (
  props
) => {
  const { open, onClose } = props;
  const dispatch = useAppDispatch();



  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: { ...defaultUpsertManualShift },
    validate: (values) => {
      const errors: any = {};
      if (!values.user_uuid) {
        errors.user_uuid = "*This field is required.";
      }
      if (!values.punch_date) {
        errors.punch_date = "*This field is required.";
      }
      // if (!values.user_phone_no) {
      //   errors.user_phone_no = "*This field is required.";
      // }
      // if (!values.reservation_date) {
      //   errors.reservation_date = "*This field is required.";
      // }
      // if (!values.reservation_from_time) {
      //   errors.reservation_from_time = "*This field is required.";
      // }
      // if (!values.reservation_to_time) {
      //   errors.reservation_to_time = "*This field is required.";
      // }
      // if (!values.number_of_people) {
      //   errors.number_of_people = "*This field is required.";
      // }
      // if (!values.table_no) {
      //   errors.table_no = "*This field is required.";
      // }
      return errors;
    },
    onSubmit: async (values) => {
      dispatch(upsertShiftManuallyAsync({
        ...values,
      })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      })
    },
  });

  // console.log("user Profile data => ", values)

  return (
    <Dialog
      open={open}
      title="Add User Shift Information"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserAutoSearch
              value={{
                user_name: values.user_name,
                user_uuid: values.user_uuid
              }}
              onSelect={(newValue) => {
                setValues({
                  ...values,
                  user_name: `${newValue.first_name} ${newValue?.last_name}`,
                  user_uuid: newValue.user_uuid as string,
                  user_image: newValue.profile_picture
                })
              }}
              error={errors.user_uuid}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDatePicker
              label="Punch Date"
              fullWidth
              fieldName="punch_date"
              value={values.punch_date}
              onChange={(newValue, formattedDate) => {
                setFieldValue("punch_date", formattedDate);
              }}
              error={errors.punch_date}
            />
          </Grid>
          <Grid item xs={12}>
            <AddShiftPunchInfo data={values.time}
              onChange={(data) => setFieldValue("time", data)}
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
