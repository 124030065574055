import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OrderCard } from "../../../Components/Card";
import {  fetchOrderRevenueAsync } from "../Dashboard.actions";
import { IAirlineRevenueChart } from "../Dashboard.types";

export const DashboardCards: React.FC = () => {

  const [airlineRevenue, setAirlineRevenue] = useState<IAirlineRevenueChart>({
    today: 0,
    yesterday: 0,
    current_week: 0,
    current_month: 0
  })
  useEffect(() => {
    fetchOrderRevenueAsync().then((data) => {
      setAirlineRevenue(data)
    })
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <OrderCard title={"Today's Revenue"} amount={`₹${airlineRevenue.today}`} bgIsPurple={true} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <OrderCard title={"Yesterday's Revenue"} amount={`₹${airlineRevenue.yesterday}`} bgIsPurple={false} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <OrderCard title={"Current Week Revenue"} amount={`₹${airlineRevenue.current_week}`} bgIsPurple={false} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <OrderCard title={"Current Month Revenue"} amount={`₹${airlineRevenue.current_month}`} bgIsPurple={false} />
      </Grid>
    </Grid>
  );
};
