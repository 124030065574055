import React, { useState } from "react";
import {
  experimentalStyled,
  useMediaQuery,
  Box,
  Theme,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Header } from "./header/Header";
import { Sidebar } from "./sidebar/Sidebar";
import { Customizer } from "./customizer/Customizer";
import { Footer } from "./footer/Footer";
import { IStoreState } from "../../redux";
import { TopbarHeight } from "../../assests/global/Theme-variable";
import { borderColor } from "../../assests/global/colors";

const MainWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
  width: "100%",
}));
const PageWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  flexDirection: "column",

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("lg")]: {
    paddingTop: TopbarHeight,
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: "64px",
  },
}));

export const FullLayout: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const customizer = useSelector(
    (state: IStoreState) => state.customizer,
  );
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  return (
    <MainWrapper className={customizer.activeMode === "dark" ? "darkbg" : ""}>
      <Header
        sx={{
          borderBottom: "1px solid " + borderColor,
        }}
        toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
        toggleMobileSidebar={() => setMobileSidebarOpen(true)}
      />

      <Sidebar
        isSidebardir={customizer.activeDir === "ltr" ? "left" : "right"}
        isSidebarOpen={isSidebarOpen}
        isMobileSidebarOpen={isMobileSidebarOpen}
        onSidebarClose={() => setMobileSidebarOpen(false)}
      />

      <PageWrapper>
        <Box
          sx={{
            paddingTop: "20px",
            paddingLeft: isSidebarOpen && lgUp ? "230px!important" : "",
          }}
        >
          <Box sx={{ minHeight: "calc(100vh - 170px)", paddingInline: 2 }}>
            <Outlet />
          </Box>
        </Box>
        <Customizer />
        {/* <Footer /> */}
      </PageWrapper>
    </MainWrapper>
  );
};
