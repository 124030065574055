import { Inventory } from "./Inventory";
import { CATEGORIES_ROUTE, CUSTOMIZATION_ROUTE, INVENTORY_ROUTE, PRODUCTS_ROUTE } from "./Inventory.constants";
import { ManageSingleProduct } from "./Products/ManageSingleProduct";

export const inventoryRoutes = [

    // ##################### Products Route ############################
    {
        path: `${PRODUCTS_ROUTE}`,
        exact: true,
        element: <Inventory activeTab={0} />
    },
    {
        path: `${PRODUCTS_ROUTE}/manage`,
        exact: true,
        element: <ManageSingleProduct />
    },
    {
        path: `${PRODUCTS_ROUTE}/manage/:uuid`,
        exact: true,
        element: <ManageSingleProduct />
    },
    // ##################### Categories Route ############################
    {
        path: CATEGORIES_ROUTE,
        exact: true,
        element: <Inventory activeTab={1} />
    },
    // ##################### Customization Route ############################
    {
        path: `${CUSTOMIZATION_ROUTE}`,
        exact: true,
        element: <Inventory activeTab={2} />
    },
    // ##################### Inventory List Route ############################
    {
        path: `${INVENTORY_ROUTE}`,
        exact: true,
        element: <Inventory activeTab={2} />
    },


] 