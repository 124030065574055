import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../../common";
import { axios_api } from "../../../api/axios-api";
import { IChartOfAccount } from "./charts-of-account.types";

const GET_ACCOUNTING_CHART_OF_ACCOUNTS_ENDPOINT= "/accounting/get-accounting-bank-info"
const UPSERT_ACCOUNTING_CHART_OF_ACCOUNTS_ENDPOINT = "/accounting/upsert-accounting-bank-info"

export const fetchChartOfAccountsListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IChartOfAccount[] }, IQueryParams>(
    'pos-bank-info/fetchChartOfAccountsListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_ACCOUNTING_CHART_OF_ACCOUNTS_ENDPOINT}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const upsertSingleChartOfAccountAsync = createAsyncThunk<IChartOfAccount, IChartOfAccount>(
    'pos-bank-info/upsertSingleChartOfAccountAsync', async (payload: IChartOfAccount, thunkAPI) => {
        const response = await axios_api.post(UPSERT_ACCOUNTING_CHART_OF_ACCOUNTS_ENDPOINT, payload)
        thunkAPI.dispatch(fetchChartOfAccountsListWithArgsAsync({
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)