function NodeLabel(props: {  disabled?: boolean, placeholder: string, value: string, onChange: (text: string) => void }) {
  return (
    <input
      type='text'
      className='node-label'
      style={{
        color: props.disabled ? "#868686" : "#030303 !important",
        fontWeight: 700
      }}
      disabled={props.disabled}
      value={props.value}
      placeholder={props.placeholder}
      onChange={(evt) => props.onChange(evt.target.value)} />
  );
}

export default NodeLabel;
