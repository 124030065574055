/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { StandardCard } from "../../../Components/Card";
import { ControlledCustomSelect, CustomFormLabel, OutlinedTextField } from "../../../Components/formComponents";
import { CustomDatePicker } from "../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { useAuthProvider } from "../../../providers";
import { fetchSingleUserProfileAsync, upsertUserProfileAsync } from "../../../redux/settings/user-profile/user-profile.actions";
import { ILocationResponsePayload, LocationAutoComplete } from "../../../Components/LocationAutoComplete/LocationAutoComplete";
import { ProfileIntroCard } from "../../../Components/Profile/ProfileIntoCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clearSingleUserStateSync } from "../../../redux/settings/user-profile";
import { ALL_USERS_LIST_ROUTE } from "./UserProfile.constant";

export const ManageSingleUserProfile = () => {
  const { uuid: profileUUID } = useParams<{ uuid?: string }>();
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { authInfo } = useAuthProvider();
  const { data: userProfileInfo } = useAppSelector((storeState: IStoreState) => storeState.settings.userProfile.single_user);

  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);
  const [saveLoading, setSaveLoading] = React.useState(false);

  const {
    values,
    errors,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...userProfileInfo },
    validate: (values) => {
      const errors: any = {};
      if (!values.first_name) {
        errors.first_name = "*First Name is required";
      }
      if (!values.last_name) {
        errors.last_name = "*Last Name is required.";
      }
      if (values.phone_number && values.phone_number.toString().length !== 10) {
        errors.phone_number = "*Phone number must be 10 digits";
      }
      if (!values.email) {
        errors.email = "*Email is required.";
      } else if (
        values.email !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.email,
        )
      ) {
        errors.email = "*Invalid email address.";
      }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertUserProfileAsync({...values, file: fileToUpload}),
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          if (pathname.includes('/profile')) {
            setSaveLoading(false);
          } else {
            navigate(ALL_USERS_LIST_ROUTE)
          }
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });

  console.log("errors ===>>>>", values.phone_number)
  console.log("errors ===>>>>", errors)
  // const handleAddress = (data: ILocationResponsePayload) => {
  //   setValues({
  //     ...values,
  //     street_address: data.address,
  //     city: data.city,
  //     country: data.country,
  //     province_or_state: data.state,
  //     postal_code: data.postalCode,
  //   });
  // };


  React.useEffect(() => {
    console.log("pathname ===>", pathname)
    if (profileUUID) {
      dispatch(fetchSingleUserProfileAsync(profileUUID));
    }
    if (authInfo.user.user_uuid && pathname.includes('/profile')) {
      console.log("pathname true===>", pathname)
      dispatch(fetchSingleUserProfileAsync(authInfo.user.user_uuid));
    }
  }, [profileUUID, authInfo.user.user_uuid, pathname]);

  React.useEffect(() => {
    setValues(userProfileInfo);
  }, [userProfileInfo]);

  React.useEffect(() => {
    return () => {
      dispatch(clearSingleUserStateSync())
    }
  }, []);

  const handleUserAddressChange = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      user_address_line2: data.address,
      user_address_city: data.city,
      user_address_state: data.state,
      user_address_country: data.country,
      user_address_pin_code: data.postalCode,
    });
  };


  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant='h2'>Profile</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <StandardCard
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <ProfileIntroCard
                fileUrl={values.profile_picture}
                title="Change Profile"
                subTitle="Change your profile picture from here"
                onChange={(file) => setFileToUpload(file)}
                onFileReset={() => setFieldValue("profile_picture", null)}
              />
            </StandardCard>
          </Grid>
          <Grid item xs={12} md={9}>
            <StandardCard
              heading="Personal Information"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="First Name"
                    id="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    error={errors.first_name}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Last Name"
                    id="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    error={errors.last_name}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ControlledCustomSelect
                    label="Gender"
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    options={[
                      { label: "Male", value: "MALE" },
                      { label: "FeMale", value: "FEMALE" },
                      { label: "Other", value: "OTHER" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomDatePicker
                    label="Date of Birth"
                    fullWidth
                    value={values.date_of_birth || ""}
                    onChange={(newValue) => {
                      const newDate = moment(newValue).format("YYYY-MM-DD");
                      setFieldValue("date_of_birth", newDate);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Phone Number"
                    id="phone_number"
                    type="number"
                    value={values.phone_number}
                    onChange={handleChange}
                    error={errors.phone_number}
                  />
                </Grid>
               
                <Grid item xs={12} md={6}>
                  <OutlinedTextField
                    label="Email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Login Pin"
                    id="login_pin"
                    disabled
                    value={values.login_pin}
                    onChange={handleChange}
                  />
                </Grid>
                
                <Grid item xs={12} lg={6}>
                  <LocationAutoComplete
                    id="address"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.user_address_line2 ? values.user_address_line2 : undefined}
                    onLocationChange={handleUserAddressChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <OutlinedTextField
                    label="Address Line 1"
                    id="user_address_line1"
                    fullWidth
                    value={values.user_address_line1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <OutlinedTextField
                    label="Address Line 2"
                    id="user_address_line2"
                    fullWidth
                    value={values.user_address_line2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="City"
                    id="user_address_city"
                    fullWidth
                    value={values.user_address_city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="State"
                    id="user_address_state"
                    fullWidth
                    value={values.user_address_state}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Country"
                    id="user_address_country"
                    fullWidth
                    value={values.user_address_country}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Pin Code"
                    id="user_address_pin_code"
                    fullWidth
                    value={values.user_address_pin_code}
                    onChange={handleChange}
                  />
                </Grid>



              </Grid>
            </StandardCard>
          </Grid>
        </Grid>



        <Grid item xs={12} md={9}>
          <StandardCard
            heading="Office Information"
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <ControlledCustomSelect
                  label="Store Location"
                  name="store_location"
                  value={values.store_location}
                  onChange={handleChange}
                  options={[
                    { label: "Location One", value: "LOCATION_ONE" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <ControlledCustomSelect
                  label="Salary Type"
                  name="salary_type"
                  value={values.salary_type}
                  onChange={handleChange}
                  options={[
                    { label: "Payroll", value: "PAYROLL" },
                    { label: "Salary", value: "SALARY" },
                  ]}
                />
              </Grid>
              {values.salary_type === "SALARY" &&
                <Grid item xs={12} md={2}>
                  <OutlinedTextField
                    label="Monthly Salary"
                    id="monthly_salary"
                    type="number"
                    value={values.monthly_salary}
                    onChange={handleChange}
                  />
                </Grid>
              }
              {values.salary_type === "PAY_ROLE" &&
                <Grid item xs={12} md={2}>
                  <ControlledCustomSelect
                    label="Payroll Type"
                    name="pay_role_type"
                    value={values.pay_role_type}
                    onChange={handleChange}
                    options={[
                      { label: "Weekly", value: "WEEKLY" },
                      { label: "Bi-weekly", value: "BI_WEEKLY" },
                      { label: "Monthly", value: "MONTHLY" },
                    ]}
                  />
                </Grid>
              }
              {values.salary_type === "PAY_ROLE" &&
                <Grid item xs={12} md={2}>
                  <OutlinedTextField
                    label="Hourly Wage"
                    id="hourly_wage"
                    type="number"
                    value={values.hourly_wage}
                    onChange={handleChange}
                  />
                </Grid>
              }
              <Grid item xs={12} md={2}>
                <ControlledCustomSelect
                  label="Role"
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                  options={[]}
                />
              </Grid>
            </Grid>
          </StandardCard>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "15%" }}
            disabled={saveLoading}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  );
};
