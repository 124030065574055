import { InputAdornment, TextFieldProps } from "@mui/material";
import { CustomTextField } from "./CustomTextField";
import { CiSearch } from "react-icons/ci";

export const CustomSearchField = (props: TextFieldProps) => {
  return (
    <CustomTextField
      {...props}
      sx={{
        "& .MuiInputBase-root": {
          borderRadius: "8px",
          backgroundColor: "#292C2D",
          // marginBottom: "8px",
        },
        "& .MuiOutlinedInput-input": {
          padding: "8px 8px 8px 0px",
        },
      }}
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CiSearch fontSize={"2rem"} />
          </InputAdornment>
        ),
      }}
    />
  );
};
