import React, { useState } from 'react'
import { RightPanel } from '../../../../Components/RightPanel';
import { Box, Breadcrumbs, Button, Checkbox, Chip, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { IProduct, defaultProduct, defaultProductModifier, upsertSingleProductAsync } from '../../../../redux/inventory';
import { PRODUCTS_ROUTE } from '../../Inventory.constants';
import { useAppDispatch } from '../../../../redux';
import { useNavigate } from 'react-router-dom';
import { LuIndianRupee } from 'react-icons/lu';
import { MdOutlinePercent } from 'react-icons/md';
import { StandardCard } from '../../../../Components/Card';
import { CustomChip } from '../../../../Components/CustomChip';
import LoadingDialog from '../../../../Components/Dialogs/LoadingDialog';
import { OutlinedTextField, CustomFormLabel, ControlledCustomSelect } from '../../../../Components/formComponents';
import { CategoryAutoSearch } from '../../Category/AutoSearch/CategoryAutoSearch';
import { CustomDivider } from '../../../../Components/CustomDivider/CustomDivider';
import { ManageSingleModifier } from './ManageSingleModifier';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ModifiersTable } from './ModifiersTable';

interface ICustomizationOptionRightPanelProps {
    open: boolean,
    customizationUUID: string
    onClose: () => void
}
export const CustomizationOptionRightPanel: React.FC<ICustomizationOptionRightPanelProps> = ({
    open, customizationUUID, onClose
}) => {

    const [currentTab, setCurrentTab] = useState<"List" | "editable">("List")
    const [selectedModifier, setSelectedModifier] = useState<string | null>(null)


    const handleCreateNewOption = () => {
        setSelectedModifier(null)
        setCurrentTab("editable")
    }
    const handleEditOptionClick = (uuid: string) => {
        setSelectedModifier(uuid)
        setCurrentTab("editable")
    }

    const handleOnCancelClick = () => {
        setSelectedModifier(null)
        setCurrentTab("List")
    }

    return (
        <RightPanel
            open={open}
            width='80%'
        >
            <Stack marginBlock={1} spacing={0.5} direction="row" justifyContent="flex-start" alignItems="center" >
                <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
                <Chip sx={{ ml: 2 }} size="small" label="Customizations" onClick={onClose} />
                <KeyboardArrowRightIcon />
                <Chip size="small" label="Options" onClick={() => setCurrentTab("List")} />
                {currentTab === "editable" &&
                    <>
                        <KeyboardArrowRightIcon />
                        <Chip size="small" label="Manage" />
                        {selectedModifier &&
                            <>
                                <KeyboardArrowRightIcon />
                                <Typography variant='body1'>{selectedModifier}</Typography>
                            </>
                        }
                    </>

                }
            </Stack>

            {currentTab === "List" ?
                <ModifiersTable
                    customizationUUID={customizationUUID}
                    onCreateNew={handleCreateNewOption}
                    onEditClick={handleEditOptionClick}
                />
                :
                <ManageSingleModifier
                    customizationUUID={customizationUUID}
                    modifierUUID={selectedModifier}
                    onCancel={handleOnCancelClick} />
            }
        </RightPanel>
    )
}
