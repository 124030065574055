import React, { useState } from 'react'
import { ControlledCustomSelect } from '../../../../Components/formComponents'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { fetchAllUsersProfileAsync } from '../../../../redux/settings/user-profile/user-profile.actions';

interface IUsersDropDownProps {
    value: string | null
    onChange: (data: string) => void
    error?: string
}
export const UsersDropDown: React.FC<IUsersDropDownProps> = ({
    value,
    onChange,
    error
}) => {
    const {
        data: outletsList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.userProfile.users_list);
    const dispatch = useAppDispatch();

    const [companiesList, setCompaniesList] = useState<{ label: string, value: string | number }[]>([])

    React.useEffect(() => {
        dispatch(
            fetchAllUsersProfileAsync({
                page: 1,
                rowsPerPage: 1000,
            }),
        );
    }, []);

    React.useEffect(() => {
        const newList = outletsList.map((data) => ({ label: `${data.first_name} ${data.last_name || ""}`, value: data.user_uuid || "" }))
        setCompaniesList(newList)
    }, [outletsList]);

    return (
        <ControlledCustomSelect
            label="Select Users"
            options={companiesList}
            value={value}
            onChange={(evt) => onChange(evt.target.value as string)}
            sx={{ minWidth: 120 }}
            fullWidth size="small"
            error={error}
        />
    )
}
