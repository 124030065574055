import { createSlice } from "@reduxjs/toolkit";
import { defaultPaymentReceivedState } from "./payment-received.state";
import { getPaymentsReceivedListFormLocalStore, setPaymentsReceivedListInLocalStore } from "./payment-received.localstore";
import { fetchPaymentReceivedListWithArgsAsync } from "./payment-received.actions";
import { LoadState } from "../../../constants/enum";



const paymentReceivedSlice = createSlice({
    initialState: defaultPaymentReceivedState,
    name: "payments-received",
    reducers: {
        clearPaymentReceivedStateSync: (state) => {
            return defaultPaymentReceivedState
        },
    },
    extraReducers: (builder) => {
        // ############################# Multiple Payment Received List ######################################
        builder.addCase(fetchPaymentReceivedListWithArgsAsync.pending, (state, action) => {
            state.payments_received_list.loading = LoadState.pending
        })
        builder.addCase(fetchPaymentReceivedListWithArgsAsync.fulfilled, (state, action) => {
            state.payments_received_list.loading = LoadState.succeeded
            state.payments_received_list.data = action.payload.data
            state.payments_received_list.totalRecords = action.payload.recordCount
            state.payments_received_list.error = null
        })
        builder.addCase(fetchPaymentReceivedListWithArgsAsync.rejected, (state, action) => {
            state.payments_received_list.error = action.error.message as string
        })


        //#################################### Single Product Category ##############################################
        // builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.pending
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.succeeded
        //     state.single_airline_menu.data = action.payload
        //     state.single_airline_menu.error = null
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
        //     state.single_airline_menu.error = action.error.message as string
        // })
    },
});

export const paymentReceivedReducer = paymentReceivedSlice.reducer;
export const { clearPaymentReceivedStateSync } = paymentReceivedSlice.actions;
