


export const getAuthInfo = (): string | null => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      return JSON.parse(auth).token;
    }
    return null;
  };
  