import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { fetchInventoryWithArgsAsync } from "./inventory.actions";
import { defaultInventoryState } from "./inventory.state";

const inventorySlice = createSlice({
    initialState: defaultInventoryState,
    name: "inventory",
    reducers: {
        clearInventoryState: (state) => {
            return defaultInventoryState
        },
    },
    extraReducers: (builder) => {
        //############################# Multiple Inventory Items ######################################
        builder.addCase(fetchInventoryWithArgsAsync.pending, (state, action) => {
            state.inventory_list.loading = LoadState.pending
        })
        builder.addCase(fetchInventoryWithArgsAsync.fulfilled, (state, action) => {
            state.inventory_list.loading = LoadState.succeeded
            state.inventory_list.data = action.payload.data
            state.inventory_list.totalRecords = action.payload.recordCount
            state.inventory_list.error = null
        })
        builder.addCase(fetchInventoryWithArgsAsync.rejected, (state, action) => {
            state.inventory_list.error = action.error.message as string
        })

    },
});

export const inventoryReducer = inventorySlice.reducer;
export const { clearInventoryState } = inventorySlice.actions;
