import { useFormik } from 'formik';
import React from 'react'
import { defaultTextMessageCampaign } from '../../../../redux/marketing/campaigns';
import { OutlinedTextField } from '../../../../Components/formComponents';
import { Box, Grid } from '@mui/material';
import { MultiPhoneNumberField } from './MultiPhoneNumberField';

export const CampaignMultiSmsScreen = () => {

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        isValid,
        resetForm,
    } = useFormik({
        initialValues: {
            ...defaultTextMessageCampaign,
        },
        validate: (values) => {
            const errors: any = {};
            if (values.phone_numbers.length <= 0) {
                errors.phone_numbers = "*This Field is required.";
            }
            if (!values.message_body) {
                errors.message_body = "*This Field is required";
            }

            return errors;
        },
        onSubmit: async (values) => {

        },
    });

    return (
        <Box component="div" >
            <Grid container spacing={3} direction={"column"}>
                <Grid item xs={12}>
                    <MultiPhoneNumberField
                        emails={values.phone_numbers}
                        error={errors.phone_numbers as string}
                        onChange={(emails) => {
                            setFieldValue("phone_numbers", emails);
                        }}
                        placeholder="To"
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Message Text"
                        id="message_body"
                        multiline
                        minRows={5}
                        value={values.message_body}
                        onChange={handleChange}
                        error={errors.message_body}
                    />
                </Grid>

            </Grid>



        </Box>
    )
}
