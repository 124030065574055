import { LoadState } from "../../../constants/enum";
import { ITaxInfo, ITaxInfoState } from "./tax-info.types";


export const defaultTaxInfo: ITaxInfo = {
    tax_uuid: null,
    tax_country: "",
    tax_state: "",
    tax_name: "",
    tax_rate: "",
    company_uuid: "",
    location_uuid: "",
    status: "ACTIVE"
}


export const defaultTaxInfoState: ITaxInfoState = {
    tax_info_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_tax_info: {
        loading: LoadState.idle,
        data: defaultTaxInfo,
        error: null,
    }
}