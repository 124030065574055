import { LoadState } from "../../../constants/enum";
import { IPosBankInfoState, IPosBankInfo } from "./bank-info.types";


export const defaultPosBankInfo: IPosBankInfo = {
    bank_uuid: null,
    bank_name: "",
    bank_address: "",
    account_number: "",
    transit_number: "",
    institution_number: "",
    opening_balance: "",
    opening_date: ""
}

export const defaultPosBankInfoState: IPosBankInfoState = {
    bank_info_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null
    },
    single_bank_info: {
        loading: LoadState.idle,
        data: defaultPosBankInfo,
        error: null
    }
}