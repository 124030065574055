import React from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { IOrderCardProps } from "./interface/interface";

import { FaDollarSign } from "react-icons/fa";
export const OrderCard: React.FC<IOrderCardProps> = (props) => {
  const { title, amount, bgIsPurple } = props;

  return (
    <Box display={"flex"}>
      <Paper
        elevation={1}
        square={false}
        sx={{
          p: "20px 100px 30px 20px",
          bgcolor: bgIsPurple ? "#C9CAEF" : "#292C2D", //C9CAEF, 292C2D
          color: bgIsPurple ? "black" : "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "space-between",
          gap: 6,
          borderRadius: 1,
          width: "100%",
        }}
      >

        <FaDollarSign
        style={{
          fontSize: "3rem",
          color: "#595959",
          borderRadius: "50%",
          backgroundColor: "white",
          padding: "0.5rem",
        }}/>

        <Stack direction={"column"} gap={0.1}>
          <Typography
            variant="h4"
            fontWeight={"500"}
            color={bgIsPurple ? "#595959" : "#DDDDDD"}
          >
            {" "}
            {/* 595959, DDDDDD */}
            {title}
          </Typography>
          <Typography variant="h1" fontWeight={"bold"} letterSpacing={0.5}>
            {amount}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};
