/* eslint-disable react-hooks/exhaustive-deps */
import React, { SyntheticEvent } from "react";
import {
    Autocomplete,
    Box,
    CircularProgress,
    Stack,
} from "@mui/material";
import { debounce } from "lodash";
import { CustomTextField } from "../../../../Components/formComponents";
import { axios_api } from "../../../../api/axios-api";
import { IInventory, defaultInventory } from "../../../../redux/inventory";
import { GET_INVENTORY_BASE_URL } from "../../../../api/axios-api.endpoints";

export interface IInventoryAutoSearchProps {
    label?: string;
    name: string;
    value: {
        inventory_uuid: string,
        product_name: string,
    };
    onSelect: (data: IInventory) => void;
    disabled?: boolean;
    error?: string;
}

const INITIAL_STATE: IInventory = defaultInventory;

export const InventoryAutoSearch: React.FC<IInventoryAutoSearchProps> = ({
    label, name, value, disabled, error, onSelect
}) => {

    const [options, setOptions] = React.useState<readonly IInventory[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [search, setSearchText] = React.useState<string>("");

    const fetchSuggestion = async (enteredValue: string) => {
        setLoading(true);
        try {
            let searchQuery = "";
            if (enteredValue.length > 0) {
                searchQuery += `columns=product_name&value=${enteredValue}&`;
            }
            const res = await axios_api.get(
                `${GET_INVENTORY_BASE_URL}?${searchQuery}`,
            );
            const finalData: IInventory[] = res.data.data;
            if (value?.inventory_uuid && value?.inventory_uuid?.length > 0 && search.length === 0) {
                const option: IInventory = {
                    ...INITIAL_STATE,
                    inventory_uuid: value.inventory_uuid,
                    product_name: value.product_name,
                };
                finalData.push(option);
            }
            setOptions(finalData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

    const getOptionLabel = (option: IInventory | string) => {
        if (typeof option === "string") return option || "";
        return option.product_name || ""
    };

    const getValue = () => {
        return (
            options.find((option) => option.inventory_uuid === value.inventory_uuid) ||
            null
        );
    };

    const handleDropDownOnFocus = () => {
        debounceFn("");
    };

    React.useEffect(() => {
        if (search && search.length > 0 && search !== value.product_name) {
            debounceFn(search);
        }
    }, [search]);

    React.useEffect(() => {
        if (value.inventory_uuid && value.inventory_uuid.length > 0) {
            const option: IInventory = {
                ...INITIAL_STATE,
                inventory_uuid: value.inventory_uuid,
                product_name: value.product_name,
            };
            setOptions([option])
        } else {
            setOptions([]);
        }
    }, [value]);

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
                <Autocomplete
                    disablePortal
                    fullWidth
                    freeSolo
                    size="small"
                    disabled={disabled}
                    sx={{
                        minWidth: 180,
                        ".MuiOutlinedInput-root": {
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            fontSize: "0.8rem",
                            color: "rgb(38, 38, 38)",
                            width: "100%",
                            backgroundColor: disabled ? "#f8f9fb" : "inherit",
                        },
                    }}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={(option, value) =>
                        typeof option !== "string" && typeof value !== "string"
                            ? option.product_name === value.product_name
                            : option === value
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    value={getValue()}
                    noOptionsText="No Record Found"
                    onFocus={handleDropDownOnFocus}
                    onChange={(
                        event: SyntheticEvent<Element, Event>,
                        newValue: string | IInventory | null,
                    ) => {
                        if (newValue && typeof newValue !== "string") {
                            onSelect(newValue);
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        if ((event && event.type === "change") || !newInputValue) {
                            setSearchText(newInputValue);
                        }
                    }}
                    renderInput={(params) => (
                        <CustomTextField
                            name={name}
                            {...params}
                            fullWidth
                            label={label}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            disabled={disabled}
                            error={error ? true : false}
                            helperText={error}
                        />
                    )}
                />
            </Stack>
        </>
    );
};
