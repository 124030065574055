import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ORDERS_BASE_URL, UPSERT_ORDERS_BASE_URL } from "../../api/axios-api.endpoints";
import { IOrder } from "./orders.types";
import { IQueryParams, getSearchQuery } from "../../common";
import { axios_api } from "../../api/axios-api";




export const fetchOrdersListAsync = createAsyncThunk<{ recordCount: number, data: IOrder[] }>(
    'orders/fetchOrdersListAsync', async () => {
        const response = await axios_api.get(`${GET_ORDERS_BASE_URL}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchOrdersListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IOrder[] }, IQueryParams>(
    'orders/fetchOrdersListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_ORDERS_BASE_URL}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const fetchSingleOrderAsync = createAsyncThunk<IOrder, string>(
    'orders/fetchSingleOrderAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_ORDERS_BASE_URL}?order_uuid=${uuid}`)
        const data = response.data.data[0];
        return data
    },
)


export const upsertSingleOrderAsync = createAsyncThunk<IOrder, IOrder>(
    'orders/upsertSingleCategoryAsync', async (payload: IOrder, thunkAPI) => {
        const response = await axios_api.post(UPSERT_ORDERS_BASE_URL, payload)
        thunkAPI.dispatch(fetchOrdersListWithArgsAsync({
            page: 1,
            rowsPerPage: 25
        }))
        return response.data
    },
)


export interface IUpsertSingleOrderWithCallback {
    payload: IOrder,
    onSuccess: (isSuccess: boolean, data?: IOrder) => void
}
export const upsertSingleOrderWithCallbackAsync = createAsyncThunk<IOrder, IUpsertSingleOrderWithCallback>(
    'orders/upsertSingleOrderWithCallbackAsync', async ({ payload, onSuccess }: IUpsertSingleOrderWithCallback, thunkAPI) => {
        const response = await axios_api.post(UPSERT_ORDERS_BASE_URL, payload)
        if (response.status === 200) {
            onSuccess(true, response.data.data)
        } else {
            onSuccess(false)
        }
        return response.data
    },
)