import { ICustomTableNode } from "../../Components/ReactFlowShapes/ReactFlowShapes.types"



export const setTablesDataInLocalStore = (data: ICustomTableNode[]) => {
    localStorage.setItem("table-shapes", JSON.stringify(data))
}

export const getTablesDataFormLocalStore = (): ICustomTableNode[] => {
    const data = localStorage.getItem("table-shapes")
    if (data) {
        return JSON.parse(data)
    }
    return []
}