import { Autocomplete, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { debounce } from "lodash";
import { axios_api } from "../../../../api/axios-api";
import { OutlinedTextField } from "../../../../Components/formComponents";
import { defaultUserProfile, IUserProfile } from "../../../../redux/settings/user-profile";
import { GET_USER_PROFILE_BASE_URL } from "../../../../redux/settings/user-profile/user-profile.actions";

export interface IUserAutoSearchProps {
  value: any;
  onSelect: (value: IUserProfile) => void;
  error?: string;
}


export const UserAutoSearch: React.FC<IUserAutoSearchProps> = (
  props,
) => {
  const { value, error, onSelect } = props;
  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<IUserProfile[]>([]);
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    fetchSuggestion(value);
  }, []);


  // React.useEffect(() => {
  //   if (value) {
  //     fetchSuggestion(value);
  //   } else {
  //     setSearchText("");
  //   }
  // }, [value]);

  React.useEffect(() => {
    if (searchText && searchText !== value.first_name) {
      fetchSuggestion(searchText);
    }
  }, [searchText]);

  React.useEffect(() => {
    if (value && typeof value === "object" && value?.user_uuid?.length > 0) {
      const option: IUserProfile = {
        ...defaultUserProfile,
        user_uuid: value.user_uuid,
        first_name: value.first_name,
      };
      setSearchResults([option]);
    }
  }, []);

  const fetchSuggestion = debounce(async (value) => {
    setLoading(true);
    try {
      const res = await axios_api.get(`${GET_USER_PROFILE_BASE_URL}`);
      const data: any[] = res.data.data;
      setSearchResults(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchText(value);
  };

  const getOptionLabel = (option: string | IUserProfile) => {
    if (typeof option === "string") {
      return option;
    }
    return `${option.first_name} ${option?.last_name || ""}`;
  };

  console.log("user Value ==?", value)

  return (
    <>
      <Autocomplete
        options={searchResults}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },
        }}
        value={searchResults.find((option) => option.user_uuid === value.user_uuid) || null}
        defaultValue={value}
        getOptionLabel={getOptionLabel}
        onChange={(e, newValue) => {
          if (newValue && typeof newValue !== "string") {
            onSelect(newValue);
          }
        }}
        isOptionEqualToValue={(option, value) =>
          typeof option === "string"
            ? option === value //@ts-ignore
            : option.user_uuid === value.user_uuid
        }
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
          }
        }}
        renderInput={(params) => (
          <OutlinedTextField
            {...params}
            label="User"
            fullWidth
            value={searchText}
            onChange={handleChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            error={error}
          />
        )}
      />
    </>
  );
};
