import { LoadState } from "../../../constants/enum";
import { ICoupon, ICouponState } from "./coupon-codes.types";


export const defaultCoupon: ICoupon = {
    coupon_uuid:  null,
    coupon_code: "",
    coupon_description: "",

    // General Section
    discount_type: "FIXED_CART_DISCOUNT",
    coupon_amount: "",
    allow_free_shipping: false,
    coupon_expiry_date: "",

    // Usage Restrictions
    minimum_spend: "",
    maximum_spend: "",
    individual_use_only: false,
    exclude_sale_items: false,
    products: [],
    exclude_products: [],
    categories: [],
    exclude_categories: [],
    allowed_emails: "",

    // Usage Limit
    usage_limit_per_coupon: 0,
    limit_usage_to_x_items: 0,
    usage_limit_per_user: 0,

}


export const defaultCouponState: ICouponState = {
    coupons_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_coupon: {
        loading: LoadState.idle,
        data: defaultCoupon,
        error: null
    }
}
