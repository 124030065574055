import { Box, Stack, Typography, Divider } from '@mui/material'
import React from 'react'
import { FiUser } from 'react-icons/fi'
import { IReservation } from '../../../../redux/reservations'
import moment from 'moment'

interface IUserReservationCardProps {
    data: IReservation
    selected: boolean
    onClick: () => void
    onDoubleClick: () => void
}

export const UserReservationCard: React.FC<IUserReservationCardProps> = ({
    data,
    selected,
    onClick,
    onDoubleClick
}) => {
    return (
        <div>
            <Box
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                padding={1}
                gap={1}
                sx={{
                    background: selected ? "#363636" : "#272727",
                    cursor: "pointer",
                    borderRadius: 1,
                    minWidth: 26,
                    mb: 1
                }}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
            >
                <FiUser
                    style={{
                        border: "1px solid #FFFFFF",
                        backgroundColor: "#FFFFFF",
                        color: "#000000",
                        borderRadius: "50%",
                        padding: "0.3rem",
                        fontSize: "2rem",
                        minWidth: 26
                    }}
                />

                <Stack
                    width={"100%"}
                    direction={"row"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                    spacing={2}
                >
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 1
                        }}
                    >

                        <Typography variant="h5" fontWeight={"bold"}>
                            {data.user_name}
                        </Typography>
                        <Typography variant="h6" fontWeight={600}>{moment(data.reservation_date).format("DD-MM-YYYY")}</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: "100%",
                            margin: "0px !important",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 1
                        }}
                    >
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", gap: 1, }}>
                            <Typography variant="h6" fontWeight={600}>{`T${data.table_no}`}</Typography>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ borderColor: "white" }}
                            />

                            <Typography variant="h6" fontWeight={600}>{`${data.number_of_people}x`}</Typography>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ borderColor: "white" }}
                            />

                        </Box>

                        <Typography
                            variant="h6"
                            sx={{ minWidth: 50, fontWeight: 600, textAlign: "right" }}
                        >{moment(data.reservation_from_time).format("hh:mm A")}</Typography>
                    </Box>
                </Stack>

            </Box>
            {/* <Divider /> */}
        </div>
    )
}
