import { Box, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { fetchRevenueAndFoodCostListAsync } from "../Dashboard.actions";
import { IBarChart } from "../Dashboard.types";

const data = [
  {
    name: "Mon",
    uv: 5000,
    amt: 100,
  },
  {
    name: "Tue",
    uv: 4000,
    amt: 300,
  },
  {
    name: "Wed",
    uv: 3000,
    amt: 200,
  },
  {
    name: "Thur",
    uv: 2000,
    amt: 500,
  },
  {
    name: "Fri",
    uv: 2780,
    amt: 350,
  },
  {
    name: "Sat",
    uv: 1890,
    amt: 150,
  },
  {
    name: "Sun",
    uv: 2390,
    amt: 250,
  },
];

export const RevenueAndFoodCostBarChart: React.FC = () => {

  const [barChartData, setBarChartData] = useState<IBarChart[]>([])
  const [isAirline, setIsAirline] = useState<boolean>(false)

  useEffect(() => {
    fetchRevenueAndFoodCostListAsync().then((data) => {
      setBarChartData(data)
    })
  }, [isAirline])

  return (
    <Box
      border={"1px solid grey"}
      sx={{ padding: 2, borderRadius: 1, mt: 1 }}
    >
       <Stack direction="row" spacing={4} alignItems="center">
      <Typography variant={"h3"} fontWeight={"bold"} mb={5}>
        Accepted Orders
      </Typography>
      {/* <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Flight</Typography>
        <Switch checked={isAirline} onChange={(event) => setIsAirline(event.target.checked)} />
        <Typography>Airline</Typography>
      </Stack> */}
      </Stack>
      <BarChart
        width={600}
        height={210}
        data={barChartData}
        margin={{
          top: 0,
          right: 30,
          // left: -40,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid stroke="#ccc" strokeDasharray="6 3" /> */}
        <XAxis dataKey="name" />
        <YAxis />
        {/* <Tooltip
      contentStyle={{ color: "black" }}
      wrapperStyle={{ width: 100, backgroundColor: "blue" }}
      /> */}
        {/* <Legend
        width={100}

      /> */}
        <Bar
          dataKey="amt"
          fill="#C2E9DD"
          barSize={40}
        />
      </BarChart>
    </Box>

  );
};
