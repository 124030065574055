import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import PageContainer from '../../../../Components/PageContainer/PageContainer'
import { AvailableOrderCard } from '../AvailableOrderCard/AvailableOrderCard'

export const UberEatsNewOrdersTable = () => {
  return (
    <PageContainer title="Uber Eats In Progress Orders" description='inner page' >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant='h2'>New Orders</Typography>
          <Box sx={{
            width: '100%',
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }} >
            {[0, 1, 2, 3, 4].map((order, o_idx) => {
              return (
                <AvailableOrderCard order_status='NEW' />
              )
            })}
          </Box>
        </Grid>


        <Grid item xs={12} md={4}>
          <Typography variant='h2'>In Progress</Typography>
          <Box sx={{
            width: '100%',
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }} >
            {[0, 1, 2, 3, 4].map((order, o_idx) => {
              return (
                <AvailableOrderCard  order_status='IN_PROGRESS'/>
              )
            })}
          </Box>
        </Grid>


        <Grid item xs={12} md={4}>
          <Typography variant='h2'>Ready For Pickup</Typography>
          <Box sx={{
            width: '100%',
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }} >
            {[0, 1, 2, 3, 4].map((order, o_idx) => {
              return (
                <AvailableOrderCard order_status='READY_TO_PICKUP' />
              )
            })}
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  )
}
