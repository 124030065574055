import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Divider,
  Drawer,
} from "@mui/material";
import React from "react";
import { IRightPanelProps } from "./interfaces/IRightPanelProps";
import { CustomTypography } from "../formComponents";

export const RightPanel: React.FC<IRightPanelProps> = (props) => {
  const {
    open,
    heading,
    isWrappedWithForm = false,
    width = "35%",
    onFormSubmit,
    subHeading,
    actionButtons,
    onClose,
    showBackdrop,
  } = props;

  const content = () => {
    return (
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="space-between"
        height={"100%"}
      >
        {(heading || subHeading) && <Box flex={1}>
          <Box sx={{ padding: "0px 20px" }}>
            {heading && <CustomTypography variant="h2" fontWeight={"bold"}>
              {heading}
            </CustomTypography>}
            {subHeading && (
              <CustomTypography variant="h5">{subHeading}</CustomTypography>
            )}
          </Box>
        </Box>}
        <Box
          flex={5}
          sx={{ marginTop: 0, overflowY: "auto", padding: "0px 15px" }}
        >
          {props.children}
        </Box>
        {actionButtons && (
          <Box flex={1} sx={{ padding: "0px 15px" }}>
            <Divider sx={{ marginBottom: 3 }} />
            {actionButtons()}
          </Box>
        )}
      </Box>
    );
  };
  const wrappedWithForm = () => {
    if (isWrappedWithForm && onFormSubmit) {
      return (
        <form onSubmit={onFormSubmit} style={{ height: "100%" }}>
          {content()}
        </form>
      );
    }
    return content();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            md: "80%",
            lg: width,
          },
          padding: "5px 0px",
          height: "100vh",
          background: "#121212"
        },
      }}
      onClose={onClose}
    >
      {wrappedWithForm()}

      {showBackdrop && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showBackdrop}
        >
          <CircularProgress size={100} />
        </Backdrop>
      )}
    </Drawer>
  );
};
