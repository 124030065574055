import React from "react";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { ControlledCustomSelect, ExtendableSelectField, OutlinedTextField } from "../../../../Components/formComponents";
import { IInventory, upsertSingleInventoryItemAsync } from "../../../../redux/inventory";
import { useAppDispatch } from "../../../../redux";
import { useFormik } from "formik";
import { CustomDatePicker } from "../../../../Components/formComponents/CustomDatePicker";
import { inventory_category_list, inventory_vendor_list } from "../Inventory.constants";

export interface IUpsertInventoryDialogProps {
  open: boolean;
  inventory: IInventory
  onClose: () => void;
}

export const UpsertInventoryDialog: React.FC<IUpsertInventoryDialogProps> = (
  props
) => {
  const { open, inventory, onClose } = props;
  const dispatch = useAppDispatch();

  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: { ...inventory },
    validate: (values) => {
      const errors: any = {};
      if (!values.product_name) {
        errors.product_name = "*This field is required.";
      }
      if (!values.product_category) {
        errors.product_category = "*This field is required.";
      }
      if (!values.vender) {
        errors.vender = "*This field is required.";
      }
      if (!values.quantity) {
        errors.quantity = "*This field is required.";
      }
      if (!values.product_cost_price) {
        errors.product_cost_price = "*This field is required.";
      }
      if (!values.restock_date) {
        errors.restock_date = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSingleInventoryItemAsync(values)
      ).finally(() => {
        setSaveLoading(false);
        onClose()
      });
    },
  });
  return (
    <Dialog
      open={open}
      title="Add/Edit Inventory"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
          disabled: saveLoading
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OutlinedTextField
              id="product_name"
              label="Item Name"
              value={values.product_name}
              onChange={handleChange}
              error={errors.product_name}
            />
          </Grid>
          <Grid item xs={12}>
            <ExtendableSelectField
              label="Item Category"
              options={inventory_category_list}
              value={values.product_category}
              onChange={(newValue) => {
                setFieldValue("product_category", newValue);
              }}
              error={errors.product_category}
            />
          </Grid>
          <Grid item xs={12}>
            <ExtendableSelectField
              label="Vendor"
              options={inventory_vendor_list}
              value={values.vender}
              onChange={(newValue) => {
                setFieldValue("vender", newValue);
              }}
              error={errors.vender}
            />

          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              type="number"
              id="quantity"
              label="Quantity"
              value={values.quantity}
              onChange={handleChange}
              error={errors.quantity}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Unit"
              name="product_unit"
              fullWidth
              options={[
                { value: "lb", label: "lb" },
                { value: "oz", label: "oz" },
                { value: "kg", label: "kg" },
                { value: "g", label: "g" },
                { value: "bx", label: "bx" },
                { value: "pc", label: "pc" },
              ]}
              placeholder="Select Unit"
              value={values.product_unit}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              type="number"
              id="product_cost_price"
              label="Product Cost Price"
              value={values.product_cost_price}
              onChange={handleChange}
              error={errors.product_cost_price}
            />

          </Grid>
          <Grid item xs={12}>
            <CustomDatePicker
            label="Restock Date"
              fullWidth
              fieldName="restock_date"
              value={values.restock_date}
              onChange={(newValue, formattedDate) => {
                setFieldValue("restock_date", formattedDate);
              }}
              error={errors.restock_date}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDatePicker
              fullWidth
              label="Last Delivery Date"
              fieldName="last_delivery_Date"
              value={values.last_delivery_Date}
              onChange={(newValue, formattedDate) => {
                setFieldValue("last_delivery_Date", formattedDate);
              }}
              error={errors.restock_date}
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
