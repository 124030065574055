
export const isNotNull = (value: string | number | null | undefined) => {
  return Number(value) || 0;
};

export const calculateDiscount = (amount: number, tax: number) => {
  return (amount * tax) / 100;
};

export const calculateDiscountV2 = (
  amount: string | number | null | undefined,
  tax: string | number | null | undefined,
) => {
  let discount = (isNotNull(amount) * isNotNull(tax)) / 100;
  let remainingAmount = isNotNull(amount) - discount;
  return { discount, remainingAmount };
};

export const calculatePercentage = (
  amount: string | number | null | undefined,
  totalAmount: string | number | null | undefined,
) => {
  return (100 - (isNotNull(amount) / isNotNull(totalAmount)) * 100).toPrecision(
    10,
  );
};

export const calculateTax = (amount: number, tax: number) => {
  return (amount * tax) / 100;
};

export const calculateTaxV2 = (
  amount: string | number | null | undefined,
  tax: string | number | null | undefined,
) => {
  return (isNotNull(amount) * isNotNull(tax)) / 100;
};

export function displayPriceFormat(value: string | number | null) {
  return isNotNull(value)
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
