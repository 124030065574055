import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultCouponState } from "./coupon-codes.state";
import { fetchAllCouponsListAsync, fetchSingleCouponAsync } from "./coupon-codes.actions";
import { ICoupon } from "./coupon-codes.types";

const couponsSlice = createSlice({
    initialState: defaultCouponState,
    name: "coupons",
    reducers: {
        clearCouponsStateSync: (state) => {
            return defaultCouponState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllCouponsListAsync.pending, (state, action) => {
            state.coupons_list.loading = LoadState.pending
        })
        builder.addCase(fetchAllCouponsListAsync.fulfilled, (state, action: PayloadAction<{ data: ICoupon[], count: number }>) => {
            state.coupons_list.loading = LoadState.succeeded
            state.coupons_list.data = action.payload.data
            state.coupons_list.count = action.payload.count
            state.coupons_list.error = null
        })
        builder.addCase(fetchAllCouponsListAsync.rejected, (state, action) => {
            state.coupons_list.error = action.error.message as string
        })




        builder.addCase(fetchSingleCouponAsync.pending, (state, action) => {
            state.single_coupon.loading = LoadState.pending
        })
        builder.addCase(fetchSingleCouponAsync.fulfilled, (state, action) => {
            state.single_coupon.loading = LoadState.succeeded
            state.single_coupon.data = action.payload
            state.single_coupon.error = null
        })
        builder.addCase(fetchSingleCouponAsync.rejected, (state, action) => {
            state.single_coupon.error = action.error.message as string
        })
    },
});

export const couponsReducer = couponsSlice.reducer;
export const { clearCouponsStateSync } = couponsSlice.actions;
