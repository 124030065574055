import React from "react";
// import { DataTable, ITableColumn } from "../../../Components/Tables/Table";
import { Avatar } from "@mui/material";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../Components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../Components/Table/DataTable";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { IInventory, clearInventoryState, fetchInventoryWithArgsAsync } from "../../../redux/inventory";
import { StandardTableActions } from "../../../Components/StandardTableActions/StandardTableActions";
import { formatDateWithTime } from "../../../helpers";
import { UpsertInventoryDialog } from "./dialogs/UpsertInventoryDialog";

export const InventoryTable = () => {
  const {
    data: inventoryList,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.inventory.inventory.inventory_list);
  const dispatch = useAppDispatch();


  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });
  const [openUpsertDialog, setOpenUpsertDialog] = React.useState<IInventory | null>(null);


  const dataTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: 25,
    isDataLoading: false,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },

    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IInventory) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenUpsertDialog(row)}
            />
          );
        },
      },
      {
        key: "product_name",
        headerName: "Item Name",
        fieldName: "product_name",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "category_name",
        headerName: "Item Category",
        fieldName: "category_name",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "Vendor",
        headerName: "Vendor",
        fieldName: "Vendor",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
     
      {
        key: "quantity",
        headerName: "Quantity",
        fieldName: "quantity",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "product_cost_price",
        headerName: "Product Cost Price",
        fieldName: "product_cost_price",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "insert_ts",
        headerName: "Last Delivery Date",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: RenderType.DATE_DARK_COLOR,
        exportCellWidth: 25,
        onExportRender: (values) => {
          return formatDateWithTime(values.value);
        },
      },
    ],

    items: inventoryList,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };


  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearInventoryState());
    };
  }, []);

  const fetchList = () => {
    dispatch(
      fetchInventoryWithArgsAsync({
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
      }),
    );
  };

  return (
    <>
      <DataTable {...dataTableProps} />
      {openUpsertDialog && <UpsertInventoryDialog open={true} inventory={openUpsertDialog} onClose={() => setOpenUpsertDialog(null)} />}
    </>
  );
};
