import { Avatar, Button, Stack, Typography } from '@mui/material';
import React from 'react'
import { StandardTableActions } from '../../../../Components/StandardTableActions/StandardTableActions';
import { DataTable } from '../../../../Components/Table/DataTable';
import { IDataTableProps, MobileLogoRenderType, RenderType } from '../../../../Components/Table/interfaces/IDataTableProps';
import { useAppSelector, IStoreState, useAppDispatch } from '../../../../redux';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import { CustomButton } from '../../../../Components/CustomButton';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { ICompany, clearCompanyListSync, fetchCompanyListAsync } from '../../../../redux/settings/company';
import { COMPANY_OUTLETS_ROUTE, OUTLET_REGISTERS_ROUTE } from '../CompanyOutlets.constants';
import { IOutletRegister, clearOutletRegisterListSync, fetchRegistersListForSingleOutletAsync } from '../../../../redux/settings/outlet-registers';

export const OutletRegistersTable = () => {
    const { uuid: outlet_uuid, register_uuid } = useParams<{ uuid?: string, register_uuid?: string }>();
    const {
        data: registersList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.registers.registers_list);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 10,
    });


    const dataTableProps: IDataTableProps = {
        isPagination: true,
        rowsPerPageOptions: 25,
        isDataLoading: false,
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },

        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IOutletRegister) => {
                    return (
                        <StandardTableActions
                            onEditClick={() => navigate(`${COMPANY_OUTLETS_ROUTE}/${outlet_uuid}/registers/manage/${row.register_uuid}`)}
                        />
                    );
                },
            },
            {
                key: "register_name",
                headerName: "Register Name",
                fieldName: "register_name",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "register_type",
                headerName: "Register Email",
                fieldName: "company_email",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "location_id",
                headerName: "OUtlet",
                fieldName: "location_id",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
        ],

        items: registersList,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };


    React.useEffect(() => {
        if(!outlet_uuid) return
        dispatch(
            fetchRegistersListForSingleOutletAsync({
                outlet_uuid,
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
            }),
        );
    }, [outlet_uuid, pagination]);

    React.useEffect(() => {
        return () => {
            dispatch(clearOutletRegisterListSync());
        };
    }, []);


    return (
        <PageContainer title="Registers" description='inner page' >
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='h2'>Registers</Typography>
                    {/* <ControlledCustomSelect
                        label="Search by Users"
                        options={[
                            { label: "All", value: "ALL" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />
                    <ControlledCustomSelect
                        label="Search by Date"
                        options={[
                            { label: "All", value: "ALL" },
                            { label: "Today", value: moment().format("yyyy-MM-DD") },
                            { label: "Yesterday", value: moment().subtract(1, "day").format("yyyy-MM-DD") },
                            { label: "Custom", value: "CUSTOM" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />

                    {customDateDialog && <CustomDatePicker
                        label="Select Date"
                        fullWidth
                        fieldName="reservation_date"
                        value={customDate || ""}
                        onChange={(newValue, formattedDate) => {
                            setCustomDate(formattedDate);
                        }}
                    />} */}
                </Stack>
                <CustomButton
                    variant="contained"
                    color="primary"
                    sx={{
                        display: "flex",
                        marginLeft: "auto",
                    }}
                    onClick={() => navigate(`${COMPANY_OUTLETS_ROUTE}/${outlet_uuid}/registers/manage`)}
                >
                    Add Register
                </CustomButton>
            </Stack>
            <DataTable {...dataTableProps} />
        </PageContainer>
    );
}
