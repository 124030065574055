import { Alert, AlertProps, AlertTitle, IconButton, Snackbar } from '@mui/material'
import React from 'react'
import { IStoreState, useAppDispatch, useAppSelector } from '../../redux';
import { closeErrorDialog, closeSnackBarDialog } from '../../redux/common';
import CloseIcon from '@mui/icons-material/Close';

export const LoadingAndErrors = () => {
    const dispatch = useAppDispatch();
    const { errorVisible, snackbarVisible } = useAppSelector((storeState: IStoreState) => storeState.common);


    const handleErrorCloseClick = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return
        dispatch(closeErrorDialog())
    }


    const handleSnackbarCloseClick = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return
        dispatch(closeSnackBarDialog())
    }

    const SnackbarType = snackbarVisible ? snackbarVisible.type : "default"



    if (snackbarVisible && snackbarVisible.type === "default") {
        return (
            <Snackbar
                open={true}
                anchorOrigin={{
                    horizontal: snackbarVisible.position?.horizontal ? snackbarVisible.position.horizontal : "left",
                    vertical: snackbarVisible.position?.vertical ? snackbarVisible.position.vertical : "bottom",
                }}
                autoHideDuration={5000}
                onClose={handleSnackbarCloseClick}
                message={snackbarVisible.content}
                action={
                    <>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleSnackbarCloseClick}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        )
    }

    if (snackbarVisible && snackbarVisible.type !== "default") {
        return (
            <Snackbar
                open={true}
                anchorOrigin={{
                    horizontal: snackbarVisible.position?.horizontal ? snackbarVisible.position.horizontal : "left",
                    vertical: snackbarVisible.position?.vertical ? snackbarVisible.position.vertical : "bottom",
                }}
                autoHideDuration={5000}
                onClose={handleSnackbarCloseClick}
            >
                <Alert
                    onClose={handleSnackbarCloseClick}
                    severity={SnackbarType as AlertProps["severity"]}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarVisible.title && <AlertTitle>Info</AlertTitle>}
                    {snackbarVisible.content}
                </Alert>
            </Snackbar>
        )
    }

    return  <></>
}
