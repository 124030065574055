import React, { useState } from "react";
import {
  Fab,
  Drawer,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { changeCustomizerDarkModeSync, changeCustomizerDirSync, changeCustomizerNavbarBgSync } from "../../../redux/customizer";
import { CustomRadio } from "../../../Components/formComponents";

const SidebarWidth = "320px";

export const Customizer = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const customizer = useAppSelector(
    (state: IStoreState) => state.customizer,
  );
  const dispatch = useAppDispatch();
  const thColors = [
    {
      id: 1,
      bgColor: "#1a9bfc",
      disp: "BLUE_THEME",
    },
    {
      id: 2,
      bgColor: "#00cec3",
      disp: "GREEN_THEME",
    },
    {
      id: 3,
      bgColor: "#7352ff",
      disp: "PURPLE_THEME",
    },
    {
      id: 5,
      bgColor: "#1e4db7",
      disp: "INDIGO_THEME",
    },
    {
      id: 6,
      bgColor: "#fb9678",
      disp: "ORANGE_THEME",
    },
    {
      id: 7,
      bgColor: "rgb(24, 144, 255)",
      disp: "LIGHT_BLUE_THEME",
    },
    {
      id: 7,
      bgColor: "#800000",
      disp: "MAROON_THEME",
    },
    {
      id: 9,
      bgColor: "#F56942",
      disp: "ROCKWORTH_THEME",
    },
  ];
  return (
    <div>
      <Tooltip title="Settings">
        <Fab
          color="primary"
          aria-label="settings"
          sx={{ position: "fixed", right: "15px", bottom: "15px" }}
          onClick={() => setShowDrawer(true)}
        >
          <FeatherIcon icon="settings" />
        </Fab>
      </Tooltip>
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        PaperProps={{
          sx: {
            width: SidebarWidth,
          },
        }}
      >
        <Box p={2}>
          <Typography variant="h3">Settings</Typography>
        </Box>
        <Divider />
        <Box p={2}>
          {/* ------------ Dark light theme setting ------------- */}
          <Typography variant="h4" gutterBottom>
            Theme Option
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="theme"
              name="theme"
              value={customizer.activeMode}
              onChange={(event) => dispatch(changeCustomizerDarkModeSync(event.target.value))}
            >
              <FormControlLabel
                value="light"
                control={<CustomRadio />}
                label="Light"
              />
              <FormControlLabel
                value="dark"
                control={<CustomRadio />}
                label="Dark"
              />
            </RadioGroup>
          </FormControl>
          <Box pt={3} />
          {/* ------------ RTL theme setting -------------*/}
          <Typography variant="h4" gutterBottom>
            Theme Direction
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="themedir"
              name="themedir"
              value={customizer.activeDir}
              onChange={(event) => dispatch(changeCustomizerDirSync(event.target.value))}
            >
              <FormControlLabel
                value="ltr"
                control={<CustomRadio />}
                label="LTR"
              />
              <FormControlLabel
                value="rtl"
                control={<CustomRadio />}
                label="RTL"
              />
            </RadioGroup>
          </FormControl>
          <Box pt={3} />
          {/* ------------ Navbar Color setting ------------- */}
          <Typography variant="h4" gutterBottom>
            Theme Colors
          </Typography>
          {thColors.map((thcolor) => (
            <Tooltip title={thcolor.disp} placement="top" key={thcolor.id}>
              <Fab
                color="primary"
                style={{ backgroundColor: thcolor.bgColor }}
                sx={{ marginRight: "3px", marginBottom: 1 }}
                size="small"
                onClick={() => dispatch(changeCustomizerNavbarBgSync(thcolor.disp))}
                aria-label={thcolor.bgColor}
              >
                {customizer.activeTheme === thcolor.disp ? (
                  <FeatherIcon icon="check" size="16" />
                ) : (
                  ""
                )}
              </Fab>
            </Tooltip>
          ))}

          <Box pt={3} />
        </Box>
      </Drawer>
    </div>
  );
};
