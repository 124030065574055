import React from 'react'
import { List, ListItemButton, ListItemText, Divider, DialogContent, Box, Typography, ListItem, IconButton } from '@mui/material'
import { IUberEatsOrder } from '../../../../../redux/uber-eats'
import { IOrderScreenType } from '../ViewOrderDialog.types'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const UberEatsHelpList = ["Delay", "Cancel"]


interface IHelpListViewScreenProps {
    order_info: IUberEatsOrder,
    onBackIconClick: () => void

    onListClick: (type: IOrderScreenType) => void,
}

export const HelpListViewScreen: React.FC<IHelpListViewScreenProps> = ({
    order_info,
    onListClick,
    onBackIconClick
}) => {
    return (
        <>
            <Box sx={{
                minHeight: 70,
                width: '100%',
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
                background: "#292929",
                paddingInline: 2,
                paddingBlock: 2,
                borderRadius: 1,
                cursor: "pointer"
            }}
            >
                <IconButton sx={{ p: .75 }} onClick={onBackIconClick}>
                    <ArrowBackIcon sx={{ height: 26, width: 26 }} />
                </IconButton>
                <Typography variant='h2'>
                    {`Help with order ${order_info.display_id}`}
                </Typography>

            </Box >

            <DialogContent dividers sx={{ minHeight: 390}}>
                <List sx={{ p: 0 }} >
                    {UberEatsHelpList.map((helpText, h_idx) => {
                        return (
                            <ListItem
                                key={h_idx}
                                secondaryAction={
                                    <ArrowForwardIosIcon />
                                }
                                sx={{
                                    borderBottom: "1px solid #454545",
                                    cursor: "pointer",
                                    minHeight: 60
                                }}
                                onClick={() => onListClick(helpText.toUpperCase() as IOrderScreenType)}
                            >
                                <Typography variant='h3'>
                                    {helpText}
                                </Typography>
                                {/* <ListItemButton>
                                    <ListItemText
                                        sx={{ fontSize: 18 }}
                                        primary={help}
                                    />
                                </ListItemButton> */}
                            </ListItem>
                        )
                    })}
                </List>
            </DialogContent>
        </>
    )
}
