import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useAuthProvider } from "../../../providers";


export const ProfileDropdown: React.FC = () => {
  const { authInfo } = useAuthProvider();
  return (
    <Box>
      <Box
        sx={{
          pb: 3,
          mt: 3,
        }}
      >
        <Box display="flex" alignItems="center">
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            width="15%"
            height={"48px"}
            borderRadius={"100%"}
            sx={{ backgroundColor: "#000" }}
          >
            <Typography variant="h2" color="#fff">
              {authInfo.name.charAt(0).toUpperCase()}
            </Typography>
          </Box>
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                lineHeight: "1.235",
              }}
            >
              {authInfo.name}
            </Typography>
            <Typography
              color="textSecondary"
              variant="h6"
              fontWeight="400"
              textTransform={"capitalize"}
            >
              {authInfo.user.role_id}
              {/* {user.role.replace(/_/g, " ").toLocaleLowerCase()} */}
            </Typography>
            {/* <Box display="flex" alignItems="center">
            <Typography
              color="textSecondary"
              display="flex"
              alignItems="center"
              sx={{
                color: (theme) => theme.palette.grey.A200,
                mr: 1,
              }}
            >
              <FeatherIcon icon="mail" width="18" />
            </Typography>
            <Typography color="textSecondary" variant="h6">
              info@flexy.com
            </Typography>
          </Box> */}
          </Box>
        </Box>
      </Box>
      <Divider
        style={{
          marginTop: 0,
          marginBottom: 0,
        }}
      />
    </Box>
  );
};
