import { ICustomizerState } from "./customizer.types";


export const defaultCustomizerState: ICustomizerState = {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "ROCKWORTH_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
}