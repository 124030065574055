import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultProductCustomizationState } from "./customization.state";
import { fetchCustomizationListWithArgsAsync, fetchCustomizedModifiersListAsync, fetchProductModifiersListWithArgsAsync, fetchSingleModifiersAsync } from "./customization.actions";

const productCustomizationSlice = createSlice({
    initialState: defaultProductCustomizationState,
    name: "product-customization",
    reducers: {
        clearProductCustomizationsListSync: (state) => {
            state.customizations_list = defaultProductCustomizationState["customizations_list"]
        },
        clearSingleProductCustomizationStateSync: (state) => {
            state.single_customization = defaultProductCustomizationState["single_customization"]
        },
        clearProductModifierListSync: (state) => {
            state.product_modifiers_list = defaultProductCustomizationState["product_modifiers_list"]
        },
        clearSingleProductModifierStateSync: (state) => {
            state.single_product_modifier = defaultProductCustomizationState["single_product_modifier"]
        },
        clearProductCustomizationState: (state) => {
            return defaultProductCustomizationState
        },
    },
    extraReducers: (builder) => {

        //############################# Multiple Customizations ######################################
        builder.addCase(fetchCustomizationListWithArgsAsync.pending, (state, action) => {
            state.customizations_list.loading = LoadState.pending
        })
        builder.addCase(fetchCustomizationListWithArgsAsync.fulfilled, (state, action) => {
            state.customizations_list.loading = LoadState.succeeded
            state.customizations_list.data = action.payload.data
            state.customizations_list.totalRecords = action.payload.recordCount
            state.customizations_list.error = null
        })
        builder.addCase(fetchCustomizationListWithArgsAsync.rejected, (state, action) => {
            state.customizations_list.error = action.error.message as string
        })


        //#################################### Single customization ##############################################
        // builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.pending
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.succeeded
        //     state.single_airline_menu.data = action.payload
        //     state.single_airline_menu.error = null
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
        //     state.single_airline_menu.error = action.error.message as string
        // })



        //############################# Multiple Product Modifier List ######################################
        builder.addCase(fetchProductModifiersListWithArgsAsync.pending, (state, action) => {
            state.product_modifiers_list.loading = LoadState.pending
        })
        builder.addCase(fetchProductModifiersListWithArgsAsync.fulfilled, (state, action) => {
            state.product_modifiers_list.loading = LoadState.succeeded
            state.product_modifiers_list.data = action.payload.data
            state.product_modifiers_list.totalRecords = action.payload.recordCount
            state.product_modifiers_list.error = null
        })
        builder.addCase(fetchProductModifiersListWithArgsAsync.rejected, (state, action) => {
            state.product_modifiers_list.error = action.error.message as string
        })

        //############################# Multiple Customized Product Modifier List ######################################
        builder.addCase(fetchCustomizedModifiersListAsync.pending, (state, action) => {
            state.product_modifiers_list.loading = LoadState.pending
        })
        builder.addCase(fetchCustomizedModifiersListAsync.fulfilled, (state, action) => {
            state.product_modifiers_list.loading = LoadState.succeeded
            state.product_modifiers_list.data = action.payload.data
            state.product_modifiers_list.totalRecords = action.payload.recordCount
            state.product_modifiers_list.error = null
        })
        builder.addCase(fetchCustomizedModifiersListAsync.rejected, (state, action) => {
            state.product_modifiers_list.error = action.error.message as string
        })

        // #################################### Single Modifier ##############################################
        builder.addCase(fetchSingleModifiersAsync.pending, (state, action) => {
            state.single_product_modifier.loading = LoadState.pending
        })
        builder.addCase(fetchSingleModifiersAsync.fulfilled, (state, action) => {
            state.single_product_modifier.loading = LoadState.succeeded
            state.single_product_modifier.data = action.payload
            state.single_product_modifier.error = null
        })
        builder.addCase(fetchSingleModifiersAsync.rejected, (state, action) => {
            state.single_product_modifier.error = action.error.message as string
        })
    },
});

export const productCustomizationReducer = productCustomizationSlice.reducer;
export const {
    clearProductCustomizationState,
    clearProductCustomizationsListSync,
    clearProductModifierListSync,
    clearSingleProductCustomizationStateSync,
    clearSingleProductModifierStateSync
} = productCustomizationSlice.actions;
