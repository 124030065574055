import { createSlice } from "@reduxjs/toolkit";
import { defaultPosBankInfoState } from "./bank-info.state";
import { getPosBankInfoListFormLocalStore, setPosBankInfoListInLocalStore } from "./bank-info.localstore";
import { fetchPosBankInfoListWithArgsAsync } from "./bank-info.actions";
import { LoadState } from "../../../constants/enum";



const posBankInfoSlice = createSlice({
    initialState: defaultPosBankInfoState,
    name: "pos-bank-info",
    reducers: {
        clearPosBankInfoState: (state) => {
            return defaultPosBankInfoState
        },
    },
    extraReducers: (builder) => {
        // ############################# Multiple Bank Info ######################################
        builder.addCase(fetchPosBankInfoListWithArgsAsync.pending, (state, action) => {
            state.bank_info_list.loading = LoadState.pending
        })
        builder.addCase(fetchPosBankInfoListWithArgsAsync.fulfilled, (state, action) => {
            state.bank_info_list.loading = LoadState.succeeded
            state.bank_info_list.data = action.payload.data
            state.bank_info_list.totalRecords = action.payload.recordCount
            state.bank_info_list.error = null
        })
        builder.addCase(fetchPosBankInfoListWithArgsAsync.rejected, (state, action) => {
            state.bank_info_list.error = action.error.message as string
        })


        //#################################### Single Product Category ##############################################
        // builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.pending
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.succeeded
        //     state.single_airline_menu.data = action.payload
        //     state.single_airline_menu.error = null
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
        //     state.single_airline_menu.error = action.error.message as string
        // })
    },
});

export const posBankInfoReducer = posBankInfoSlice.reducer;
export const { clearPosBankInfoState } = posBankInfoSlice.actions;
