import React, { } from 'react'
import { Add, Delete } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { OutlinedTextField } from "../../../../Components/formComponents";
import { produce } from 'immer';
import { IProductCustomization, defaultProductCustomization } from '../../../../redux/inventory';
import { CustomizationAutoSearch } from '../../Customization/AutoSearch/CustomizationAutoSearch';

export interface IProductCustomizationTableProps {
  data: IProductCustomization[];
  onChange: (data: IProductCustomization[]) => void;
}
export const ProductCustomizationTable: React.FC<IProductCustomizationTableProps> = ({
  data: customizationList,
  onChange,

}) => {

  const handleInsertNewCustomization = (index: number) => {
    const newValues = produce(customizationList, (draftItems) => {
      draftItems.splice(index + 1, 0, defaultProductCustomization);
    });
    onChange(newValues);
  };

  const handleDeleteNewCustomization = (currentIndex: number) => () => {
    if (customizationList.length > 1) {
      const newValues = produce(customizationList, (draftItems) => {
        draftItems.splice(currentIndex, 1);
      });
      onChange(newValues);
    }
  };


  const handleCustomizationChange = (index: number) => (data: IProductCustomization) => {
    const newValues = produce(customizationList, (draftItems) => {
      draftItems[index] = data;
    });
    onChange(newValues);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table >
          <TableHead sx={{ background: (theme) => theme.palette.grey[800] }}>
            <TableRow>
              <TableCell sx={{ borderBottom: "none", width: 80}} />
              <TableCell sx={{ borderBottom: "none", width: 180 }}>
                <Typography variant="h5" fontWeight={600}>
                  Customization Name
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }}>
                <Typography variant="h5" fontWeight={600}>
                  Options
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customizationList.map((customization, rowIndex) => {
              return (
                <TableRow>
                  <TableCell sx={{ width: 80 }}>
                    <Box display={"flex"}>
                      <IconButton
                        color="error"
                        disabled={
                          customizationList.length === 1
                        }
                        onClick={handleDeleteNewCustomization(rowIndex)}
                      >
                        <Delete />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleInsertNewCustomization(rowIndex)}
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </TableCell>

                  <TableCell sx={{ width: 180 }}>
                    <CustomizationAutoSearch
                      name="product_category_name"
                      label="Customization Name"
                      value={{
                        customization_uuid: customization.customization_uuid,
                        customization_name: customization.customization_name,
                      }}
                      onSelect={(newValue) => handleCustomizationChange(rowIndex)(newValue)}
                    />
                  </TableCell>

                  <TableCell>
                    <OutlinedTextField
                      label="Options"
                      value={customization.customization_options}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};