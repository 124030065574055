import React, {  } from "react";
import { Dialog } from "../../../../Components/Dialogs/Dialog";


export interface IViewImageDialogProps {
    open: boolean;
    imgSrc: string
    onClose: () => void;
}


export const ViewImageDialog: React.FC<IViewImageDialogProps> = (
    props
) => {
    const { open, imgSrc, onClose } = props;


    return (
        <Dialog
            open={open}
            title="View Image"
            size="sm"
            onClose={onClose}
            actions={[
                {
                    type: "button",
                    variant: "outlined",
                    label: "Cancel",
                    onClick: onClose,
                },
            ]}
        >
            <img alt="Shift User" src={imgSrc} height="100%" width="100%" />
        </Dialog>
    );
};
