import React, { useState } from 'react'
import { StandardCard } from '../../../Components/Card'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../redux';
import { clearFlightMenuState, fetchSingleFlightMenuAsync } from '../../../redux/airlineAndFlights';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomDivider } from '../../../Components/CustomDivider/CustomDivider';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { CustomSquareButton } from '../../../Components/CustomButton';
import { POS_ORDERS_ROUTE } from '../MenuAndOrders.constants';
import { clearSingleOrderStateSync, fetchSingleOrderAsync } from '../../../redux/orders';
import { OrderPrintDialog } from './OrderPrintDialog';
import { RUPEE_SYMBOL } from '../../../constants/constants';

export const ViewSinglePOSOrder = () => {
    const { uuid: order_uuid } = useParams() as {
        uuid?: string | null;
    };
    const {
        data: singleOrderInfo,
    } = useAppSelector((storeState: IStoreState) => storeState.orders.single_order);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [openPrintDialog, setOpenPrintDialog] = useState(false)

    React.useEffect(() => {
        if (!order_uuid) return
        dispatch(fetchSingleOrderAsync(order_uuid));

    }, [order_uuid]);

    React.useEffect(() => {
        return () => {
            dispatch(clearSingleOrderStateSync());
        };
    }, []);


    const handlePrintOrderClick = () => {
        setOpenPrintDialog(true)
      }

    return (
        <StandardCard
            heading={singleOrderInfo.order_type || ""}
            rightHeading={`Order No. ${singleOrderInfo.order_number}`}
            sx={{
                // mt: 2,
                display: "block",
                pageBreakInside: "avoid",
                breakInside: "avoid",
                // width: "32%"
            }}
        >
            <CustomDivider borderColor="white" />

            <Table>
                <TableHead>
                    <TableCell
                        sx={{
                            border: "none",
                            p: 1,
                        }}
                    >
                        <Typography fontWeight={"bold"}>QT</Typography>
                    </TableCell>
                    <TableCell
                        sx={{
                            border: "none",
                            p: 1,
                        }}
                    >
                        <Typography fontWeight={"bold"}>Items</Typography>
                    </TableCell>
                    <TableCell
                        sx={{
                            border: "none",
                            p: 1,
                        }}
                    >
                        <Typography fontWeight={"bold"}>Price</Typography>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {singleOrderInfo.menu_items.map((product, Item_idx) => {
                        return (
                            <>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        border: "none",
                                        p: 1,
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>{product.quantity}</Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "none",
                                        p: 1,
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>{product.product_name}</Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "none",
                                        p: 1,
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>{`${RUPEE_SYMBOL}${product.product_price}`}</Typography>
                                </TableCell>
                            </TableRow>
                            {(product.options || []).map((option, option_idx) => {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          p: 1,
                                        }}
                                      >
                                        {/* <Typography fontWeight={"bold"}>{Item_idx + 1}</Typography> */}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          p: 1,
                                        }}
                                      >
                                        <Typography >{`${option.modifier_name} [add-onn]`}</Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          p: 1,
                                        }}
                                      >
                                        <Typography fontWeight={"bold"} >{`${RUPEE_SYMBOL}${option.modifier_price}`}</Typography>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              })}
                            </>
                        )
                    })}

                    <TableRow sx={{ borderTop: "2px solid gray" }}>
                        <TableCell
                            sx={{
                                border: "none",
                                p: 1,
                            }}
                        >
                            <Typography fontWeight={"bold"}>Subtotal</Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "none",
                                p: 1,
                            }}
                        >
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "none",
                                p: 1,
                            }}
                        >
                            <Typography fontWeight={"bold"}>{`${RUPEE_SYMBOL}${singleOrderInfo.sub_total}`}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Stack gap={1} mt={2} direction={"row"}>
                <CustomSquareButton title="Print" onClick={handlePrintOrderClick}  />
                <CustomSquareButton title="Done" />
                <CustomSquareButton title="Close" onClick={() => navigate(POS_ORDERS_ROUTE)} />
            </Stack>

            {openPrintDialog && <OrderPrintDialog
                open={openPrintDialog}
                orderUUID={singleOrderInfo.order_uuid || ""}
                onClose={() => setOpenPrintDialog(false)} />}
        </StandardCard>
    )
}
