import { Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

export const CurrentDateTime = () => {

    const [currentTime, setCurrentTime] = useState<string>("")

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().format("dddd, D MMM YYYY - h:mm:ss A"))
        }, 1000)

        return () => clearInterval(intervalId);
    }, [])

    return (
        <Typography variant="h4" component="p">
            {currentTime}
        </Typography>
    )
}
