import React from 'react'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { clearProductCustomizationState, clearProductModifierListSync, fetchCustomizedModifiersListAsync, fetchProductModifiersListWithArgsAsync } from '../../../../redux/inventory';
import { Button, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IModifiersTableProps {
  customizationUUID: string;
  onCreateNew: () => void;
  onEditClick: (uuid: string) => void;
}
export const ModifiersTable: React.FC<IModifiersTableProps> = ({
  customizationUUID,
  onCreateNew,
  onEditClick
}) => {

  const {
    data: product_modifiers_list,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.inventory.customization.product_modifiers_list);

  const dispatch = useAppDispatch();


  React.useEffect(() => {
    dispatch(
      fetchCustomizedModifiersListAsync(customizationUUID),
    );
  }, [customizationUUID]);

  React.useEffect(() => {
    return () => {
      dispatch(clearProductModifierListSync());
    };
  }, []);


  return (
    <>
      <Stack mb={2} mt={4} direction="row" justifyContent="space-between" alignItems="flex-end" >
        <Typography variant='h3'>Customization Options</Typography>
        <Button variant='contained' onClick={onCreateNew}>Create New Option</Button>
      </Stack>

      <Stack direction="column" spacing={2}>
        {product_modifiers_list.map((modifier, idx) => {
          return (
            <Stack direction="row" justifyContent="space-between" alignItems="center"
              sx={{
                background: "#272B2C",
                borderRadius: 1,
                padding: 2,
                cursor: 'pointer'
              }}
              onClick={() => onEditClick(modifier.modifier_uuid || "")}
            >
              <Stack direction="column" justifyContent="center" alignItems="flex-start">
                <Typography variant='h3'>{modifier.modifier_name}</Typography>
                <Typography variant='h6'>{`₹${modifier.modifier_price}`}</Typography>
              </Stack>

              <CloseIcon sx={{ cursor: "pointer", fontSize: 36 }} />
            </Stack>
          )
        })}
      </Stack>
    </>
  )
}
