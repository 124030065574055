import { LoadState } from "../../../constants/enum";
import { ISecurityRoleGroup, ISecurityState } from "./security.types";

export const defaultSecurityRoleGroup: ISecurityRoleGroup = {
    role_group_uuid: null,
    role_group: "",
    status: "ACTIVE",
};

export const defaultSecurityState: ISecurityState = {
    groups: {
        roleName: null,
        status: "ACTIVE",
        group: { modules: {} },
        role_group: "",
        loading: LoadState.idle,
        error: null,
    },
    security_roles_list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.idle,
        error: null
    },
    approval: {
        list: [],
        loading: LoadState.idle,
        approvalData: {
            approval_count_uuid: null,
            table_name: "",
            link_table: null,
            link_column: null,
            approval_hierarchy: [[{ type: "", uuid: "" }]],
            status: "ACTIVE",
            approval_raise_status: "",
            previous_status: "",
            next_status: "",
        },
        approvalLoading: LoadState.idle,
        totalRecords: 0,
        error: null,
    },
    roleGroups: {
        data: [],
        totalRecords: 0,
        loading: LoadState.idle,
        error: null
    },
};
