import { LoadState } from "../../../constants/enum"
import { IInventory, IInventoryState } from "./inventory.types"

export const defaultInventory: IInventory = {
    inventory_uuid: null,
    product_name: "",
    product_category: "",
    product_cost_price: 0,
    quantity: 0,
    product_unit: "",
    restock_date: "",
    last_delivery_Date: "",
    report_id: "",
    vender: "",
    status: "ACTIVE"
}

export const defaultInventoryState: IInventoryState = {
    inventory_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_inventory: {
        loading: LoadState.idle,
        data: defaultInventory,
        error: null,
    }
}