import { companyRoutes } from "./Company/Companies.router";
import { companyLocationsRoutes } from "./CompanyOutlets/CompanyOutlets.router";
import { receiptComposerRoutes } from "./ReceiptComposer/ReceiptComposer.router";
import { securityRoutes } from "./Security/Security.router";
import { taxInfoRoutes } from "./TaxInfo/TaxInfo.router";
import { userProfileRoutes } from "./UserProfile/UserProfile.router";

export const settingRoutes = receiptComposerRoutes
    .concat(taxInfoRoutes)
    .concat(userProfileRoutes)
    .concat(taxInfoRoutes)
    .concat(companyRoutes)
    .concat(companyLocationsRoutes)
    .concat(securityRoutes)