import React from "react";
import { ICustomRoundButtonProps } from "./interface/interface";
import { Typography } from "@mui/material";
import { CustomButton } from "./CustomButton";

export const CustomRoundButton: React.FC<ICustomRoundButtonProps> = ({
  title,
  sx,
  ...props
}) => {
  return (
    <CustomButton
      variant="contained"
      sx={{ borderRadius: 5, py: 0.9, px: "auto", ...sx }}
      {...props}
    >
      <Typography variant={"h4"} fontWeight={"bold"}>
        {title}
      </Typography>
    </CustomButton>
  );
};
