import { Avatar, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { IStoreState, useAppDispatch, useAppSelector } from '../../redux';
import moment from 'moment';
import { CustomButton } from '../../Components/CustomButton';
import { ControlledCustomSelect } from '../../Components/formComponents';
import { CustomDatePicker } from '../../Components/formComponents/CustomDatePicker';
import PageContainer from '../../Components/PageContainer/PageContainer';
import { StandardTableActions } from '../../Components/StandardTableActions/StandardTableActions';
import { DataTable } from '../../Components/Table/DataTable';
import { IDataTableProps, MobileLogoRenderType, RenderType } from '../../Components/Table/interfaces/IDataTableProps';
import { clearReservationsState, defaultReservation, fetchTableReservationListAsync, IReservation } from '../../redux/reservations';
import { UpsertReservationDialog } from './dialogs/UpsertReservationDialog';


export const ReservationsTable = () => {
    const navigate = useNavigate()
    const {
        data: reservationsList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.reservations.reservations_list);
    const dispatch = useAppDispatch();


    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 10,
    });
    const [openUpsertDialog, setOpenUpsertDialog] = React.useState<IReservation | null>(null);
    const [dateFilter, setDateFilter] = React.useState<string>("ALL");
    const [selectedStatus, setSelectedStatus] = React.useState<string>("ALL");
    const [selectedUser, setSelectedUser] = React.useState<string>("ALL");
    const [customDate, setCustomDate] = React.useState<string | null>(null);
    const [customDateDialog, setCustomDateDialog] = React.useState<boolean>(false);
    const [userShiftsList, setUserShiftsList] = React.useState<IReservation[]>([]);


    const dataTableProps: IDataTableProps = {
        isPagination: true,
        rowsPerPageOptions: 25,
        isDataLoading: false,
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },

        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IReservation) => {
                    return (
                        <StandardTableActions
                            // onEditClick={() => navigate(`${ALL_SHIFTS_ROUTE}/manage/${row.user_uuid}/${row.shift_code}`)}
                            onEditClick={() => setOpenUpsertDialog(row)}
                        />
                    );
                },
            },
            {
                key: "user_name",
                headerName: "Name",
                fieldName: "user_name",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IReservation) => {
                    return (
                        <Typography variant='body1' textAlign={"left"}>{row.user_name}</Typography>
                    );
                },
            },
            {
                key: "user_email",
                headerName: "Email",
                fieldName: "user_email",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IReservation) => {
                    return (
                        <Typography variant='body1' textAlign={"left"}>{row.user_email}</Typography>
                    );
                },
            },
            {
                key: "user_phone_no",
                headerName: "Phone",
                fieldName: "user_phone_no",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IReservation) => {
                    return (
                        <Typography variant='body1' textAlign={"left"}>{row.user_phone_no}</Typography>
                    );
                },
            },
            {
                key: "reservation_date",
                headerName: "Date",
                fieldName: "reservation_date",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (values, row: IReservation) => {
                    return moment(row.reservation_date).isValid() ? moment(row.reservation_date).format("DD-MM-YYYY") : "--";
                },
            },
            {
                key: "reservation_from_time",
                headerName: "From",
                fieldName: "reservation_from_time",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (values, row: IReservation) => {
                    return moment(row.reservation_from_time).isValid() ? moment(row.reservation_from_time).format("hh:mm A") : "--";
                },
            },
            {
                key: "reservation_to_time",
                headerName: "To",
                fieldName: "reservation_to_time",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (values, row: IReservation) => {
                    return moment(row.reservation_to_time).isValid() ? moment(row.reservation_to_time).format("hh:mm A") : "--";
                },
            },
            {
                key: "number_of_people",
                headerName: "# of People",
                fieldName: "number_of_people",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "table_no",
                headerName: "Table #",
                fieldName: "table_no",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
        ],

        items: userShiftsList,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };


    React.useEffect(() => {
        dispatch(
            fetchTableReservationListAsync({
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
            }),
        );
    }, [pagination]);

    React.useEffect(() => {
        return () => {
            dispatch(clearReservationsState());
        };
    }, []);

    useEffect(() => {
        let newReservationsList = [...reservationsList]
        if (selectedUser === "ALL" || selectedStatus === "ALL") {
            newReservationsList = [...reservationsList]
        }
        
        if(selectedUser !== "ALL") {
            newReservationsList = newReservationsList.filter((res) => res.user_name.toLowerCase() === selectedUser.toLowerCase())
        }
        if (selectedStatus !== "ALL")  {
            newReservationsList = newReservationsList.filter((res) => res.status === selectedStatus)
        }
        if (dateFilter === "ALL") {
            setUserShiftsList(newReservationsList)
        } else if (dateFilter === "CUSTOM") {
            setCustomDateDialog(true)
        } else {
            const newList = newReservationsList.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === dateFilter)
            setUserShiftsList(newList)
        }
    }, [dateFilter, selectedUser, selectedStatus, reservationsList])

    useEffect(() => {
        if (dateFilter === "CUSTOM" && customDate) {
            const newCustomDate = moment(customDate).format("yyyy-MM-DD")
            const newList = reservationsList.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === newCustomDate)
            setUserShiftsList(newList)
        }
    }, [customDate])

    return (
        <PageContainer title="Reservations" description='inner page' >
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='h2'>Reservations</Typography>
                    <ControlledCustomSelect
                        label="Filter by Users"
                        options={[
                            { label: "All", value: "ALL" },
                        ].concat(reservationsList.map((res) => ({ label: res.user_name, value: res.user_name })))}
                        value={selectedUser}
                        onChange={(evt) => setSelectedUser(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />
                    <ControlledCustomSelect
                        label="Filter by Date"
                        options={[
                            { label: "All", value: "ALL" },
                            { label: "Today", value: moment().format("yyyy-MM-DD") },
                            { label: "Yesterday", value: moment().subtract(1, "day").format("yyyy-MM-DD") },
                            { label: "Custom", value: "CUSTOM" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />

                    {customDateDialog && <CustomDatePicker
                        label="Select Date"
                        fullWidth
                        fieldName="reservation_date"
                        value={customDate || ""}
                        onChange={(newValue, formattedDate) => {
                            setCustomDate(formattedDate);
                        }}
                    />}
                    <ControlledCustomSelect
                        label="Filter by Status"
                        options={[
                            { label: "All", value: "ALL" },
                            { label: "Active", value: "ACTIVE" },
                            { label: "Cancelled", value: "CANCELLED" },
                            { label: "Completed", value: "COMPLETED" },
                        ]}
                        value={selectedStatus}
                        onChange={(evt) => setSelectedStatus(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />
                </Stack>
                <CustomButton
                    variant="contained"
                    color="primary"
                    sx={{
                        display: "flex",
                        marginLeft: "auto",
                    }}
                    onClick={() => setOpenUpsertDialog(defaultReservation)}
                >
                    Add Reservation
                </CustomButton>
            </Stack>
            <DataTable {...dataTableProps} />

            {openUpsertDialog &&
                <UpsertReservationDialog
                    open={true}
                    reservation={openUpsertDialog}
                    onClose={() => setOpenUpsertDialog(null)}
                />
            }

        </PageContainer>
    );
}
