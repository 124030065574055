import { IPunchInOut } from "./shifts.types"



export const setShiftsInLocalStore = (data: IPunchInOut[]) => {
    localStorage.setItem("shifts", JSON.stringify(data))
}

export const getShiftsFormLocalStore = (): IPunchInOut[] => {
    const data = localStorage.getItem("shifts")
    if (data) {
        return JSON.parse(data)
    }
    return []
}