import BlankLayout from "../../layout/BlankLayout/BlankLayout";
import { ForgetPassword } from "./ForgetPassword/ForgetPassword";
import { LoginPage } from "./LoginPage";

export const authenticationRoutes = [
    {
        path: "/auth",
        exact: false,
        element: (<BlankLayout />),
        children: [
            // { path: "404", element: <Error /> },
            { path: "login", element: <LoginPage /> },
            { path: "forget-password", element: <ForgetPassword /> },
            // { path: "*", element: <Navigate to="/auth/404" /> },
        ],
    },
]