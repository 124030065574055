import { COMPANY_OUTLETS_ROUTE, OUTLET_REGISTERS_ROUTE, REGISTERS_STAFF_ROUTE } from "./CompanyOutlets.constants";
import { CompanyOutletsTable } from "./CompanyOutletsTable";
import { ManageCompanyOutletWithTabs } from "./ManageCompanyOutletWithTabs";
import { ManageSingleOutlet } from "./ManageSingleOutlet";
import { ManageSingleRegister } from "./OutletRegisters/ManageSingleRegister";
import { ManageSingleRegisterStaff } from "./RegisterStaff/ManageSingleRegisterStaff";

export const companyLocationsRoutes = [
    { path: `${COMPANY_OUTLETS_ROUTE}`, exact: true, element: <CompanyOutletsTable /> },
    { path: `${COMPANY_OUTLETS_ROUTE}/manage`, exact: true, element: <ManageSingleOutlet /> },
    { path: `${COMPANY_OUTLETS_ROUTE}/manage/:uuid`, exact: true, element: <ManageCompanyOutletWithTabs /> },

    { path: `${OUTLET_REGISTERS_ROUTE}`, exact: true, element: <ManageCompanyOutletWithTabs /> },
    { path: `${OUTLET_REGISTERS_ROUTE}/manage`, exact: true, element: <ManageSingleRegister /> },
    { path: `${OUTLET_REGISTERS_ROUTE}/manage/:register_uuid`, exact: true, element: <ManageSingleRegister /> },


    { path: `${REGISTERS_STAFF_ROUTE}/manage`, exact: true, element: <ManageSingleRegisterStaff /> },
    { path: `${REGISTERS_STAFF_ROUTE}/manage/:register_staff_uuid`, exact: true, element: <ManageSingleRegisterStaff /> },

]