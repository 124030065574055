import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultUberEatsOrderState } from "./uber-eats-orders.state";
import { fetchUberEatsOrderHistoryListAsync, fetchUberEatsSingleOrderAsync } from "./uber-eats-orders.actions";

const uberEatsOrdersSlice = createSlice({
    initialState: defaultUberEatsOrderState,
    name: "uber-eats-orders",
    reducers: {
        clearUberEatsOrdersStateSync: (state) => {
            return defaultUberEatsOrderState
        },
        clearUberEatsSingleOrderSync: (state) => {
            state.uber_eats_single_order.data = defaultUberEatsOrderState["uber_eats_single_order"]["data"]
            state.uber_eats_single_order.loading = defaultUberEatsOrderState["uber_eats_single_order"]["loading"]
            state.uber_eats_single_order.error = defaultUberEatsOrderState["uber_eats_single_order"]["error"]
        },
    },
    extraReducers: (builder) => {
        //############################# fetchUberEatsOrderHistoryListAsync ######################################
        builder.addCase(fetchUberEatsOrderHistoryListAsync.pending, (state, action) => {
            state.uber_eats_orders_list.loading = LoadState.pending
        })
        builder.addCase(fetchUberEatsOrderHistoryListAsync.fulfilled, (state, action) => {
            state.uber_eats_orders_list.loading = LoadState.succeeded
            state.uber_eats_orders_list.data = action.payload.data
            state.uber_eats_orders_list.totalRecords = action.payload.recordCount
            state.uber_eats_orders_list.error = null
        })
        builder.addCase(fetchUberEatsOrderHistoryListAsync.rejected, (state, action) => {
            state.uber_eats_orders_list.error = action.error.message as string
        })

        //############################# fetchUberEatsSingleOrderAsync ######################################
        builder.addCase(fetchUberEatsSingleOrderAsync.pending, (state, action) => {
            state.uber_eats_single_order.loading = LoadState.pending
        })
        builder.addCase(fetchUberEatsSingleOrderAsync.fulfilled, (state, action) => {
            state.uber_eats_single_order.loading = LoadState.succeeded
            state.uber_eats_single_order.data = action.payload
            state.uber_eats_single_order.error = null
        })
        builder.addCase(fetchUberEatsSingleOrderAsync.rejected, (state, action) => {
            state.uber_eats_single_order.error = action.error.message as string
        })


    },
});

export const uberEatsOrdersReducer = uberEatsOrdersSlice.reducer;
export const {
    clearUberEatsOrdersStateSync,
    clearUberEatsSingleOrderSync
} = uberEatsOrdersSlice.actions;
