import { Avatar, Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { StandardTableActions } from '../../../Components/StandardTableActions/StandardTableActions';
import { DataTable } from '../../../Components/Table/DataTable';
import { IDataTableProps, MobileLogoRenderType, RenderType } from '../../../Components/Table/interfaces/IDataTableProps';
import { useAppSelector, IStoreState, useAppDispatch } from '../../../redux';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { CustomButton } from '../../../Components/CustomButton';
import { IPunchInOut, clearShiftsState, defaultPunchInOut, fetchShiftPunchInOutAsync } from '../../../redux/shifts';
import moment from 'moment';
import { ControlledCustomSelect } from '../../../Components/formComponents';
import { CustomDatePicker } from '../../../Components/formComponents/CustomDatePicker';
import { useNavigate } from 'react-router-dom';
import { UpsertUserShiftDialog } from './dialogs/UpsertUserShiftDialog/UpsertUserShiftDialog';
import { ViewImageDialog } from './dialogs/ViewImageDialog';
import { ALL_SHIFTS_ROUTE } from '../ShiftAndPunchInOut.constants';

export const ShiftsTable = () => {
    const navigate = useNavigate()
    const {
        data: shiftsList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.shifts.all_user_shifts_list);
    const dispatch = useAppDispatch();


    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 10,
    });
    const [openUpsertDialog, setOpenUpsertDialog] = React.useState<boolean>(false);
    const [dateFilter, setDateFilter] = React.useState<string>("ALL");
    const [customDate, setCustomDate] = React.useState<string | null>(null);
    const [customDateDialog, setCustomDateDialog] = React.useState<boolean>(false);
    const [openImageDialog, setOpenImageDialog] = React.useState<string | null>(null);
    const [userShiftsList, setUserShiftsList] = React.useState<IPunchInOut[]>([]);


    const dataTableProps: IDataTableProps = {
        isPagination: true,
        rowsPerPageOptions: 25,
        isDataLoading: false,
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },

        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IPunchInOut) => {
                    return (
                        <StandardTableActions
                            onEditClick={() => navigate(`${ALL_SHIFTS_ROUTE}/manage/${row.user_uuid}/${row.shift_code}`)}
                        />
                    );
                },
            },
            {
                key: "user_name",
                headerName: "User",
                fieldName: "user_name",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IPunchInOut) => {
                    return (
                        <Typography variant='body1' textAlign={"left"}>{row.user_name}</Typography>
                    );
                },
            },
                        {
                key: "punch_date",
                headerName: "Punch Date",
                fieldName: "punch_date",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (values, row: IPunchInOut) => {
                    return moment(row.punch_date).isValid() ? moment(row.punch_date).format("DD-MM-YYYY") : "--";
                },
            },
            {
                key: "total_punch_time",
                headerName: "Total Punch Time",
                fieldName: "total_punch_time",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IPunchInOut) => {
                    return (
                        <Typography variant='body1' textAlign={"left"}>{`${row.total_punch_time} Hrs`}</Typography>
                    );
                },
            },
            {
                key: "total_break_out_time",
                headerName: "Total Break Time",
                fieldName: "total_break_out_time",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IPunchInOut) => {
                    return (
                        <Typography variant='body1' textAlign={"left"}>{`${row.total_break_out_time} Hrs`}</Typography>
                    );
                },
            },
            {
                key: "work_time",
                headerName: "Total Time",
                fieldName: "work_time",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IPunchInOut) => {
                    return (
                        <Typography variant='body1' textAlign={"left"}>{`${row.work_time} Hrs`}</Typography>
                    );
                },
            },
            {
                key: "amount",
                headerName: "Amount Earn",
                fieldName: "amount",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
           
        ],

        items: userShiftsList,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };


    React.useEffect(() => {
        dispatch(
            fetchShiftPunchInOutAsync({
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
            }),
        );
    }, [pagination]);

    React.useEffect(() => {
        return () => {
            dispatch(clearShiftsState());
        };
    }, []);

    useEffect(() => {
        if (dateFilter === "ALL") {
            setUserShiftsList(shiftsList)
        } else if (dateFilter === "CUSTOM") {
            setCustomDateDialog(true)
        } else {
            const newList = shiftsList.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === dateFilter)
            setUserShiftsList(newList)
        }
    }, [dateFilter, shiftsList])

    useEffect(() => {
        if (dateFilter === "CUSTOM" && customDate) {
            const newCustomDate = moment(customDate).format("yyyy-MM-DD")
            const newList = shiftsList.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === newCustomDate)
            setUserShiftsList(newList)
        }
    }, [customDate])

    return (
        <PageContainer title="Shifts" description='inner page' >
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='h2'>Shifts</Typography>
                    <ControlledCustomSelect
                        label="Search by Users"
                        options={[
                            { label: "All", value: "ALL" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />
                    <ControlledCustomSelect
                        label="Search by Date"
                        options={[
                            { label: "All", value: "ALL" },
                            { label: "Today", value: moment().format("yyyy-MM-DD") },
                            { label: "Yesterday", value: moment().subtract(1, "day").format("yyyy-MM-DD") },
                            { label: "Custom", value: "CUSTOM" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        sx={{ minWidth: 120 }}
                        fullWidth size="small" />

                    {customDateDialog && <CustomDatePicker
                        label="Select Date"
                        fullWidth
                        fieldName="reservation_date"
                        value={customDate || ""}
                        onChange={(newValue, formattedDate) => {
                            setCustomDate(formattedDate);
                        }}
                    />}
                </Stack>
                <CustomButton
                    variant="contained"
                    color="primary"
                    sx={{
                        display: "flex",
                        marginLeft: "auto",
                    }}
                    onClick={() => setOpenUpsertDialog(true)}
                >
                    Add New Punch
                </CustomButton>
            </Stack>
            <DataTable {...dataTableProps} />

            {openUpsertDialog &&
                <UpsertUserShiftDialog
                    open={openUpsertDialog}
                    onClose={() => setOpenUpsertDialog(false)}
                />
            }

            {openImageDialog && <ViewImageDialog open={true} imgSrc={openImageDialog} onClose={() => setOpenImageDialog(null)} />}
        </PageContainer>
    );
}
