import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultCompanyState } from "./company.state";
import { fetchCompanyListAsync, fetchSingleCompanyAsync } from "./company.actions";
import { ICompany } from "./company.types";

const companySlice = createSlice({
    initialState: defaultCompanyState,
    name: "company",
    reducers: {
        clearCompanyListSync: (state) => {
            state.company_list.data = defaultCompanyState["company_list"]["data"]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyListAsync.pending, (state, action) => {
            state.company_list.loading = LoadState.pending
        })
        builder.addCase(fetchCompanyListAsync.fulfilled, (state, action: PayloadAction<{ data: ICompany[], count: number }>) => {
            state.company_list.loading = LoadState.succeeded
            state.company_list.data = action.payload.data
            state.company_list.count = action.payload.count
            state.company_list.error = null
        })
        builder.addCase(fetchCompanyListAsync.rejected, (state, action) => {
            state.company_list.error = action.error.message as string
        })




        builder.addCase(fetchSingleCompanyAsync.pending, (state, action) => {
            state.single_company.loading = LoadState.pending
        })
        builder.addCase(fetchSingleCompanyAsync.fulfilled, (state, action) => {
            state.single_company.loading = LoadState.succeeded
            state.single_company.data = action.payload
            state.single_company.error = null
        })
        builder.addCase(fetchSingleCompanyAsync.rejected, (state, action) => {
            state.single_company.error = action.error.message as string
        })
    },
});

export const companyReducer = companySlice.reducer;
export const { clearCompanyListSync } = companySlice.actions;
