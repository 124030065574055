import { Stack, Typography, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, IconButton, Table, TableBody, TableContainer, Paper } from '@mui/material'
import React from 'react'
import PageContainer from '../../../../Components/PageContainer/PageContainer'
import { useNavigate } from 'react-router-dom'
import { fetchShiftPunchInOutAsync, clearShiftsState } from '../../../../redux/shifts'
import { CustomDatePicker } from '../../../../Components/formComponents/CustomDatePicker'
import Close from '@mui/icons-material/Close'
import { OrderHistoryRow } from './OrderHistoryRow'
import { useAppSelector, IStoreState, useAppDispatch } from '../../../../redux'
import { clearUberEatsOrdersStateSync, fetchUberEatsOrderHistoryListAsync } from '../../../../redux/uber-eats'

export const UberEatsOrderHistoryTable = () => {

  const navigate = useNavigate()
  const {
    data: uberEatsOrdersList,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.uberEats.uberEatsOrders.uber_eats_orders_list);
  const dispatch = useAppDispatch();

  const [dateFilter, setDateFilter] = React.useState<"ALL" | "CUSTOM">("ALL");
  const [customDate, setCustomDate] = React.useState<string | null>(null);


  React.useEffect(() => {
    dispatch(
      fetchUberEatsOrderHistoryListAsync(""),
    );
  }, [dateFilter, customDate]);


  React.useEffect(() => {
    return () => {
        dispatch(clearUberEatsOrdersStateSync());
    };
}, []);

  const handleDateFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateFilter(event.target.value as "ALL");
  };

  const onCancelFilterClick = () => {
    setCustomDate(null)
  }

  return (
    <PageContainer title="Uber Eats | Order History" description='inner page' >
      <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} flexWrap={"wrap"} >
        <Stack direction="row" justifyContent="flex-start" alignItems="center" minWidth="20%" spacing={1}>
          <Typography variant='h2'>Order History</Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" flexWrap={"wrap"} spacing={1}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={dateFilter}
              onChange={handleDateFilterChange}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 32,
                },
                '& .MuiTypography-root': {
                  fontSize: 14,
                }
              }}
            >
              <FormControlLabel value="ALL" control={<Radio />} label="All" />
              <FormControlLabel value="CUSTOM" control={<Radio />} label="Select Date" />
            </RadioGroup>
          </FormControl>

          {dateFilter === "CUSTOM" &&
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
              <CustomDatePicker
                label="Select Date"
                fieldName="reservation_date"
                value={customDate || ""}
                onChange={(newValue, formattedDate) => {
                  setCustomDate(formattedDate);
                }}
              />
              <Tooltip title="Cancel">
                <IconButton onClick={onCancelFilterClick}>
                  <Close sx={{ fontSize: 32 }} />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        </Stack>
      </Stack>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            {[1, 2, 3, 4, 5, 6, 7].map((row) => (
              <OrderHistoryRow />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </PageContainer>
  )
}



export const SampleTableData = [
  {
    status: "Out for Delivery",
    time: "11:45am",
    code: "&EA9C",
    amount: "16.10",
    courier_partner: {
      name: "Michael",
      phone: "(020) 8123 1234",
      img: ""
    },
    order_details: [
      {
        quantity: 1,
        product_name: "Double cheese burger",
        amount: "12.50"
      }
    ]
  },
  {
    status: "Out for Delivery",
    time: "11:45am",
    code: "&EA9C",
    amount: "16.10",
    courier_partner: {
      name: "Michael",
      phone: "(020) 8123 1234",
      img: ""
    },
    order_details: [
      {
        quantity: 1,
        product_name: "Double cheese burger",
        amount: "12.50"
      }
    ]
  },
  {
    status: "Out for Delivery",
    time: "11:45am",
    code: "&EA9C",
    amount: "16.10",
    courier_partner: {
      name: "Michael",
      phone: "(020) 8123 1234",
      img: ""
    },
    order_details: [
      {
        quantity: 1,
        product_name: "Double cheese burger",
        amount: "12.50"
      }
    ]
  },
  {
    status: "Out for Delivery",
    time: "11:45am",
    code: "&EA9C",
    amount: "16.10",
    courier_partner: {
      name: "Michael",
      phone: "(020) 8123 1234",
      img: ""
    },
    order_details: [
      {
        quantity: 1,
        product_name: "Double cheese burger",
        amount: "12.50"
      }
    ]
  },
  {
    status: "Out for Delivery",
    time: "11:45am",
    code: "&EA9C",
    amount: "16.10",
    courier_partner: {
      name: "Michael",
      phone: "(020) 8123 1234",
      img: ""
    },
    order_details: [
      {
        quantity: 1,
        product_name: "Double cheese burger",
        amount: "12.50"
      }
    ]
  },
  {
    status: "Out for Delivery",
    time: "11:45am",
    code: "&EA9C",
    amount: "16.10",
    courier_partner: {
      name: "Michael",
      phone: "(020) 8123 1234",
      img: ""
    },
    order_details: [
      {
        quantity: 1,
        product_name: "Double cheese burger",
        amount: "12.50"
      }
    ]
  },
  {
    status: "Out for Delivery",
    time: "11:45am",
    code: "&EA9C",
    amount: "16.10",
    courier_partner: {
      name: "Michael",
      phone: "(020) 8123 1234",
      img: ""
    },
    order_details: [
      {
        quantity: 1,
        product_name: "Double cheese burger",
        amount: "12.50"
      }
    ]
  }

]
