import { IReservation } from "./reservations.types"



export const setReservationsInLocalStore = (data: IReservation[]) => {
    localStorage.setItem("reservations", JSON.stringify(data))
}

export const getReservationsFormLocalStore = (): IReservation[] => {
    const data = localStorage.getItem("reservations")
    if (data) {
        return JSON.parse(data)
    }
    return []
}