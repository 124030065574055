import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios_api, uber_eats_base_api } from "../../../api";
import { IUberEatsOrder, IUberEatsOrderFulfillmentIssueResponse } from "./uber-eats-orders-types";
import { IUberEatsAcceptOrderPayload, IUberEatsAcceptOrderResponse } from "./uber-eats-orders-types/IUberEatsAcceptOrder";
import { IUberEatsCommonResponse } from "./uber-eats-orders.types";
import { IUberEatsRejectOrderPayload } from "./uber-eats-orders-types/IUberEatsRejectOrder";
import { IUberEatsCancelOrderPayload } from "./uber-eats-orders-types/IUberEatsCancelOrder";
import { IUberEatsUpdateOrderReadyTime } from "./uber-eats-orders-types/IUberEatsUpdateOrderReadyTime";
import { IUberEatsDeliveryPartnerFeedback } from "./uber-eats-orders-types/IUberEatsDeliveryPartnerFeedback";
import { IUberEatsAdjustOrderPricePayload } from "./uber-eats-orders-types/IUberEatsAdjustOrderPrice";

// interface IFetchUberEatsOrderHistoryPayload {
//     outlet_uuid: string
// }
export const fetchUberEatsOrderHistoryListAsync = createAsyncThunk<{ recordCount: number, data: IUberEatsOrder[] }, string>(
    'uber-eats-orders/fetchUberEatsOrderHistoryListAsync', async (outlet_uuid: string) => {
        const response = await uber_eats_base_api.get(`/delivery/store/${outlet_uuid}/orders`)
        const data = response.data.data;
        // const count = response.data.totalRecords
        return { recordCount: 0, data: data }
    },
)

export const fetchUberEatsSingleOrderAsync = createAsyncThunk<IUberEatsOrder, string>(
    'uber-eats-orders/fetchUberEatsSingleOrderAsync', async (order_id: string) => {
        const response = await uber_eats_base_api.get(`/delivery/order/${order_id}`)
        return response.data
    },
)


export const acceptUberEatsOrderAsync = createAsyncThunk<IUberEatsCommonResponse, IUberEatsAcceptOrderPayload>(
    'uber-eats-orders/acceptUberEatsOrderAsync', async ({ order_id, ...payload }: IUberEatsAcceptOrderPayload) => {
        const response = await uber_eats_base_api.post(`/delivery/order/${order_id}/accept`, payload)
        return response.data
    },
)


export const rejectUberEatsOrderAsync = createAsyncThunk<IUberEatsCommonResponse, IUberEatsRejectOrderPayload>(
    'uber-eats-orders/rejectUberEatsOrderAsync', async ({ order_id, ...payload }: IUberEatsRejectOrderPayload) => {
        const response = await uber_eats_base_api.post(`/delivery/order/${order_id}/deny`, payload)
        return response.data
    },
)

export const cancelUberEatsOrderAsync = createAsyncThunk<IUberEatsCommonResponse, IUberEatsCancelOrderPayload>(
    'uber-eats-orders/cancelUberEatsOrderAsync', async ({ order_id, ...payload }: IUberEatsCancelOrderPayload) => {
        const response = await uber_eats_base_api.post(`/delivery/order/${order_id}/cancel`, payload)
        return response.data
    },
)

export const readyUberEatsOrderAsync = createAsyncThunk<IUberEatsCommonResponse, string>(
    'uber-eats-orders/readyUberEatsOrderAsync', async (order_id: string) => {
        const response = await uber_eats_base_api.post(`/delivery/order/${order_id}/cancel`, {})
        return response.data
    },
)


export const updateUberEatsOrderReadyTimeAsync = createAsyncThunk<IUberEatsCommonResponse, IUberEatsUpdateOrderReadyTime>(
    'uber-eats-orders/updateUberEatsOrderReadyTimeAsync', async ({ order_id, ...payload }: IUberEatsUpdateOrderReadyTime) => {
        const response = await uber_eats_base_api.post(`/delivery/order/${order_id}/update-ready-time`, payload)
        return response.data
    },
)

export const resolveUberEatsOrderFulfillmentIssueAsync = createAsyncThunk<IUberEatsOrderFulfillmentIssueResponse, IUberEatsUpdateOrderReadyTime>(
    'uber-eats-orders/resolveUberEatsOrderFulfillmentIssueAsync', async ({ order_id, ...payload }: IUberEatsUpdateOrderReadyTime) => {
        const response = await uber_eats_base_api.post(`/delivery/order/${order_id}/resolve-fulfillment-issues`, payload)
        return response.data
    },
)

export const submitUberEatsDeliveryPartnerFeedbackAsync = createAsyncThunk<IUberEatsCommonResponse, IUberEatsDeliveryPartnerFeedback>(
    'uber-eats-orders/submitUberEatsDeliveryPartnerFeedbackAsync', async ({ order_id, ...payload }: IUberEatsDeliveryPartnerFeedback) => {
        const response = await uber_eats_base_api.post(`/delivery/order/${order_id}/delivery-partner-feedback`, payload)
        return response.data
    },
)


export const adjustUberEatsDeliveryPartnerFeedbackOrderPriceAsync = createAsyncThunk<IUberEatsCommonResponse, IUberEatsAdjustOrderPricePayload>(
    'uber-eats-orders/adjustUberEatsDeliveryPartnerFeedbackOrderPriceAsync', async ({ order_id, ...payload }: IUberEatsAdjustOrderPricePayload) => {
        const response = await uber_eats_base_api.post(`/delivery/order/${order_id}/adjust-price`, payload)
        return response.data
    },
)