import { IQueryParams } from "../types/IQueryParams";


export const getSearchQuery = (queryParams: IQueryParams): string => {
    const { page, rowsPerPage, status, value, columns, fromDate, toDate } =
        queryParams;
    let query = "?";
    if (status && status !== "-1") {
        query += `status=${status}&`;
    }
    if (columns && value && value.length > 0 && columns.length > 0) {
        query += `columns=${columns}&`;
    }
    if (value) {
        query += `value=${value}&`;
    }
    if (fromDate) {
        query += `from_date=${fromDate}&`;
    }
    if (toDate) {
        query += `to_date=${toDate}&`;
    }
    query += `pageNo=${page}&itemPerPage=${rowsPerPage}`;
    return query;
};
