import { CampaignsTable, ManageSingleCampaign } from "./Campaigns";
import { CouponCodesTable, ManageSingleCouponCode } from "./CouponCodes";
import { MARKETING_CAMPAIGNS_ROUTE, MARKETING_COUPON_CODES_ROUTE } from "./Marketing.constants";

export const marketingsRoutes = [
    { path: `${MARKETING_COUPON_CODES_ROUTE}`, exact: true, element: <CouponCodesTable /> },
    { path: `${MARKETING_COUPON_CODES_ROUTE}/manage`, exact: true, element: <ManageSingleCouponCode /> },
    { path: `${MARKETING_COUPON_CODES_ROUTE}/manage/:uuid`, exact: true, element: <ManageSingleCouponCode /> },

    { path: `${MARKETING_CAMPAIGNS_ROUTE}`, exact: true, element: <CampaignsTable /> },
    { path: `${MARKETING_CAMPAIGNS_ROUTE}/manage`, exact: true, element: <ManageSingleCampaign /> },
    { path: `${MARKETING_CAMPAIGNS_ROUTE}/manage/:uuid`, exact: true, element: <ManageSingleCampaign /> },
]