import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { FaCoffee } from "react-icons/fa";

export const TodaysUpscale: React.FC = () => {
  return (
    <Box border={"1px solid grey"} sx={{ padding: 2, borderRadius: 1, mt: 1 }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingBottom={3}
      >
        <Typography variant={"h3"} fontWeight={"bold"}>
          Today's Upscale
        </Typography>
        <Typography variant={"h4"} sx={{ textDecoration: "underline" }}>
          See All
        </Typography>
      </Stack>

      <UpscaleItemLayout />
      <UpscaleItemLayout />
      <UpscaleItemLayout />
      <UpscaleItemLayout />
    </Box>
  );
};

const UpscaleItemLayout: React.FC = () => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      py={0.5}
    >
     

      <FaCoffee 
        style={{
          fontSize: "2.9rem",
          borderRadius: "0.2rem",
          backgroundColor: "#292C2D",
          padding: "0.7rem",
          
        }}
      />

      <Stack direction={"column"}>
        <Typography variant={"h4"}>Roast Chicken</Typography>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"h6"} color={"grey"}>
            Order
          </Typography>
          <Typography variant={"h6"}>120</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
