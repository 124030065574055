import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultOutletRegisterState } from "./outlet-registers.state";
import { fetchOutletRegistersListAsync, fetchRegistersListForSingleOutletAsync, fetchSingleOutletRegisterAsync, fetchSingleRegisterStaffDetailsAsync, fetchStaffListForSingleRegisterAsync } from "./outlet-registers.actions";
import { IOutletRegister, IRegisterStaff } from "./outlet-registers.types";


const outletRegisterSlice = createSlice({
    initialState: defaultOutletRegisterState,
    name: "outlet-registers",
    reducers: {
        clearOutletRegisterListSync: (state) => {
            state.registers_list.data = defaultOutletRegisterState["registers_list"]["data"]
        },
        clearSingleOutletRegisterSync: (state) => {
            state.single_register.data = defaultOutletRegisterState["single_register"]["data"]
        },

        clearRegisterStaffListSync: (state) => {
            state.single_register_staff_list.data = defaultOutletRegisterState["single_register_staff_list"]["data"]
        },
        clearSingleRegisterStaffSync: (state) => {
            state.single_staff.data = defaultOutletRegisterState["single_staff"]["data"]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOutletRegistersListAsync.pending, (state, action) => {
            state.registers_list.loading = LoadState.pending
        })
        builder.addCase(fetchOutletRegistersListAsync.fulfilled, (state, action: PayloadAction<{ data: IOutletRegister[], count: number }>) => {
            state.registers_list.loading = LoadState.succeeded
            state.registers_list.data = action.payload.data
            state.registers_list.count = action.payload.count
            state.registers_list.error = null
        })
        builder.addCase(fetchOutletRegistersListAsync.rejected, (state, action) => {
            state.registers_list.error = action.error.message as string
        })



        builder.addCase(fetchRegistersListForSingleOutletAsync.pending, (state, action) => {
            state.registers_list.loading = LoadState.pending
        })
        builder.addCase(fetchRegistersListForSingleOutletAsync.fulfilled, (state, action: PayloadAction<{ data: IOutletRegister[], count: number }>) => {
            state.registers_list.loading = LoadState.succeeded
            state.registers_list.data = action.payload.data
            state.registers_list.count = action.payload.count
            state.registers_list.error = null
        })
        builder.addCase(fetchRegistersListForSingleOutletAsync.rejected, (state, action) => {
            state.registers_list.error = action.error.message as string
        })




        builder.addCase(fetchSingleOutletRegisterAsync.pending, (state, action) => {
            state.single_register.loading = LoadState.pending
        })
        builder.addCase(fetchSingleOutletRegisterAsync.fulfilled, (state, action) => {
            state.single_register.loading = LoadState.succeeded
            state.single_register.data = action.payload
            state.single_register.error = null
        })
        builder.addCase(fetchSingleOutletRegisterAsync.rejected, (state, action) => {
            state.single_register.error = action.error.message as string
        })



        // #################################################################################################
        // ################################### Register_Staff ##############################################
        // #################################################################################################

        builder.addCase(fetchStaffListForSingleRegisterAsync.pending, (state, action) => {
            state.single_register_staff_list.loading = LoadState.pending
        })
        builder.addCase(fetchStaffListForSingleRegisterAsync.fulfilled, (state, action: PayloadAction<{ data: IRegisterStaff[], count: number }>) => {
            state.single_register_staff_list.loading = LoadState.succeeded
            state.single_register_staff_list.data = action.payload.data
            state.single_register_staff_list.count = action.payload.count
            state.single_register_staff_list.error = null
        })
        builder.addCase(fetchStaffListForSingleRegisterAsync.rejected, (state, action) => {
            state.single_register_staff_list.error = action.error.message as string
        })




        builder.addCase(fetchSingleRegisterStaffDetailsAsync.pending, (state, action) => {
            state.single_staff.loading = LoadState.pending
        })
        builder.addCase(fetchSingleRegisterStaffDetailsAsync.fulfilled, (state, action: PayloadAction<IRegisterStaff>) => {
            state.single_staff.loading = LoadState.succeeded
            state.single_staff.data = action.payload
            state.single_staff.error = null
        })
        builder.addCase(fetchSingleRegisterStaffDetailsAsync.rejected, (state, action) => {
            state.single_staff.error = action.error.message as string
        })
    },
});

export const outletRegisterReducer = outletRegisterSlice.reducer;
export const {
    clearOutletRegisterListSync,
    clearRegisterStaffListSync,
    clearSingleOutletRegisterSync,
    clearSingleRegisterStaffSync
} = outletRegisterSlice.actions;
