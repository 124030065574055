import moment from "moment";
import { IBarChart, IOrderRevenueResponse } from "./Dashboard.types";


export const calculateOrderRevenue = (data: IOrderRevenueResponse[]): number => {
    let revenue = 0;
    (data || []).forEach((airline_data) => {
        revenue += Number(airline_data.total_amount)
    })
    return revenue
}


export const calculateOrderRevenueForBarChart = (data: IOrderRevenueResponse[]): IBarChart[] => {
    return data.map((flight_data, idx) => {
        return {
            name: moment(flight_data.created_date).format("DD-MM-YYYY") || "",
            uv: ((idx + 1) * 1000),
            amt: Number(flight_data.total_amount)
        }
    })
}