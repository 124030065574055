import { CreateSecurityGroup } from "./CreateSecurityGroup";
import { ListSecurityGroups } from "./ListSecurityGroups";
import { SECURITY_APPROVALS_ROUTE, SECURITY_GROUPS_ROUTE, SECURITY_ROLE_GROUPS_ROUTE } from "./Security.constatnts";
import { ApprovalList } from "./approval/ApprovalList";
import { RoleGroupTable } from "./roleGroup/RoleGroupTable";



export const securityRoutes = [
    { path: `${SECURITY_GROUPS_ROUTE}`, exact: true, element: <ListSecurityGroups /> },
    { path: `${SECURITY_GROUPS_ROUTE}/create-security-group`, exact: true, element: <CreateSecurityGroup /> },
    { path: `${SECURITY_GROUPS_ROUTE}/create-security-group/:roleId`, exact: true, element: <CreateSecurityGroup  /> },
    { path: `${SECURITY_GROUPS_ROUTE}/security-role-duplicate/:roleId`, exact: true, element: <CreateSecurityGroup isDuplicate  /> },

    

    { path: `${SECURITY_ROLE_GROUPS_ROUTE}`, exact: true, element: <RoleGroupTable /> },
]