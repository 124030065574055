import { Avatar, Stack, Typography } from '@mui/material';
import React from 'react'
import { StandardTableActions } from '../../../../Components/StandardTableActions/StandardTableActions';
import { DataTable } from '../../../../Components/Table/DataTable';
import { IDataTableProps, MobileLogoRenderType, RenderType } from '../../../../Components/Table/interfaces/IDataTableProps';
import { useAppSelector, IStoreState, useAppDispatch } from '../../../../redux';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import { CustomButton } from '../../../../Components/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { IRegisterStaff, clearRegisterStaffListSync, fetchStaffListForSingleRegisterAsync } from '../../../../redux/settings/outlet-registers';

export const RegisterStaffTable = () => {
    const { uuid: outlet_uuid, register_uuid: registerUUID } = useParams<{ uuid?: string, register_uuid?: string }>();
    const {
        data: registersList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.registers.single_register_staff_list);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 10,
    });


    const dataTableProps: IDataTableProps = {
        isPagination: true,
        rowsPerPageOptions: 25,
        isDataLoading: false,
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },

        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IRegisterStaff) => {
                    return (
                        <StandardTableActions
                            onEditClick={() => navigate(`/outlets/${outlet_uuid}/registers/${registerUUID}/staff/manage/${row.register_staff_uuid}`)}
                        />
                    );
                },
            },
            {
                key: "user_name",
                headerName: "User Name",
                fieldName: "user_name",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "register_uuid",
                headerName: "Register",
                fieldName: "register_uuid",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
        ],

        items: registersList,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };


    React.useEffect(() => {
        if(!registerUUID) return;
        dispatch(
            fetchStaffListForSingleRegisterAsync({
                register_uuid: registerUUID,
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
            }),
        );
    }, [registerUUID, pagination]);

    React.useEffect(() => {
        return () => {
            dispatch(clearRegisterStaffListSync());
        };
    }, []);

  

    return (
        <PageContainer title="Staff" description='inner page' >
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='h2'>Staff</Typography>
                </Stack>
                <CustomButton
                    variant="contained"
                    color="primary"
                    sx={{
                        display: "flex",
                        marginLeft: "auto",
                    }}
                    onClick={() => navigate(`/outlets/${outlet_uuid}/registers/${registerUUID}/staff/manage`)}
                >
                    Add Staff
                </CustomButton>
            </Stack>
            <DataTable {...dataTableProps} />
        </PageContainer>
    );
}
