import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../../common";
import { axios_api } from "../../../api/axios-api";
import { IProductCustomization, IProductModifier } from "./customization.types";
import { GET_PRODUCT_CUSTOMIZATION_BASE_URL, GET_PRODUCT_MODIFIER_BASE_URL, UPSERT_PRODUCT_CUSTOMIZATION_BASE_URL, UPSERT_PRODUCT_MODIFIER_BASE_URL } from "../../../api/axios-api.endpoints";



export const fetchCustomizationListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IProductCustomization[] }, IQueryParams>(
    'product-customization/fetchCustomizationListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_PRODUCT_CUSTOMIZATION_BASE_URL}${searchQuery}&status=ACTIVE`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const upsertSingleCustomizationAsync = createAsyncThunk<IProductCustomization, IProductCustomization>(
    'product-customization/upsertSingleCustomizationAsync', async (payload: IProductCustomization, thunkAPI) => {
        const { customization_options, ...restPayload } = payload
        const response = await axios_api.post(UPSERT_PRODUCT_CUSTOMIZATION_BASE_URL, restPayload)
        thunkAPI.dispatch(fetchCustomizationListWithArgsAsync({
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)


// ###########################################################################################
// ################################# Product Modifiers #######################################
// ###########################################################################################


export const fetchCustomizedModifiersListAsync = createAsyncThunk<{ recordCount: number, data: IProductModifier[] }, string>(
    'product-customization/fetchCustomizedModifiersListAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_PRODUCT_MODIFIER_BASE_URL}?customization_uuid=${uuid}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const fetchProductModifiersListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IProductModifier[] }, IQueryParams>(
    'product-customization/fetchProductModifiersListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_PRODUCT_MODIFIER_BASE_URL}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchSingleModifiersAsync = createAsyncThunk<IProductModifier, string>(
    'product-customization/fetchSingleModifiersAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_PRODUCT_MODIFIER_BASE_URL}?modifier_uuid=${uuid}`)
        const data = response.data.data;
        return data[0]
    },
)

export const upsertSingleProductModifierAsync = createAsyncThunk<IProductModifier, IProductModifier>(
    'product-customization/upsertSingleProductModifierAsync', async (payload: IProductModifier, thunkAPI) => {
        const response = await axios_api.post(UPSERT_PRODUCT_MODIFIER_BASE_URL, payload)
        thunkAPI.dispatch(fetchCustomizedModifiersListAsync(payload.customization_uuid))
        return response.data
    },
)