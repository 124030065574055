import { Grid } from "@mui/material";
import React from "react";
import { useAppDispatch } from "../../../../../redux";
import { ISingleUserPunch, updateSinglePunchInfoAsync } from "../../../../../redux/shifts";
import { useFormik } from "formik";
import { Dialog } from "../../../../../Components/Dialogs/Dialog";
import { OutlinedTextField } from "../../../../../Components/formComponents";
import { OutlinedTimePicker } from "../../../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { punch_type_without_underscore } from "../../../ShiftAndPunchInOut.constants";


interface PunchInOutUpdateDialogProps {
    open: boolean,
    onClose: () => void
    data: ISingleUserPunch
    onSubmit: (data: ISingleUserPunch) => void
}

export const PunchInOutUpdateDialog: React.FC<PunchInOutUpdateDialogProps> = ({
    open,
    onClose,
    data,
    onSubmit
}) => {
    const dispatch = useAppDispatch();
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue
    } = useFormik({
        initialValues: data,
        validate: (values) => {
            const errors: any = {};
            if (!values.user_punch_in_out_time) {
                errors.user_punch_in_out_time = "*This field is required.";
            }
            return errors;
        },
        onSubmit: async (values) => {
            dispatch(updateSinglePunchInfoAsync({
                ...values,
            })).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    onSubmit(values)
                }
            })
        },
    });

    return (
        <Dialog
            open={open}
            title="Update Punch"
            size="sm"
            contentWrappedWithForm={{ onSubmit: handleSubmit }}
            onClose={onClose}
            actions={[
                {
                    type: "button",
                    variant: "outlined",
                    label: "Cancel",
                    onClick: onClose,
                },
                {
                    type: "submit",
                    variant: "contained",
                    label: "Update",

                },
            ]}
        >
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            label="Punch Type"
                            id="type"
                            disabled
                            value={punch_type_without_underscore[values.type]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTimePicker
                            label="Punch In"
                            fullWidth
                            value={moment(values.user_punch_in_out_time)}
                            onChange={(newValue) => {
                                setFieldValue("user_punch_in_out_time", newValue.toISOString())
                            }}
                            error={errors.user_punch_in_out_time}
                        />
                    </Grid>
                </Grid>
            </form>
        </Dialog>
    );
};
