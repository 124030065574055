import { LoadState } from "../../../constants/enum"
import { ICategory, ICategoryState } from "./categories.types"

export const defaultCategory: ICategory = {
    category_uuid: null,
    category_name: "",
    category_description: "",
    category_type: "",
    outlet_uuid: "",
    status: "ACTIVE"
}

export const defaultCategoryState: ICategoryState = {
    category_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_category: {
        loading: LoadState.idle,
        data: defaultCategory,
        error: null,
    },
    selected_category: null,
}