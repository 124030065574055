import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../../common";
import { axios_api } from "../../../api/axios-api";
import { IInventory } from "./inventory.types";
import { GET_INVENTORY_BASE_URL, UPSERT_INVENTORY_BASE_URL } from "../../../api/axios-api.endpoints";



export const fetchInventoryWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IInventory[] }, IQueryParams>(
    'inventory/fetchInventoryWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_INVENTORY_BASE_URL}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const upsertSingleInventoryItemAsync = createAsyncThunk<IInventory, IInventory>(
    'inventory/upsertSingleInventoryItemAsync', async (payload: IInventory, thunkAPI) => {
        const response = await axios_api.post(UPSERT_INVENTORY_BASE_URL, payload)
        thunkAPI.dispatch(fetchInventoryWithArgsAsync({
            columns: [],
            page: 1,
            status: "-1",
            value: "",
            rowsPerPage: 10
        }))
        return response.data
    },
)