import { Divider, DividerProps, styled } from "@mui/material";

interface ICustomDividerProps extends DividerProps {
  borderColor: string;
}
export const CustomDivider = styled((props: ICustomDividerProps) => (
  <Divider {...props} />
))(({ theme, borderColor }) => ({
  marginTop: 8,
  marginBottom: 16,
  borderColor: borderColor,
  borderWidth: "1px",
}));
