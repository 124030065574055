import { createSlice } from "@reduxjs/toolkit";
import { defaultChartOfAccountState } from "./charts-of-account.state";
import { getChartsOfAccountListFormLocalStore, setChartsOfAccountListInLocalStore } from "./charts-of-account.localstore";
import { fetchChartOfAccountsListWithArgsAsync } from "./charts-of-account.actions";
import { LoadState } from "../../../constants/enum";



const chartsOfAccountSlice = createSlice({
    initialState: defaultChartOfAccountState,
    name: "charts-of-account",
    reducers: {
        clearChartsOfAccountState: (state) => {
            return defaultChartOfAccountState
        },
    },
    extraReducers: (builder) => {
        // ############################# Multiple Chart Of Accounts Lis  ######################################
        builder.addCase(fetchChartOfAccountsListWithArgsAsync.pending, (state, action) => {
            state.charts_of_account_list.loading = LoadState.pending
        })
        builder.addCase(fetchChartOfAccountsListWithArgsAsync.fulfilled, (state, action) => {
            state.charts_of_account_list.loading = LoadState.succeeded
            state.charts_of_account_list.data = action.payload.data
            state.charts_of_account_list.totalRecords = action.payload.recordCount
            state.charts_of_account_list.error = null
        })
        builder.addCase(fetchChartOfAccountsListWithArgsAsync.rejected, (state, action) => {
            state.charts_of_account_list.error = action.error.message as string
        })

    },
});

export const chartsOfAccountReducer = chartsOfAccountSlice.reducer;
export const {
    clearChartsOfAccountState
} = chartsOfAccountSlice.actions;
