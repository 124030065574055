import { Delete } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useRef } from "react";

const convertToBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};


export const SingleFileUploadButton: React.FC<{
  title?: string
  fileUrl: string | null;
  onChange: (fileUrl: File) => void;
  onDelete: () => void
}> = ({
  title,
  fileUrl,
  onChange,
  onDelete
}) => {
    const fileClick = useRef<any>();
    const [localFileName, setLocalFileName] = React.useState<string | null>(null)

    const onFileChange = async (e: any) => {
      const file = e.target.files[0] as File;
      setLocalFileName(file.name)
      // const base64 = await convertToBase64(file);
      onChange(file);
    };

 
    if (fileUrl || localFileName) return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">{fileUrl ? fileUrl : localFileName ? localFileName : ""}</Typography>
        <Box onClick={onDelete} sx={{ cursor: "pointer" }}>
          <Tooltip title="Delete">
            <Delete color="primary" />
          </Tooltip>
        </Box>
      </Box>
    )


    return (
      <>
        <input
          ref={fileClick}
          type="file"
          onChange={onFileChange}
          // multiple={true}
          style={{ display: "none" }}
        />
        <Button
          variant="outlined"
          fullWidth
          onClick={() => fileClick.current.click()}
        >
          {title ? title : "Upload"}
        </Button>
      </>
    );
  };
