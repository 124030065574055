import { UberEatsReadyForPickupTable } from "./components/ReadyForPickup/ReadyForPickupTable";
import { UberEatsInProgressOrdersTable } from "./components/InProgressOrders/InProgressOrdersTable";
import { UberEatsNewOrdersTable } from "./components/NewOrders/NewOrdersTable";
import { UBER_EATS_IN_PROGRESS_ORDERS_ROUTE, UBER_EATS_NEW_ORDERS_ROUTE, UBER_EATS_ORDER_HISTORY_ROUTE, UBER_EATS_ORDER_READY_FOR_PICKUP_ROUTE } from "./UberEats.constants";
import { UberEatsOrderHistoryTable } from "./components/OrderHistory/OrderHistoryTable";


export const uberEatsRoutes = [
    {
        path: UBER_EATS_NEW_ORDERS_ROUTE,
        exact: true,
        element: <UberEatsNewOrdersTable />
    },
    {
        path: UBER_EATS_IN_PROGRESS_ORDERS_ROUTE,
        exact: true,
        element: <UberEatsInProgressOrdersTable />
    },
    {
        path: UBER_EATS_ORDER_READY_FOR_PICKUP_ROUTE,
        exact: true,
        element: <UberEatsReadyForPickupTable />
    },
    {
        path: UBER_EATS_ORDER_HISTORY_ROUTE,
        exact: true,
        element: <UberEatsOrderHistoryTable />
    },
] 