import { FullLayout } from "../layout/FullLayout/FullLayout";
import { authenticationRoutes } from "../views/Authentication/Authentication.router";
import { ProtectedRoute } from "./ProtectedRoutes";
import { inventoryRoutes } from "../views/Inventory/Inventory.router";
import { tablesRoutes } from "../views/Tables/Tables.router";
import { accountingRoutes } from "../views/Accounting/Accounting.router";
import { posOrderRoutes } from "../views/MenuAndOrders/MenuAndOrders.router";
import { shiftAndPunchInOutRoutes } from "../views/ShiftAndPunchInOut/ShiftAndPunchInOut.router";
import { settingRoutes } from "../views/Settings";
import { marketingsRoutes } from "../views/Marketing";
import { reservationsRoutes } from "../views/Reservations/Reservations.router";
import { Navigate, Outlet } from "react-router-dom";
import { DASHBOARD_ROUTES } from "../views/Dashboard/Dashboard.constants";
import { dashboardRoutes } from "../views/Dashboard/Dashboard.router";
import { uberEatsRoutes } from "../views/UberEats/UberEats.router";
import { PageNotFound } from "./PageNotFound";

export const Router = [
  {
    path: "*",
    exact: true,
    element: <PageNotFound/>,
  },
  {
    path: "/",
    exact: true,
    element: <ProtectedRoute><FullLayout /></ProtectedRoute>,
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to={DASHBOARD_ROUTES} replace />,
      },
    ].concat(dashboardRoutes)
      .concat(inventoryRoutes)
      .concat(tablesRoutes)
      .concat(shiftAndPunchInOutRoutes)
      .concat(accountingRoutes)
      .concat(posOrderRoutes)
      .concat(settingRoutes)
      .concat(marketingsRoutes)
      .concat(reservationsRoutes)
      .concat(uberEatsRoutes)
  },

  // --------------------------------AUTH ROUTING----------------------------------------------
  ...authenticationRoutes
]
