import moment from "moment";
import {
  STANDARD_APP_DATE_FORMAT,
  STANDARD_APP_DATE_TIME_FORMAT,
  STANDARD_APP_TIME_FORMAT,
} from "../constants/constants";

export const convertDate = (targetDate: string) => {
  let date = new Date(targetDate);

  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  console.log(`${date.getFullYear()}-0${date.getMonth()}-${date.getDate()}`);
  console.log(date);

  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};

export const formatDate = (date: string | null): string => {
  if (date) {
    return moment(date).format(STANDARD_APP_DATE_FORMAT);
  } else {
    return "--";
  }
};

export const formatDateWithTime = (date: string | null) => {
  if (date) {
    return moment(date).format(STANDARD_APP_DATE_TIME_FORMAT);
  } else {
    return "--";
  }
};

export const formatTime = (date: string | null) => {
  console.log(date)
  if (date) {
    return moment(date).format(STANDARD_APP_TIME_FORMAT);
  } else {
    return "--";
  }
};
