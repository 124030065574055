import { LoadState } from "../../../constants/enum";
import { IPaymentPaidState, IPaymentPaid } from "./payment-paid.types";


export const defaultPaymentPaid: IPaymentPaid = {
    payment_paid_uuid: null,
    vendor: "",
    category: "",
    order_number: "",
    invoice_number: "",
    total_tax: "",
    total_amount: "",
    order_status: "DRAFT",
    payment_mode: "CASH",
    attachment: null
}

export const defaultPaymentPaidState: IPaymentPaidState = {
    payments_paid_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null
    },
    single_payment_paid: {
        loading: LoadState.idle,
        data: defaultPaymentPaid,
        error: null
    }
}