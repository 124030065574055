import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultTaxInfoState } from "./tax-info.state";
import { fetchTaxInfoListAsync } from "./tax-info.actions";
import { ITaxInfo } from "./tax-info.types";

const taxInfoSlice = createSlice({
    initialState: defaultTaxInfoState,
    name: "tax-info",
    reducers: {
        clearTaxInfoListSync: (state) => {
            state.tax_info_list.data = defaultTaxInfoState["tax_info_list"]["data"]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTaxInfoListAsync.pending, (state, action) => {
            state.tax_info_list.loading = LoadState.pending
        })
        builder.addCase(fetchTaxInfoListAsync.fulfilled, (state, action: PayloadAction<{ data: ITaxInfo[], count: number }>) => {
            state.tax_info_list.loading = LoadState.succeeded
            state.tax_info_list.data = action.payload.data
            state.tax_info_list.count = action.payload.count
            state.tax_info_list.error = null
        })
        builder.addCase(fetchTaxInfoListAsync.rejected, (state, action) => {
            state.tax_info_list.error = action.error.message as string
        })




        // builder.addCase(fetchSingleUserProfileAsync.pending, (state, action) => {
        //     state.single_user.loading = LoadState.pending
        // })
        // builder.addCase(fetchSingleUserProfileAsync.fulfilled, (state, action) => {
        //     state.single_user.loading = LoadState.succeeded
        //     state.single_user.data = action.payload
        //     state.single_user.error = null
        // })
        // builder.addCase(fetchSingleUserProfileAsync.rejected, (state, action) => {
        //     state.single_user.error = action.error.message as string
        // })
    },
});

export const taxInfoReducer = taxInfoSlice.reducer;
export const { clearTaxInfoListSync } = taxInfoSlice.actions;
