import { Chip, ChipProps, styled } from "@mui/material";

export const CustomChip = styled((props: ChipProps) => <Chip {...props} />)(
  ({ theme }) => ({
    fontSize: "0.7rem",
    padding: "1px 3px",
    height: "24px",
    fontWeight: 600,
  })
);
