import { TableRow, TableCell, Typography, Box, styled } from '@mui/material'
import { RUPEE_SYMBOL } from '../../../../constants/constants'
import React from 'react'

interface IOrderHistoryExpandViewTablePRops {
    quantity: number,
    item: string,
    price: string
    subItems: {
        quantity: number,
        item: string,
        price: string
    }[]
}

const MuiCustomTableCell = styled(TableCell)({
    borderBottom: "none",
    paddingBlock: 0
})

export const OrderHistoryExpandViewTable = () => {
    return (
        <React.Fragment>
            <TableRow sx={{ background: "#292929" }}>
                <MuiCustomTableCell width={80} sx={{ paddingTop: 2 }}>
                    <Typography variant='h5'>1X</Typography>
                </MuiCustomTableCell>
                <MuiCustomTableCell sx={{ paddingTop: 2 }}>
                    <Typography variant='h5'>Lentil soup</Typography>
                </MuiCustomTableCell>
                <MuiCustomTableCell align="right" sx={{ paddingTop: 2 }}>
                    <Typography variant='h5'> {`${RUPEE_SYMBOL} 50`}</Typography>
                </MuiCustomTableCell>
            </TableRow>

            <TableRow sx={{ background: "#292929" }}>
                <MuiCustomTableCell width={80} />
                <MuiCustomTableCell sx={{ pl: 4 }}>
                    <Typography variant='subtitle1' sx={{ color: "#ccc", mt: 1, textTransform: "uppercase" }}>Additional Toppings</Typography>
                </MuiCustomTableCell>
                <MuiCustomTableCell />
            </TableRow>

            <TableRow sx={{ background: "#292929" }}>
                <MuiCustomTableCell width={80} sx={{ paddingBottom: 2 }} />
                <MuiCustomTableCell sx={{ paddingBottom: 2, pl: 4 }}>
                    <Typography textAlign={"left"} variant='body1'>Low Sodium</Typography>
                </MuiCustomTableCell>
                <MuiCustomTableCell align="right" sx={{ paddingBottom: 2 }}>
                    <Typography variant='h5'> {`${RUPEE_SYMBOL} 2`}</Typography>
                </MuiCustomTableCell>
            </TableRow>
        </React.Fragment>
    )
}
