import Box from "@mui/material/Box";
import React from "react";
import { Link } from "react-router-dom";
import posLogo from "../../../assests/pos_logo.png"
import { Typography } from "@mui/material";

export const LogoIcon: React.FC = () => {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <Box display={"flex"} justifyContent="flex-start">
        {/* <img src={posLogo} height={50} /> */}
        <Typography variant="h5"
        sx={{
          // fontFamily: `"Kanit", sans-serif !important`,
          // fontFamily: `"Teko", sans-serif !important`,
          fontFamily: `"Russo One", sans-serif !important`,
          color: "#fff",
          fontSize: 36,
          textTransform: "uppercase",
          fontWeight: 700
        }}
        >AirPos</Typography>
      </Box>
    </Link>
  );
};
