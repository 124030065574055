import React from "react";
import { StandardCard } from "../../../Components/Card";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  OutlinedTextField,
} from "../../../Components/formComponents";
import { LuIndianRupee } from "react-icons/lu";
import { MdOutlinePercent } from "react-icons/md";
import { CustomChip } from "../../../Components/CustomChip";
import { CustomAccordion } from "../../../Components/CustomAccordion/CustomAccordion";
import { CustomRoundButton } from "../../../Components/CustomButton";
import dummyPhoto from "../../../assests/dummy_image.jpg";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useNavigate, useParams } from "react-router-dom";
import { defaultProductCustomization, defaultProductIngredient, fetchSingleProductAsync, upsertSingleProductAsync } from "../../../redux/inventory";
import { useFormik } from "formik";
import { PRODUCTS_ROUTE } from "../Inventory.constants";
import { CategoryAutoSearch } from "../Category/AutoSearch/CategoryAutoSearch";
import LoadingDialog from "../../../Components/Dialogs/LoadingDialog";
import { ProductIngredientsTable } from "./ProductIngredients/ProductIngredientsTable";
import { ProductCustomizationTable } from "./ProductCustomizations/ProductCustomizationTable";

export const ManageSingleProduct: React.FC = () => {
  const { uuid: product_uuid } = useParams() as {
    uuid?: string;
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: productInfo, loading, error } = useAppSelector((state) => state.inventory.products.single_product);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);
  
  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: productInfo,
    validate: (values) => {
      const errors: any = {};
      if (!values.product_name) {
        errors.product_name = "*This field is required.";
      }
      if (!values.product_price) {
        errors.product_price = "*This field is required.";
      }

      if (!values.category_uuid) {
        errors.category_uuid = "*This field is required.";
      }
      // if (!values.airline_uuid) {
      //   errors.airline_uuid = "*This field is required.";
      // }
      // if (!values.airline_uuid) {
      //   errors.airline_uuid = "*This field is required.";
      // }
      // if (!values.airline_uuid) {
      //   errors.airline_uuid = "*This field is required.";
      // }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSingleProductAsync({...values, file: fileToUpload})
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(`${PRODUCTS_ROUTE}`)
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });

  React.useEffect(() => {
    if (product_uuid) {
      dispatch(fetchSingleProductAsync(product_uuid));
    }
  }, [product_uuid]);

  React.useEffect(() => {
    setValues({
      ...productInfo,
      product_image: productInfo.product_image ? productInfo.product_image : dummyPhoto
    });
  }, [productInfo]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFieldValue("product_image", reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setFieldValue("product_image", "");
  };

  return (
    <StandardCard heading="Add/Edit Product">
      <form
        style={{
          height: "80vh",
          overflowY: "scroll",
          paddingRight: "16px",
          paddingTop: 6
        }}
        onSubmit={handleSubmit}
      >
        <CustomAccordion
          expanded
          sx={{ mt: 2 }}
          title="Product Information"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <OutlinedTextField
                label="Product Name"
                id="product_name"
                placeholder="Enter Product Name"
                value={values.product_name}
                onChange={handleChange}
                error={errors.product_name}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row" spacing={2}>
                <Box
                  sx={{
                    width: "500px",
                    height: "300px",
                    borderRadius: "8px",
                    overflow: "hidden",
                    position: "relative",
                    border: "1px solid #b1abab",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      aspectRatio: "16/9",
                    },
                  }}
                >
                  <img src={values.product_image || ""} alt="food_image" />
                </Box>
                <Stack direction={"column"} gap={2}>
                  <Typography variant="h4">
                    File Requirement: JPG, PNG, GIF, or WEBP upto 10MB. Minimum
                    pixels required: 320 width and height.
                  </Typography>
                  <Stack direction={"row"} gap={1}>
                    <input
                      type="file"
                      id="file_input"
                      multiple
                      accept=".jpg, .png, .gif, .webp"
                      onChange={handleImageChange}
                    />
                    <label htmlFor="file_input" className="input_file_text">
                      Replace
                    </label>
                    <CustomRoundButton
                      title="Remove"
                      color="primary"
                      onClick={handleRemoveImage}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <OutlinedTextField
                id="product_description"
                label="Product Description"
                multiline
                rows={3}
                placeholder="Enter Description"
                value={values.product_description}
                onChange={handleChange}

              // error={errors.product_name}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <CustomFormLabel>Sell Item on its owm?</CustomFormLabel>
              <Stack direction="row" spacing={2}>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Checkbox checked={values.product_sell_its_own} onChange={(evt) => setFieldValue("product_sell_its_own", !values.product_sell_its_own)} />
                  <CustomFormLabel>Yes</CustomFormLabel>
                </Stack>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Checkbox checked={!values.product_sell_its_own} onChange={(evt) => setFieldValue("product_sell_its_own", !values.product_sell_its_own)} />
                  <CustomFormLabel mt={2}>No</CustomFormLabel>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <CategoryAutoSearch
                name="category_name"
                label="Product Category"
                value={{
                  category_uuid: values.category_uuid,
                  category_name: values.category_name,
                }}
                onSelect={(newValue) => {
                  setValues({
                    ...values,
                    category_uuid: newValue.category_uuid as string,
                    category_name: newValue.category_name,
                  });
                }}
                error={errors.category_uuid}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <CustomFormLabel>Price By</CustomFormLabel>
              <Stack direction="row" spacing={2}>
                <CustomChip
                  label="Each"
                  sx={{ p: 3, fontSize: "16px" }}
                  color={values.product_price_by === "EACH" ? "warning" : "default"}
                  onClick={() => setFieldValue("product_price_by", "EACH")}
                />
                <CustomChip label="Weight"
                  sx={{ p: 3, fontSize: "16px" }}
                  color={values.product_price_by === "WEIGHT" ? "warning" : "default"}
                  onClick={() => setFieldValue("product_price_by", "WEIGHT")}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <OutlinedTextField
                label="Price"
                id="product_price"
                placeholder="Enter Price"
                value={values.product_price}
                onChange={handleChange}
                error={errors.product_price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LuIndianRupee fontSize={"24px"} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {values.product_price_by === "WEIGHT" &&
              <Grid item xs={12} sm={12} md={3}>
                <ControlledCustomSelect
                  label="Weight Unit"
                  name="product_weight_unit"
                  fullWidth
                  options={[
                    { value: "lb", label: "lb" },
                    { value: "oz", label: "oz" },
                    { value: "kg", label: "kg" },
                    { value: "g", label: "g" },
                  ]}
                  placeholder="Select Weight Unit"
                  value={values.product_weight_unit}
                  onChange={handleChange}
                />
              </Grid>
            }
            <Grid item xs={12} sm={12} md={3}>
              <OutlinedTextField
                label="Tax Rate"
                id="product_tax"
                placeholder="Enter Tax Rate"
                value={values.product_tax}
                onChange={handleChange}
                // error={errors.product_tax}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <MdOutlinePercent fontSize={"24px"} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="row" alignItems="center">
                <Typography variant="body1" >Item out of stock?</Typography>
                <Checkbox checked={values.is_out_of_stock} onChange={(evt) => {
                  setValues({
                    ...values,
                    is_out_of_stock: evt.target.checked,
                    out_of_stock_for: null
                  })
                }} />
              </Stack>
            </Grid>

            {/* If out of stock is checked, then only this field will be visible */}
            {values.is_out_of_stock && <Grid item xs={12} sm={12} md={3}>
              <ControlledCustomSelect
                label="Out of stock For"
                name="out_of_stock_for"
                options={[
                  { value: "Today", label: "Today" },
                  { value: "Indefinitely", label: "Indefinitely" },
                ]}
                clearable
                value={values.out_of_stock_for}
                onChange={handleChange}
              />
            </Grid>}
          </Grid>
        </CustomAccordion>

        <CustomAccordion title="Customize Item">

          <ProductCustomizationTable
            data={values.product_customizations || [defaultProductCustomization]}
            onChange={(data) => setFieldValue("product_customizations", data)}
          />
        </CustomAccordion>

        <CustomAccordion title="Ingredient Details">
          <ProductIngredientsTable
            data={values.product_ingredients || [defaultProductIngredient]}
            onChange={(data) => setFieldValue("product_ingredients", data)} />
        </CustomAccordion>

        <CustomAccordion title="Energy Values">
          <Typography variant="h6" mb={2}>
            Include calorie and kilojoule count for this item.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <OutlinedTextField
                label="Calories"
                name="calories"
                type="number"
                placeholder="Enter item's calorie count"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="h6">Cal</Typography>
                    </InputAdornment>
                  ),
                }}
                value={values.calories}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <OutlinedTextField
                label="Kilojoules"
                name="kilojoules"
                type="number"
                placeholder="Enter item's kilojoule count"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="h6">KJ</Typography>
                    </InputAdornment>
                  ),
                }}
                value={values.kilojoules}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CustomAccordion>

        <CustomAccordion title="Extra Details">

          <Typography variant="h6" mb={2}>
            Edit external ID and other item data.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <OutlinedTextField
                label="GTIN"
                id="gst_number"
                placeholder="enter gst number"
                value={values.gst_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <OutlinedTextField
                id="external_id"
                label="External ID"
                placeholder="enter external id"
                value={values.external_id}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <OutlinedTextField
                label="External Data"
                id="external_data"
                placeholder="enter external data"
                value={values.external_data}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CustomAccordion>

        <Stack marginBlock={2} direction="row" justifyContent="flex-end" spacing={2}>
          <Button sx={{ minWidth: 120 }} variant="contained"  onClick={() => navigate(PRODUCTS_ROUTE)}>Close</Button>
          <Button sx={{ minWidth: 120 }} variant="contained" type="submit">Save</Button>
        </Stack>
      </form>
      {saveLoading && <LoadingDialog open={saveLoading} />}
    </StandardCard>
  );
};
