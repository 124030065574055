import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { IQuillEditorProps } from "./QuillEditor.types";
import "react-quill/dist/quill.snow.css";
import { error_message } from "../../constants/constants";

export const QuillEditor: React.FC<IQuillEditorProps> = (props) => {
  const { value, onChange, error } = props;
  const myColors = [
    "purple",
    "#785412",
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white"
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: myColors }],
      [{ background: myColors }]
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align"
  ];

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          ".ql-container": {
            minHeight: 200,
          },
        }}
      >
        <ReactQuill theme="snow" 
        formats={formats}
        modules={modules}
        value={value} onChange={onChange} />
        {error && <Typography sx={{ color: "red", fontSize: "11px", p: 1 }}>*{error_message.required}</Typography>}
      </Box>
    </Grid>
  );
};
