import { Box, Typography } from '@mui/material'
import React from 'react'
import { CustomButton } from '../Components/CustomButton'
import { useNavigate } from 'react-router-dom'

export const PageNotFound = () => {
const navigate = useNavigate();

    const handleClick = () => {
        navigate("/")
    }
    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Typography variant='h1' fontWeight={700} >404: Page Not Found!</Typography>
            <CustomButton
                variant="outlined"
                color="primary"
                size="large"
                sx={{
                    minWidth: 100,
                    paddingInline: "16px !important"
                }}
onClick={handleClick}
            >
                Go to Dashboard
            </CustomButton>
        </Box>
    )
}
