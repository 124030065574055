import React, { useState } from 'react'
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { HiMinusSm } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { changeItemQuantitySync, insertItemIntoOrderSync, removeItemFromOrderSync } from '../../../../redux/orders';
import { RUPEE_SYMBOL } from '../../../../constants/constants';
import { IProduct } from '../../../../redux/inventory';
import { CustomizeProductDialog } from './CustomizeProductDialog';
import { OutlinedTextField } from '../../../../Components/formComponents';
import { Add, AutoAwesome, AutoFixHigh, Edit, Remove } from '@mui/icons-material';
import { BiMinus } from 'react-icons/bi';



export interface IMenuProductCardProps {
    data: IProduct;
}

export const MenuProductCard: React.FC<IMenuProductCardProps> = ({
    data,
}) => {
    const dispatch = useAppDispatch();

    const currentOrderInfo = useAppSelector((state) => state.orders.single_order.data)
    const quantity = currentOrderInfo.menu_items.find((item) => item.product_uuid === data.product_uuid)?.quantity;

    const [openCustomizationDialog, setOpenCustomizationDialog] = useState<boolean>(false)
    const handleAddItemInOrder = () => {
        dispatch(insertItemIntoOrderSync(data))
    }

    const handleRemoveItemFromOrder = () => {
        if (quantity && quantity > 0) {
            dispatch(removeItemFromOrderSync(data))
        }
    }

    const handleChangeProductQuantity = (itemQuantity: number) => {
        console.log("evt.target.value ==>", itemQuantity)
        dispatch(changeItemQuantitySync({
            product: data,
            quantity: itemQuantity
        }))
    }

    return (
        <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Paper
                square={false}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "space-between",
                    bgcolor: "#2C2D2C" /* FBC1D9, 2C2D2C */,
                    color: "#FFFFFF",
                    borderRadius: 1,
                    width: "100%",
                }}
            >
                <Box
                    width={"4%"}
                    bgcolor={"#FBC1D9"}
                    borderRadius={"8px 0 0 8px"}
                ></Box>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1.8}
                    width={"96%"}
                    px={1.5}
                    pb={1.5}
                >
                    <Box pt={1}>
                        <Typography
                            color={"#D0D0D0"}
                            variant="h6"
                            fontWeight={"bold"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={0.5}
                            justifyContent={"flex-start"}
                        >
                            {data.category_name}
                            {/* <TrendingFlatOutlinedIcon fontSize="small" /> */}
                            {/* <FaLongArrowAltRight fontSize={"1rem"} /> */}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Box minWidth={"40%"}>
                        <Typography variant="h4"  fontWeight={"bold"} letterSpacing={-0.5}>
                            {data.product_name}
                        </Typography>
                        <Typography
                            variant="h4"
                            color={"#D0D0D0"}
                            fontWeight={"600"}
                        >
                            {`${RUPEE_SYMBOL}${data.product_price}`}
                        </Typography>
                        </Box>

                        <Button variant='contained' onClick={handleRemoveItemFromOrder}><Remove /></Button>

                       
                        <OutlinedTextField
                            sx={{
                                maxWidth: 60,
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                                "& input[type=number]::-webkit-outer-spin-button": {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]::-webkit-inner-spin-button": {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "6px 6px",
                                    textAlign: "center"
                                },
                            }}
                            type="number"
                            value={quantity || 0}
                            autoFocus
                            onChange={(evt) => {

                                if (Number(evt.target.value) > 0) {
                                    handleChangeProductQuantity(Number(evt.target.value))
                                }
                            }}
                        // onBlur={handleQuantityChange}
                        />
                        <Button variant='contained' onClick={handleAddItemInOrder}><Add /></Button>

                        <Button
                            variant='contained'
                            // size='small'
                            // sx={{ background: "#fff", color: "#000" }}
                            onClick={() => setOpenCustomizationDialog(true)}><AutoFixHigh/></Button>
                    </Box>

                </Box>
            </Paper>

            {openCustomizationDialog &&
                <CustomizeProductDialog
                    open={openCustomizationDialog}
                    product={data}
                    onClose={() => setOpenCustomizationDialog(false)}
                />}
        </Box>
    );
};

