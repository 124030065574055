import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { UberEatsOrderDialog } from '../../dialogs/ViewOrderDialog/ViewOrderDialog'
import { CustomButton } from '../../../../Components/CustomButton'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { defaultUberEatsOrder } from '../../../../redux/uber-eats';

interface IAvailableOrderCardProps {
  order_status: "NEW" | "IN_PROGRESS" | "READY_TO_PICKUP"
}
export const AvailableOrderCard: React.FC<IAvailableOrderCardProps> = ({
  order_status
}) => {
  const [openViewOrderDialog, setOpenViewOrderDialog] = useState(false)

  const openOrderDialog = () => {
    setOpenViewOrderDialog(true)
  }

  const closeOrderDialog = () => {
    setOpenViewOrderDialog(false)
  }
  return (
    <>
      <Box sx={{
        width: '100%',
        minHeight: 70,
        maxWidth: 600,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        background: "#292929",
        borderRadius: 1,
        cursor: "pointer"
      }}
        onClick={openOrderDialog}
      >
        <Box sx={{
          padding: 2,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap"
        }} >
          <Typography variant='h3' sx={{mr: 3}}>3WEWSF3</Typography>

          <Typography variant='h3'>Michelle T.</Typography>
        </Box>

        {order_status === "NEW" ?
          <Box sx={{
            padding: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }} >
            <Typography variant='h5'>11:45am</Typography>
          </Box>
          :
          <Box sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }} >
            <Typography variant='h5'>11:45am</Typography>

            <Box sx={{
              minWidth: 70,
              minHeight: 70,
              borderLeft: "1px solid #717171",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }} >
              {order_status === "READY_TO_PICKUP"
                ?
                <CheckCircleIcon sx={{ fontSize: 28, color: "#717171" }} />
                :
                <CheckCircleOutlineIcon sx={{ fontSize: 28, color: "#717171" }} />
              }
            </Box>

          </Box>
        }
      </Box>

      {openViewOrderDialog && <UberEatsOrderDialog open={true} onClose={closeOrderDialog} order_info={defaultUberEatsOrder} />}
    </>
  )
}
