
//############################  Inventory Endpoints ############################//

export const GET_CATEGORIES_BASE_URL = "/inventory/get-category"
export const UPSERT_CATEGORIES_BASE_URL = "/inventory/upsert-category"

export const GET_PRODUCT_BASE_URL = "/inventory/get-product"
export const UPSERT_PRODUCT_BASE_URL = "/inventory/upsert-product"

export const GET_PRODUCT_CUSTOMIZATION_BASE_URL = "/inventory/get-customization"
export const UPSERT_PRODUCT_CUSTOMIZATION_BASE_URL = "/inventory/upsert-customization"

export const GET_PRODUCT_MODIFIER_BASE_URL = "/inventory/get-product-modifier"
export const UPSERT_PRODUCT_MODIFIER_BASE_URL = "/inventory/upsert-product-modifier"

export const GET_INVENTORY_BASE_URL = "/inventory/get-inventory"
export const UPSERT_INVENTORY_BASE_URL = "/inventory/upsert-inventory"



//############################  Orders Endpoints ############################//
export const GET_ORDERS_BASE_URL = "/order/get-order"
export const UPSERT_ORDERS_BASE_URL = "/order/upsert-order"