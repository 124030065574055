import { TableMenuLayout } from "./TableMenuLayout";
import { Tables } from "./Tables";
import { TABLES_LAYOUT_ROUTE, TABLES_ROUTE } from "./Tables.constants";

export const tablesRoutes = [
    {
        path: TABLES_ROUTE,
        exact: true,
        element: <Tables />
    },
    {
        path: TABLES_LAYOUT_ROUTE,
        exact: true,
        element: <TableMenuLayout />
    },
    {
        path: `${TABLES_ROUTE}/view/:uuid`,
        exact: true,
        element: <TABLES_ROUTE />
    },
] 