import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { useNavigate, useParams } from "react-router-dom";
import { StandardCard } from "../../../Components/Card";
import { OutlinedTextField } from "../../../Components/formComponents";
import { fetchSingleUserShiftInfoAsync, ISingleUserPunch } from "../../../redux/shifts";
import { SingleUserPunchDetails } from "./components/SingleUserShift/SingleUserPunchDetails";

export const ManageSingleShift = () => {
    const { user_uuid, shift_code } = useParams<{ user_uuid?: string, shift_code?: string }>();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { data: singleShiftInfo } = useAppSelector((storeState: IStoreState) => storeState.shifts.single_user_shift);

    const [saveLoading, setSaveLoading] = React.useState(false);
    const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

    const {
        values,
        errors,
        setFieldValue,
        setValues,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: { ...singleShiftInfo },
        validate: (values) => {
            const errors: any = {};
            // if (!values.company_name) {
            //   errors.company_name = "*First Name is required";
            // }
            // if (!values.company_opening_timing) {
            //   errors.company_opening_timing = "*This Field is required.";
            // }
            // if (!values.company_closing_timing) {
            //   errors.company_closing_timing = "*This Field is required.";
            // }
            // if (values.company_phone_number && values.company_phone_number.toString().length !== 10) {
            //   errors.company_phone_number = "*Phone number must be 10 digits";
            // }
            // if (!values.company_email) {
            //   errors.company_email = "*Email is required.";
            // } else if (
            //   values.company_email !== "" &&
            //   !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            //     values.company_email,
            //   )
            // ) {
            //   errors.company_email = "*Invalid email address.";
            // }
            return errors;
        },
        onSubmit: (values) => {
            // setSaveLoading(true);
            // dispatch(
            //     upsertSingleShiftPunchInOutAsync({ ...values, file: fileToUpload }),
            // ).then((res) => {
            //     if (res.meta.requestStatus === "fulfilled") {
            //         navigate(ALL_SHIFTS_ROUTE)
            //     }
            // }).finally(() => {
            //     setSaveLoading(false);
            // });
        },
    });


    React.useEffect(() => {
        if (!shift_code || !user_uuid) return
        dispatch(fetchSingleUserShiftInfoAsync({ user_uuid, shift_code }));
    }, [user_uuid, shift_code]);

    React.useEffect(() => {
        setValues(singleShiftInfo);
    }, [singleShiftInfo]);

    React.useEffect(() => {
        return () => {
            // dispatch(clearSingleOutletsSync())
        }
    }, []);



    const handleUserPunchInOutChange = (idx: number) => (punch: ISingleUserPunch) => {
        let newData = [...values.punch_details]
        newData[idx] = punch
        setFieldValue("punch_details", newData)
    }


    return (
        <>
            <form onSubmit={handleSubmit}>
                <Typography variant='h2'>Manage Shift</Typography>
                <StandardCard
                    heading="Shift Information"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <OutlinedTextField
                                label="User Name"
                                id="user_name"
                                disabled
                                value={values.user_name}
                                onChange={handleChange}
                                error={errors.user_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <OutlinedTextField
                                label="Shift Code"
                                id="shift_code"
                                disabled
                                value={values.shift_code}
                                onChange={handleChange}
                                error={errors.shift_code}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <OutlinedTextField
                                label="Total Time"
                                id="work_time"
                                disabled
                                value={values.work_time}
                                onChange={handleChange}
                                error={errors.work_time}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <OutlinedTextField
                                label="Amount Earned"
                                id="amount"
                                disabled
                                value={values.amount}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </StandardCard>

                <Grid sx={{
                    mt: 2
                }}
                    container spacing={2}>
                    {(values.punch_details || []).map((punch_info, idx) => {
                        return (
                            <Grid item xs={12} sm={4} md={2.4}>
                                <SingleUserPunchDetails key={idx} data={punch_info} onChange={handleUserPunchInOutChange(idx)} />
                            </Grid>
                        )
                    })}
                </Grid>

                {/* <Box sx={{ mt: 4 }}>
                    <Button
                        sx={{ width: "10%" }}
                        disabled={saveLoading}
                        variant="contained"
                        type="submit"
                    >
                        Save
                    </Button>
                </Box> */}
            </form>
        </>
    );
};
