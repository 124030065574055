import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultOrderState } from "./orders.state";
import { fetchOrdersListAsync, fetchOrdersListWithArgsAsync, fetchSingleOrderAsync } from "./orders.actions";
import { LoadState } from "../../constants/enum";
import { IProduct, IProductCustomization, IProductModifier } from "../inventory";
import { isNotNull } from "../../helpers/isNotNull";

const ordersSlice = createSlice({
    initialState: defaultOrderState,
    name: "orders",
    reducers: {
        insertItemIntoOrderSync: (state, action: PayloadAction<IProduct>) => {
            const { product_uuid, product_name, product_price, product_tax } = action.payload;
            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        item.quantity = isNotNull(item.quantity) + 1;
                        item.product_price = isNotNull(item.product_price) + isNotNull(product_price);
                    }
                    return item;
                });
            } else {
                state.single_order.data.menu_items.push({
                    product_uuid: product_uuid as string,
                    product_name,
                    product_price,
                    tax_percentage: isNotNull(product_tax),
                    quantity: 1,
                    options: [],
                    notes: "",
                    is_served: false
                });
            }
        },
        removeItemFromOrderSync: (state, action) => {
            const { product_uuid, product_price } = action.payload;
            const currentStateItems = [...state.single_order.data.menu_items]
            const isPresent = currentStateItems.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                currentStateItems.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        item.quantity = isNotNull(item.quantity) - 1;
                        item.product_price = isNotNull(item.product_price) - isNotNull(product_price);
                    }
                    return item;
                });
                console.log("currentStateItems ==?", currentStateItems)
                state.single_order.data.menu_items = currentStateItems.filter((item) => item.quantity > 0);
            }
        },
        changeItemQuantitySync: (state, action: PayloadAction<{ product: IProduct, quantity: number }>) => {
            const { product_uuid, product_name, product_price, product_tax } = action.payload.product;
            const quantity = action.payload.quantity;

            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        item.product_price = isNotNull(quantity) * isNotNull(product_price);
                        item.quantity = quantity;
                    }
                    return item;
                });
            } else {
                state.single_order.data.menu_items.push({
                    product_uuid: product_uuid as string,
                    product_name,
                    product_price,
                    tax_percentage: isNotNull(product_tax),
                    quantity: quantity,
                    options: [],
                    notes: "",
                    is_served: false
                });
            }
        },

        changeOrderItemNoteFieldSync: (state, action: PayloadAction<{ product: IProduct, note: string }>) => {
            const { product_uuid, product_name, product_price, product_tax } = action.payload.product;
            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        item.notes = action.payload.note
                    }
                    return item;
                });
            } else {
                // state.single_order.data.menu_items.push({
                //     product_uuid: product_uuid as string,
                //     product_name,
                //     product_price,
                //     tax_percentage: isNotNull(product_tax),
                //     quantity: 1,
                //     notes: ""
                // });
            }
        },



        addProductModifierIntoOrderSync: (state, action: PayloadAction<{ product: IProduct, modifier: IProductModifier }>) => {
            const { product_uuid, product_name, product_price, product_tax } = action.payload.product;
            const productModifier = action.payload.modifier;
            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        const isOptionPresent = item.options.find((option) => option.modifier_uuid === productModifier.modifier_uuid);
                        if (isOptionPresent) {
                            item.options.map((option) => {
                                if (option.modifier_uuid === productModifier.modifier_uuid) {
                                    option.quantity += 1
                                    option.modifier_price = isNotNull(option.modifier_price) + isNotNull(productModifier.modifier_price)
                                }
                                return option
                            })

                        } else {
                            item.options.push({
                                modifier_uuid: productModifier.modifier_uuid || "",
                                modifier_name: productModifier.modifier_name,
                                customization_uuid: productModifier.customization_uuid,
                                modifier_price: productModifier.modifier_price,
                                tax_percentage: product_tax,
                                quantity: 1
                            })
                        }
                    }
                    return item;
                });
            } else {
                state.single_order.data.menu_items.push({
                    product_uuid: product_uuid as string,
                    product_name,
                    product_price,
                    tax_percentage: isNotNull(product_tax),
                    quantity: 1,
                    options: [{
                        modifier_uuid: productModifier.modifier_uuid || "",
                        modifier_name: productModifier.modifier_name,
                        customization_uuid: productModifier.customization_uuid,
                        modifier_price: productModifier.modifier_price,
                        tax_percentage: product_tax,
                        quantity: 1
                    }],
                    notes: "",
                    is_served: false
                });
            }
        },

        changeProductModifierQuantitySync: (state, action: PayloadAction<{ product: IProduct, modifier: IProductModifier, quantity: number }>) => {
            const { product_uuid, product_name, product_price, product_tax } = action.payload.product;
            const productModifier = action.payload.modifier;
            const modifierQuantity = action.payload.quantity;

            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        const isOptionPresent = item.options.find((option) => option.modifier_uuid === productModifier.modifier_uuid);
                        if (isOptionPresent) {
                            item.options.map((option) => {
                                if (option.modifier_uuid === productModifier.modifier_uuid) {
                                    option.quantity = modifierQuantity
                                    option.modifier_price = isNotNull(productModifier.modifier_price) * isNotNull(modifierQuantity)
                                }
                                return option
                            })
                        } else {
                            item.options.push({
                                modifier_uuid: productModifier.modifier_uuid || "",
                                modifier_name: productModifier.modifier_name,
                                customization_uuid: productModifier.customization_uuid,
                                modifier_price: isNotNull(productModifier.modifier_price) * isNotNull(modifierQuantity),
                                tax_percentage: product_tax,
                                quantity: modifierQuantity
                            })
                        }
                    }
                    return item;
                });
            } else {
                state.single_order.data.menu_items.push({
                    product_uuid: product_uuid as string,
                    product_name,
                    product_price,
                    tax_percentage: isNotNull(product_tax),
                    quantity: 1,
                    options: [{
                        modifier_uuid: productModifier.modifier_uuid || "",
                        modifier_name: productModifier.modifier_name,
                        customization_uuid: productModifier.customization_uuid,
                        modifier_price: isNotNull(productModifier.modifier_price) * isNotNull(modifierQuantity),
                        tax_percentage: product_tax,
                        quantity: modifierQuantity
                    }],
                    notes: "",
                    is_served: false
                });
            }
        },

        removeProductModifierFromOrderSync: (state, action: PayloadAction<{ product: IProduct, modifier: IProductModifier }>) => {
            const { product_uuid } = action.payload.product;
            const productModifier = action.payload.modifier;

            const currentStateItems = [...state.single_order.data.menu_items]
            const isPresent = currentStateItems.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                currentStateItems.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        const isOptionPresent = item.options.find((option) => option.modifier_uuid === productModifier.modifier_uuid);
                        if (isOptionPresent) {
                            item.options.map((option) => {
                                if (option.modifier_uuid === productModifier.modifier_uuid) {
                                    option.quantity -= 1
                                    option.modifier_price = isNotNull(option.modifier_price) - isNotNull(productModifier.modifier_price)
                                }
                                return option
                            })
                            item.options = item.options.filter((option) => option.quantity > 0)
                        }
                    }
                    return item;
                });
                state.single_order.data.menu_items = currentStateItems.filter((item) => item.quantity > 0);
            }
        },




        clearOrdersStateSync: (state) => {
            return defaultOrderState
        },
        clearSingleOrderStateSync: (state) => {
            state.single_order.data = defaultOrderState["single_order"]["data"]
            state.single_order.loading = defaultOrderState["single_order"]["loading"]
        },
    },
    extraReducers: (builder) => {
        //############################# fetchOrdersListAsync ######################################
        builder.addCase(fetchOrdersListAsync.pending, (state, action) => {
            state.orders_list.loading = LoadState.pending
        })
        builder.addCase(fetchOrdersListAsync.fulfilled, (state, action) => {
            state.orders_list.loading = LoadState.succeeded
            state.orders_list.data = action.payload.data
            state.orders_list.totalRecords = action.payload.recordCount
            state.orders_list.error = null
        })
        builder.addCase(fetchOrdersListAsync.rejected, (state, action) => {
            state.orders_list.error = action.error.message as string
        })

        //############################# fetchOrdersListWithArgsAsync ######################################
        builder.addCase(fetchOrdersListWithArgsAsync.pending, (state, action) => {
            state.orders_list.loading = LoadState.pending
        })
        builder.addCase(fetchOrdersListWithArgsAsync.fulfilled, (state, action) => {
            state.orders_list.loading = LoadState.succeeded
            state.orders_list.data = [...state.orders_list.data].concat(action.payload.data)
            state.orders_list.totalRecords = action.payload.recordCount
            state.orders_list.error = null
        })
        builder.addCase(fetchOrdersListWithArgsAsync.rejected, (state, action) => {
            state.orders_list.error = action.error.message as string
        })


        //#################################### fetchSingleOrderAsync ##############################################
        builder.addCase(fetchSingleOrderAsync.pending, (state, action) => {
            state.single_order.loading = LoadState.pending
        })
        builder.addCase(fetchSingleOrderAsync.fulfilled, (state, action) => {
            state.single_order.loading = LoadState.succeeded
            state.single_order.data = action.payload
            state.single_order.error = null
        })
        builder.addCase(fetchSingleOrderAsync.rejected, (state, action) => {
            state.single_order.error = action.error.message as string
        })
    },
});

export const ordersReducer = ordersSlice.reducer;
export const {
    insertItemIntoOrderSync,
    removeItemFromOrderSync,
    changeItemQuantitySync,

    changeOrderItemNoteFieldSync,

    addProductModifierIntoOrderSync,
    changeProductModifierQuantitySync,
    removeProductModifierFromOrderSync,

    clearOrdersStateSync,
    clearSingleOrderStateSync
} = ordersSlice.actions;
