import { CloudUpload, InsertDriveFile, Delete } from '@mui/icons-material';
import { Box, InputAdornment, Grid, Button, Typography, IconButton, Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../redux';
import { QuillEditor } from '../QuillEditor/QuillEditor';
import { TextFieldWithLabel } from '../TextFieldWithLabel/TextFieldWithLabel';
import { CustomTextField } from '../formComponents';
import { fetchEnquiryInfoAsync } from './ComposeMail.actions';
import { getComposedMail } from './ComposeMail.helpers';
import { IEmailAttachment, defaultComposeMailMessage, IComposeMailMessage } from './ComposeMail.types';
import { CustomMailTextField, LocalFileUploaderInMail } from './components';

export const ComposeNewMail = () => {

    //################################ Hooks ################################/
    const dispatch = useAppDispatch();
    // const { email: loggedInUserEmail } = useLoggedInUserInfo();

    //################################ Local State ################################/
    const [openEnquiryAttachment, setOpenEnquiryAttachment] =
        useState<boolean>(false);
    const [localFiles, setLocalFiles] = useState<IEmailAttachment[]>([]);
    const [enquiryFiles, setEnquiryFiles] = useState<IEmailAttachment[]>([]);
    const [sendLoading, setSendLoading] = React.useState<boolean>(false);
    const [pageLoading, setPageLoading] = React.useState<boolean>(false);
    const [hasEmails, setHasEmails] = React.useState<boolean>(false);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        isValid,
        resetForm,
    } = useFormik({
        initialValues: {
            ...defaultComposeMailMessage,
            // sender_email: loggedInUserEmail,
            // cc_emails: [loggedInUserEmail],
            attachments: [
                {
                    content: "",
                    filename: "",
                },
            ],
        },
        validate: (values) => {
            const errors: any = {};
            if (values.recipient_emails.length <= 0) {
                errors.recipient_emails = "*Email is required.";
            }
            if (!values.subject) {
                errors.subject = "*This Field is required";
            }
            if (!values.body) {
                errors.body = "*This Field is required";
            }
            return errors;
        },
        onSubmit: async (values) => {

        },
    });


    const handleDelete = (filename: string) => {
        setEnquiryFiles((preFiles) =>
            preFiles.filter((file) => file.filename !== filename),
        );
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <CustomMailTextField
                emails={values.recipient_emails}
                error={errors.recipient_emails as string}
                onChange={(emails) => {
                    setFieldValue("recipient_emails", emails);
                }}
                placeholder="To"
            />
            <CustomMailTextField
                emails={values.cc_emails}
                error={errors.cc_emails as string}
                onChange={(emails) => {
                    setFieldValue("cc_emails", emails);
                }}
                placeholder="CC"
            />
            <CustomTextField
                id="subject"
                fullWidth
                disabled={hasEmails}
                value={values.subject}
                error={errors.subject ? true : false}
                helperText={errors.subject}
                onChange={handleChange}
                placeholder="Subject"
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       {hasEmails ? "" : `#${enquiryNumber}: `}
            //     </InputAdornment>
            //   ),
            // }}
            />

            <QuillEditor
                value={values.body}
                onChange={(html) => setFieldValue("body", html)}
                error={errors.body ? true : false}
            />

            {/* {uploadsVisible && (
            <>
              <TextFieldWithLabel
                heading="Remark"
                id="comment"
                fullWidth
                value={values.comment}
                onChange={handleChange}
              />
              <Grid container gap={4}>
                <Grid item xs={6}>
                  <LocalFileUploaderInMail
                    onUploadSuccess={(files) => {
                      setLocalFiles([...files, ...localFiles]);
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Button
                    onClick={() => setOpenEnquiryAttachment(true)}
                    fullWidth
                    variant="contained"
                    endIcon={<CloudUpload />}
                  >
                    Upload from enquiry
                  </Button>

                  {enquiryFiles.length > 0 && (
                    <Box>
                      {enquiryFiles.map((file) => (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          borderBottom="1px solid #ccc"
                          paddingY={1}
                        >
                          <Box display="flex" alignItems="center">
                            <InsertDriveFile sx={{ marginRight: 1 }} />
                            <Typography variant="body1" sx={{ marginRight: 1 }}>
                              {file.filename}
                            </Typography>
                          </Box>
                          <IconButton
                            onClick={() => handleDelete(file.filename)}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Button variant="outlined">Import Templates</Button>
            </>
          )} */}
        </Box>
    )
}
