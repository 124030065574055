import { APP_MODULE_ACCESS_LIST } from "../../constants/enum"
import { ISidebarMenuItem } from "../../layout/FullLayout/sidebar/Siderbar.types"



export const UBER_EATS_ROUTE = "/uber-eats"
export const UBER_EATS_ORDER_NOTIFICATIONS_ROUTE = UBER_EATS_ROUTE + "/order-notifications"

export const UBER_EATS_NEW_ORDERS_ROUTE = UBER_EATS_ROUTE + "/new-orders"
export const UBER_EATS_IN_PROGRESS_ORDERS_ROUTE = UBER_EATS_ROUTE + "/in-progress-orders"
export const UBER_EATS_ORDER_READY_FOR_PICKUP_ROUTE = UBER_EATS_ROUTE + "/ready-for-pickup"
export const UBER_EATS_ORDER_HISTORY_ROUTE = UBER_EATS_ROUTE + "/orders-history"



export const uberEatsMenu: ISidebarMenuItem = {
  title: "Uber Eats",
  icon: "Profile",
  href: UBER_EATS_ROUTE,
  accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
  children: [
    {
      title: "New Orders",
      icon: "Profile",
      href: UBER_EATS_NEW_ORDERS_ROUTE,
      accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
    },
    {
      title: "In Progress",
      icon: "Profile",
      href: UBER_EATS_IN_PROGRESS_ORDERS_ROUTE,
      accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
    },
    {
      title: "Ready For Pickup",
      icon: "Profile",
      href: UBER_EATS_ORDER_READY_FOR_PICKUP_ROUTE,
      accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
    },
    {
      title: "Order History",
      icon: "Profile",
      href: UBER_EATS_ORDER_HISTORY_ROUTE,
      accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
    },
  ]
}