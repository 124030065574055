
export interface IComposeMailRightPanelProps {
  open: boolean;
  enquiryNumber: string;
  uploadsVisible?: boolean;
  documents?: IEmailAttachment[];
  onComplete: () => void;
  onClose: () => void;
}

export interface IComposeMailMessage {
  module_uuid: string;
  module_name: string;
  subject: string;
  sender_email: string;
  recipient_emails: string[];
  cc_emails: string[];
  bcc_emails: string[];
  body: string;
  comment: string;
  attachments: IEmailAttachment[];
}

export const defaultComposeMailMessage: IComposeMailMessage = {
  module_uuid: "",
  module_name: "",
  sender_email: "",
  recipient_emails: [],
  cc_emails: [],
  bcc_emails: [],
  subject: "",
  body: "",
  comment: "",
  attachments: [],
};

export interface IComposeMail {
  module_uuid: string;
  module_name: string;
  emails: string[];
  subject: string;
  body: string;
  comment: string;
  templateName?: string;
  objectVariables?: {
    additionalProp1: {};
  };
  cc?: string[];
  bcc?: string[];
  reply_to?: IReplyTo;
  attachments?: IEmailAttachment[];
}

export interface IReplyTo {
  email: string | null;
  name: string;
}

export interface IEmailAttachmentV233 {
  content: string;
  filename: string;
}

export interface IEmailAttachment {
  content: {
    data: number[];
    type: string;
  };
  filename: string;
}

export interface FileUploadWithListProps {
  label?: string;
  onUploadSuccess: (files: IEmailAttachment[]) => void;
}

export interface IEnquiryAttachmentsDialog {
  open: boolean;
  enquiryNumber: string;
  onClose: () => void;
  onUploadSuccess: (files: IEmailAttachment[]) => void;
}
