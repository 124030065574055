import { ICustomTableNode } from "../../../../Components/ReactFlowShapes/ReactFlowShapes.types"


export interface IStandardTableCardProps {
    data: ICustomTableNode
}

export const tableAvailability = {
    FREE: "Free",
    RESERVED: "Reserved",
    CHECKED_IN: "Checked-in"
}