import moment from "moment";
import { axios_api } from "../../api/axios-api"
import { calculateOrderRevenue, calculateOrderRevenueForBarChart } from "./Dashboard.helpers";
import { IAirlineRevenueChart, IBarChart, IOrderRevenueResponse } from "./Dashboard.types";



export const fetchRevenueAndFoodCostListAsync = () => new Promise<IBarChart[]>((resolve, reject) => {
    const today = moment().format('YYYY-MM-DD')
    const sevenDaysBefore = moment().subtract(6, "week").subtract(1, "day").format('YYYY-MM-DD')
    axios_api.get(`/order/get-details-of-orders?from_date=${sevenDaysBefore}&to_date=${today}`).then((response) => {
        resolve(calculateOrderRevenueForBarChart(response.data.data))
    }).catch((error) => reject(error))
})



export const fetchOrderRevenueAsync = () => new Promise<IAirlineRevenueChart>(async (resolve, reject) => {
    try {
        const today = moment().format('YYYY-MM-DD')
        const yesterday = moment().subtract(1, "day").format('YYYY-MM-DD')
        const sevenDaysBefore = moment().subtract(6, "week").subtract(1, "day").format('YYYY-MM-DD')
        const oneMonthBefore = moment().subtract(1, "month").subtract(1, "day").format('YYYY-MM-DD')

        const dailyRevenue = await axios_api.get(`/order/get-details-of-orders?from_date=${today}&to_date=${today}`)
        const yesterdaysRevenue = await axios_api.get(`/order/get-details-of-orders?from_date=${yesterday}&to_date=${yesterday}`)
        const weeklyRevenue = await axios_api.get(`/order/get-details-of-orders?from_date=${sevenDaysBefore}&to_date=${today}`)
        const monthlyRevenue = await axios_api.get(`/order/get-details-of-orders?from_date=${oneMonthBefore}&to_date=${today}`)
        resolve({
            today: calculateOrderRevenue(dailyRevenue.data.data),
            yesterday: calculateOrderRevenue(yesterdaysRevenue.data.data),
            current_week: calculateOrderRevenue(weeklyRevenue.data.data),
            current_month: calculateOrderRevenue(monthlyRevenue.data.data)
        })
    } catch (error) {
        reject(error)
    }
})


