import { FormControl, Box, Typography, Button } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthProvider } from "../../../providers/Authentication/AuthenticationProvider";
import { CustomTextFieldV2 } from "../../../Components/formComponents";

export const ValidateOtpAndResetPassword: React.FC<{ email: string }> = ({
  email,
}) => {
  const { resetPasswordAsync } = useAuthProvider();
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { values, errors, handleChange, handleSubmit, setFieldError } =
    useFormik({
      initialValues: {
        email: email,
        password: "",
        confirm_password: "",
        emailOTP: "",
      },
      validate: (values) => {
        const errors: any = {};
        if (!values.emailOTP) {
          errors.emailOTP = "*OTP is required.";
        }
        if (!values.password) {
          errors.password = "*Password is required.";
        }
        if (!values.confirm_password) {
          errors.confirm_password = "*Confirm Password is required.";
        }
        return errors;
      },
      onSubmit: (values) => {
        setLoading(true);
        resetPasswordAsync(
          values.email,
          values.password,
          Number(values.emailOTP),
          () => {
            setLoading(false);
            navigate("/dashboard");
          },
          (errorMessage) => {
            setLoading(false);
            setFieldError("emailOTP", errorMessage);
          },
        );
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        sx={{ mt: 2 }}
        fullWidth
        error={errors.emailOTP ? true : false}
      >
        <Box display="flex" justifyContent={"end"}>
          <Typography
            fontWeight="500"
            sx={{
              color: '#000',
              display: "block",
              textDecoration: "none",
              mb: 2,
            }}
          >
            We’ve Successfully sent a One Time Password (OTP) to the email.
            Please enter otp and new password to reset.
          </Typography>
        </Box>
        <CustomTextFieldV2
          label="OTP"
          id="emailOTP"
          variant="outlined"
          value={values.emailOTP}
          fullWidth
          type="number"
          error={errors.emailOTP ? true : false}
          helperText={errors.emailOTP}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <CustomTextFieldV2
          label="New Password"
          id="password"
          variant="outlined"
          value={values.password}
          fullWidth
          error={errors.password ? true : false}
          helperText={errors.password}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <CustomTextFieldV2
          label="Confirm Password"
          id="confirm_password"
          variant="outlined"
          value={values.confirm_password}
          fullWidth
          error={errors.confirm_password ? true : false}
          helperText={errors.confirm_password}
          onChange={handleChange}
        />
      </FormControl>

      <Button
        color="primary"
        variant="contained"
        size="large"
        fullWidth
        disabled={loading}
        type="submit"
        sx={{
          mt: 2,
          pt: "10px",
          pb: "10px",
          background: "#000",
          color: "#fff",
          "&:hover": {
            background: "#252525",
            color: "#fff",
          }
        }}
      >
        Change Password
      </Button>
    </form>
  );
};
