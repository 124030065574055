import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { IOutletRegister, IRegisterStaff } from "./outlet-registers.types"
import { defaultOutletRegister } from "./outlet-registers.state"

const GET_OUTLET_REGISTER_BASE_URL = "/company/get-register"
const UPSERT_OUTLET_REGISTER_BASE_URL = "/company/upsert-register"


export const fetchOutletRegistersListAsync = createAsyncThunk<{ data: IOutletRegister[], count: number }, IQueryParams>(
    'outlet-register/fetchOutletRegistersListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_OUTLET_REGISTER_BASE_URL}${searchQuery}`)
        return response.data as { data: IOutletRegister[], count: number }
    },
)

interface IFetchOutletRegisterPayload extends IQueryParams {
    outlet_uuid: string
}
export const fetchRegistersListForSingleOutletAsync = createAsyncThunk<{ data: IOutletRegister[], count: number }, IFetchOutletRegisterPayload>(
    'register-staff/fetchRegistersListForSingleOutletAsync', async (queryParams: IFetchOutletRegisterPayload, thunkAPI) => {
        const { outlet_uuid, ...restParams } = queryParams
        const searchQuery = getSearchQuery(restParams);
        const response = await axios_api.get(`${GET_OUTLET_REGISTER_BASE_URL}${searchQuery}&outlet_uuid=${outlet_uuid}`)
        return response.data
    },
)

export const fetchSingleOutletRegisterAsync = createAsyncThunk<IOutletRegister, string>(
    'outlet-register/fetchSingleOutletRegisterAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_OUTLET_REGISTER_BASE_URL}?register_uuid=${uuid}`)
        return response.data.data[0]
    },
)


export const upsertOutletRegisterAsync = createAsyncThunk<IOutletRegister, IOutletRegister>(
    'outlet-register/upsertOutletRegisterAsync', async (payload: IOutletRegister, thunkAPI) => {
        const response = await axios_api.post(UPSERT_OUTLET_REGISTER_BASE_URL, payload)
        return response.data
    },
)


// #################################################################################################
// ################################### Register_Staff ##############################################
// #################################################################################################

const GET_REGISTER_STAFF_BASE_URL = "/company/get-register-staff"
const UPSERT_REGISTER_STAFF_BASE_URL = "/company/upsert-register-staff"

interface IFetchRegisterStaffPayload extends IQueryParams {
    register_uuid: string
}
export const fetchStaffListForSingleRegisterAsync = createAsyncThunk<{ data: IRegisterStaff[], count: number }, IFetchRegisterStaffPayload>(
    'register-staff/fetchStaffListForSingleRegisterAsync', async (queryParams: IFetchRegisterStaffPayload, thunkAPI) => {
        const { register_uuid, ...restParams } = queryParams
        const searchQuery = getSearchQuery(restParams);
        const response = await axios_api.get(`${GET_REGISTER_STAFF_BASE_URL}${searchQuery}&register_uuid=${register_uuid}`)
        return response.data
    },
)

export const fetchSingleRegisterStaffDetailsAsync = createAsyncThunk<IRegisterStaff, string>(
    'register-staff/fetchSingleRegisterStaffDetailsAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_REGISTER_STAFF_BASE_URL}?register_uuid=${uuid}`)
        return response.data.data[0]
    },
)

export const upsertSingleStaffMemberForRegisterAsync = createAsyncThunk<IRegisterStaff, IRegisterStaff>(
    'register-staff/upsertSingleStaffMemberForRegisterAsync', async (payload: IRegisterStaff, thunkAPI) => {
        const response = await axios_api.post(UPSERT_REGISTER_STAFF_BASE_URL, payload)
        thunkAPI.dispatch(fetchStaffListForSingleRegisterAsync({
            register_uuid: payload.register_uuid,
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)
