import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../../common";
import { axios_api } from "../../../api/axios-api";
import { IPosBankInfo } from "./bank-info.types";

const GET_ACCOUNTING_BANK_INFO_ENDPOINT= "/accounting/get-accounting-bank-info"
const UPSERT_ACCOUNTING_BANK_INFO_ENDPOINT = "/accounting/upsert-accounting-bank-info"

export const fetchPosBankInfoListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IPosBankInfo[] }, IQueryParams>(
    'pos-bank-info/fetchPosBankInfoListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_ACCOUNTING_BANK_INFO_ENDPOINT}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const upsertSinglePosBankInfoAsync = createAsyncThunk<IPosBankInfo, IPosBankInfo>(
    'pos-bank-info/upsertSinglePosBankInfoAsync', async (payload: IPosBankInfo, thunkAPI) => {
        const response = await axios_api.post(UPSERT_ACCOUNTING_BANK_INFO_ENDPOINT, payload)
        thunkAPI.dispatch(fetchPosBankInfoListWithArgsAsync({
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)