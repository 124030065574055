import React from "react";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { IProductCustomization, upsertSingleCustomizationAsync } from "../../../../redux/inventory";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../redux";
import { ControlledCustomSelect, OutlinedTextField } from "../../../../Components/formComponents";


export interface IManageCustomizationDialogProps {
  open: boolean;
  customization: IProductCustomization;
  onClose: () => void;
}
export const ManageCustomizationDialog: React.FC<IManageCustomizationDialogProps> = (props) => {
  const { open, customization, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...customization },
    validate: (values) => {
      const errors: any = {};
      if (!values.customization_name) {
        errors.customization_name = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSingleCustomizationAsync(values)
      ).finally(() => {
        setSaveLoading(false);
        onClose()
      });
    },
  });

  return (
    <Dialog
      open={open}
      title="Add/Edit Customization"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
          disabled: saveLoading
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OutlinedTextField
            label="Customization Name"
            id="customization_name"
            value={values.customization_name}
            onChange={handleChange}
            error={errors.customization_name}
          />
        </Grid>

        <Grid item xs={12}>
          <OutlinedTextField
            label="Notes"
            id="customization_notes"
            value={values.customization_notes}
            onChange={handleChange}
          />
        </Grid>

        {values.customization_uuid && (
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Status"
              name="status"
              fullWidth
              value={values.status}
              onChange={handleChange}
              options={[
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
              ]}
              displayEmpty
              placeholder="Select Status"
              error={errors.status}
            />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};
