import React from "react";
import { Grid } from "@mui/material";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { MenuCategoryList } from "./MenuCategory/MenuCategoryList";
import { MenuProductList } from "./MenuProduct/MenuProductList";
import { MenuOrderLayout } from "./MenuOrder/MenuOrderLayout";


export const OrderMenuLayout: React.FC = () => {
    return (
        <PageContainer title="Menu" description="Menu page">
            <Grid container spacing={1.5} pt={2} flexWrap="wrap">
                <Grid item xs={12} sm={3} md={3} lg={2}>
                    <MenuCategoryList />
                </Grid>

                <Grid item xs={12} sm={5} md={6} lg={7}>
                    <MenuProductList />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <MenuOrderLayout />
                </Grid>
            </Grid>
        </PageContainer>
    );
};





