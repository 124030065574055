import { combineReducers } from "@reduxjs/toolkit";
import { userProfileReducer } from "./settings/user-profile";
import { productReducer, categoriesReducer, inventoryReducer, productCustomizationReducer } from "./inventory";
import { tablesLayoutReducer } from "./tables";
import { shiftsReducer } from "./shifts";
import { chartsOfAccountReducer, paymentPaidReducer, paymentReceivedReducer, posBankInfoReducer } from "./accounting";
import { receiptComposerReducer } from "./settings/receipt-composer";
import { customizerReducer } from "./customizer";
import { taxInfoReducer } from "./settings/tax-info";
import { outletsReducer } from "./settings/outlets";
import { companyReducer } from "./settings/company";
import { outletRegisterReducer } from "./settings/outlet-registers";
import { securityReducer } from "./settings/security";
import { couponsReducer } from "./marketing";
import { campaignsReducer } from "./marketing/campaigns";
import { ordersReducer } from "./orders";
import { reservationsReducer } from "./reservations";
import { commonReducer } from "./common/common.reducer";
import { uberEatsOrdersReducer } from "./uber-eats";
export const rootReducer = combineReducers({
  // Add your reducers here
  common: commonReducer,
  inventory: combineReducers({
    categories: categoriesReducer,
    products: productReducer,
    inventory: inventoryReducer,
    customization: productCustomizationReducer
  }),
  tables: tablesLayoutReducer,
  reservations: reservationsReducer,
  orders: ordersReducer,
  accounting: combineReducers({
    paymentReceived: paymentReceivedReducer,
    paymentPaid: paymentPaidReducer,
    bankInfo: posBankInfoReducer,
    chartOfAccount: chartsOfAccountReducer
  }),


  customizer: customizerReducer,


  shifts: shiftsReducer,
  settings: combineReducers({
    receiptComposer: receiptComposerReducer,
    companies: companyReducer,
    outlets: outletsReducer,
    tax: taxInfoReducer,
    userProfile: userProfileReducer,
    registers: outletRegisterReducer,
    security: securityReducer
  }),
  marketing: combineReducers({
    campaigns: campaignsReducer,
    coupons: couponsReducer,
  }),
  uberEats: combineReducers({
    uberEatsOrders: uberEatsOrdersReducer,
  }),
});
